import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
// import { Row, Col, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { Row, Col, Card, Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

// External libraries
import moment from 'moment';
// utils
import { handleAPIError, handleCatchError } from 'utils/handleErrors';
import formatMoney from 'utils/formatMoney';
// components
import { ToastErrorCath, ToastSuccess } from '../../../../components/alerts/toast';
import ButtonRequest from '../../../../components/buttons/ButtonRequest';
// api
import { toggleCancellationState, returnShipping } from '../../../api/Api-admin';

const ShippingCard = ({ data }) => {
  // console.log('data:', data);
  const [isModalActive, setIsModalActive] = useState(false);
  const [isAddChargerLoader, setIsAddChargerLoader] = useState(false);

  const [canceled, setCanceled] = useState(data.canceled);
  const [consumed, setConsumed] = useState(data.consumed);
  const [refunded, setRefunded] = useState(data.refunded);
  // Sincronizar estados cuando `data` cambie
  useEffect(() => {
    setCanceled(data.canceled);
    setConsumed(data.consumed);
    setRefunded(data.refunded);
  }, [data]);

  const closeModal = () => {
    setIsModalActive(false);
  };
  const handlerCancellationState = async (state) => {
    try {
      const response = await toggleCancellationState(data.shipping_id, state);
      // console.log('toggleCancellationState res:', response);
      if (response?.success !== undefined) {
        if (state === 'canceled') {
          setCanceled(!canceled);
        } else if (state === 'consumed') setConsumed(!consumed);
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    }
  };
  const handlerReturnShipping = async () => {
    try {
      setIsAddChargerLoader(true);
      const response = await returnShipping(data.shipping_id);
      // console.log('return shipping response:', response);
      if (response.shipping_refunded) {
        ToastSuccess(`${response.charge_statement}. Monto $${response.amount}`);
        // ToastSuccess('La devolución de la guía se ha realizado exitosamente ⭐');
        setRefunded(true);
      } else {
        ToastSuccess(`${response.charge_statement}. Monto $${response.amount}`);
        setRefunded(false);
      }
      if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      closeModal();
      setIsAddChargerLoader(false);
    }
  };
  return (
    <>
      <Modal show={isModalActive} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">
            {/* <div className="text-primary">{data.shipping_code}</div> */}
            <span className="text-alternate">{`ID Envío ${data.shipping_id}`}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-infow">
          <div className="text-semi-large">{`¿Estas segur@ de que deseas ${data.refunded ? 'volver a cobrar' : 'reembolsar'} este envío?`}</div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-wrap justify-content-end bg-warningd">
            <ButtonRequest
              title="Si, continuar"
              titleLoading="Cargando"
              handlerFn={handlerReturnShipping}
              isButtonDisabled={isAddChargerLoader}
              isLoading={isAddChargerLoader}
            />
            <Button variant="outline-primary" style={{ height: '36px' }} className="w-auto ms-2 mb-2" onClick={closeModal}>
              Cancelar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <Card className="mb-2 py-4 py-lg-0 ps-5 pe-4 h-100 text-alternate">
        <Row className="g-0 h-100 sh-lg-9 position-relative overflow-y-hidden">
          {/* <div className="lh-1 text-muted">{`${counter}.`}</div> */}
          <Col xs="4" lg="1" className="d-flex flex-column mb-lg-0 mb-3 pe-3 h-lg-100 justify-content-center bg-infow order-2 order-lg-1">
            <div className="text-muted text-small d-lg-none">Crm Id</div>
            <OverlayTrigger delay={{ show: 200, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Ver perfil de usuario</Tooltip>}>
              <NavLink className="anchor-effect-primary" to={`/users-all/detail/${data?.api_user}`}>
                {data.api_user}
              </NavLink>
            </OverlayTrigger>
          </Col>
          <Col xs="12" lg="2" className="d-flex flex-column pe-1 pe-lg-2 mb-2 mb-lg-0 justify-content-center  bg-successw order-1 order-lg-2">
            <div className="text-muted text-small d-lg-none">Servicio / ID Envío </div>
            <OverlayTrigger delay={{ show: 200, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Ver detalle de envío</Tooltip>}>
              <NavLink className="anchor-effect-primary" to={`/shipping-all/detail/${data.shipping_id}/${data.legal_name}/${data.api_user}`}>
                {data.shipping_code}
              </NavLink>
            </OverlayTrigger>
            <div className="text-medium text-muted text-truncate">{data.shipping_id}</div>
          </Col>

          <Col xs="8" lg="3" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center align-items-center order-3">
            <div className="text-muted text-small d-lg-none">Proveedor</div>
            <OverlayTrigger delay={{ show: 200, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">{data.reason}</Tooltip>}>
              <div className="text-alternate  cursor-pointer">{data.provider}</div>
            </OverlayTrigger>
          </Col>
          <Col
            xs="4"
            lg="1"
            className=" d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center align-items-start align-items-lg-center bg-secondaryw order-4"
          >
            <div className="text-muted text-small d-lg-none">Costo</div>
            <div className="d-flex align-items-center">
              <span className="text-small text-muted">$</span>
              <div className="text-alternate">{formatMoney(data.total)}</div>
            </div>
          </Col>
          <Col xs="auto" lg="1" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center align-items-end text-end order-5">
            <div className="text-muted text-small d-lg-none">Devuelta</div>
            <input
              type="checkbox"
              className="form-check-input cursor-pointer"
              name="receiver"
              checked={refunded}
              onClick={() => {
                if (refunded) {
                  ToastErrorCath('Error: Este envío ya fue reembolsado.');
                } else {
                  setIsModalActive(true);
                }
              }}
            />
          </Col>
          <Col xs="auto" lg="1" className="ps-4 d-flex flex-column mb-2 mb-lg-0 me-5 me-lg-0 align-items-end justify-content-center bg-ternary order-5">
            <div className="text-muted text-small d-lg-none">Cancelada</div>
            <input
              type="checkbox"
              className="form-check-input cursor-pointer"
              name="receiver"
              checked={canceled}
              onClick={() => {
                handlerCancellationState('canceled');
              }}
            />
          </Col>
          <Col xs="auto" lg="1" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center align-items-end  bg-secondaryx order-5">
            <div className="text-muted text-small d-lg-none">Utilizada</div>
            <input
              type="checkbox"
              className="form-check-input cursor-pointer"
              name="receiver"
              checked={consumed}
              onClick={() => {
                handlerCancellationState('consumed');
              }}
            />
          </Col>
          <Col xs="8" lg="2" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center align-items-lg-end bg-infoW order-4 order-lg-last">
            <div className="text-muted text-small d-lg-none">Fecha / Hora</div>
            <div className="lh-1 text-alternate">{`${moment(data.created_date).format('DD-MM-YY')} ${data?.created_date.slice(11, 19)}`}</div>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export default ShippingCard;

import React, { useState } from 'react';
import { Col, Row, Card, Tooltip, OverlayTrigger, Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// components
import { ToastSuccess, ToastErrorApi, ToastErrorCath } from '../alerts/toast';
import ButtonRequest from '../buttons/ButtonRequest';
// api
import { returnAdditionalCharge } from '../../views-admin/api/Api-admin';

const AdditionalChargesCard = ({ data, isAdmin = '', setUpdatePage }) => {
  // console.log('cargo adicional:', data);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isAddChargerLoader, setIsAddChargerLoader] = useState(false);
  const { currentUser } = useSelector((state) => state.auth);

  const handleButtonAction = () => {
    setIsButtonDisabled(true); // disable button
    setIsAddChargerLoader(true); // active loader in the button
    // Rehabilitar el botón después de 3 segundos
    setTimeout(() => setIsButtonDisabled(false), 3000);
  };
  const handlerReturnCharge = async () => {
    try {
      handleButtonAction();
      const response = await returnAdditionalCharge(data.id);
      // console.log('return charge response:', response);
      if (response.shipping_check) {
        ToastSuccess('La devolución del cargo se ha realizado exitosamente ⭐');
        setUpdatePage(true);
      } else if (response.error) {
        const firstKey = Object.keys(response.error)[0];
        ToastErrorApi(response.error[firstKey]);
      }
    } catch {
      ToastErrorCath();
    } finally {
      setIsModalOpen(false);
      setIsAddChargerLoader(false);
    }
  };
  const splitAuthor = (string) => {
    const index = string.indexOf('-'); // Encuentra el índice del primer guion
    if (index === -1) {
      return string; // Si no hay guion, retorna una string vacía
    }
    return string.substring(index + 1).toUpperCase(); // Retorna la parte de la cadena desde el guion hasta el final
  };
  return (
    <>
      {/* Confirm Modal Start */}
      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title as="h5" />
        </Modal.Header>
        <Modal.Body>
          <h5>{`¿Estás segur@ de que deseas revertir este cargo "${data?.statement_text}" ?`}</h5>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end ">
            <ButtonRequest
              title="Si, Revertir"
              titleLoading="Cargando"
              handlerFn={handlerReturnCharge}
              isButtonDisabled={isButtonDisabled}
              isLoading={isAddChargerLoader}
            />
            <Button variant="outline-primary" style={{ height: '36px' }} onClick={() => setIsModalOpen(false)}>
              Cancelar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Confirm Modal End */}
      <Card className="mb-2 px-0">
        <Row className="g-0 h-100 sh-lg-9 position-relative overflow-y-hidden">
          <Col className="py-4 py-lg-0 px-4 h-100 card bg-light" style={{ boxShadow: '0 4px 6px rgba(var(--separator-rgb), 0.5)' }}>
            <Row className="g-0 h-100 align-content-center">
              <Col xs="12" lg="3" className="mb-2 pe-3 d-flex flex-column mb-lg-0 h-lg-100 justify-content-center order-1 bg-infow">
                <div className="text-muted text-small d-lg-none">Concepto</div>
                <div className="text-primary">{typeof data?.statement_text === 'string' && data?.statement_text.toUpperCase()}</div>
              </Col>
              <Col
                xs="6"
                lg="1"
                className="d-flex flex-column mt-4 mb-4 mt-lg-0 mb-lg-0 pe-1 mb-2 mb-lg-0 justify-content-center align-items-lg-start order-2 bg-secondaryw"
              >
                <div className="text-muted text-small d-lg-none">Fecha</div>
                {/* ${data?.created.slice(11, 19)} */}
                <div className="lh-1 text-alternate">{`${moment(data.created).format('DD-MM-YY')}`}</div>
              </Col>
              <Col
                xs="6"
                lg="2"
                className="d-flex flex-column pe-1 mb-1 mb-lg-0 align-items-start justify-content-center align-items-end align-items-lg-start order-3 bg-infow"
              >
                <div className="text-muted text-small d-lg-none">Referencia</div>
                <div
                  className="text-alternate text-nowrap "
                  style={{
                    overflowX: 'scroll',
                    maxWidth: '100%',
                  }}
                >
                  {!data.reference_trace ? '' : data.reference_trace}
                </div>
              </Col>
              <Col
                xs="6"
                lg="2"
                className="d-flex flex-column mb-2 mb-lg-0 align-items-start align-items-lg-center justify-content-lg-center order-4 bg-successq"
              >
                <div className="text-muted text-small d-lg-none">Estado</div>
                <div className="text-primary">{data?.refunded ? 'DEVUELTO' : 'APLICADO'}</div>
              </Col>
              <Col xs="6" lg="2" className="d-flex flex-column mb-2 mb-lg-0 justify-content-lg-center align-items-end order-4 bg-successx">
                <div className="text-muted text-small d-lg-none">Autor</div>
                <div className="text-alternate">{splitAuthor(data.author)}</div>
              </Col>
              <Col
                xs="6"
                lg="1"
                className="mb-1 mb-lg-0 d-flex flex-column justify-content-center align-items-end align-items-lg-center order-last order-lg-5 bg-infow"
              >
                {!data?.refunded && isAdmin && currentUser.access === 'Control Total' && (
                  <>
                    <div className="text-muted text-small d-lg-none">Devolver Cargo</div>
                    <OverlayTrigger delay={{ show: 500, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Devolver Cargo</Tooltip>}>
                      <div onClick={() => setIsModalOpen(true)}>
                        <CsLineIcons icon="rotate-left" className="anchor-effect-primary cursor-pointer" />
                      </div>
                    </OverlayTrigger>
                  </>
                )}
              </Col>
              <Col xs="6" lg="1" className="d-flex flex-column pe-1 mb-4 mb-lg-0 justify-content-center align-items-lg-end order-5 bg-infow">
                <div className="text-muted text-small d-lg-none">Cargo</div>
                <div className={`${data?.refunded ? 'text-success' : 'text-primary'}`}>
                  <span className="text-small text-muted">$</span>
                  {data.charge}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export default AdditionalChargesCard;

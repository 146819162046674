// import redux and persist plugins
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage';
import persistStore from 'reduxjs-toolkit-persist/es/persistStore';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'reduxjs-toolkit-persist/es/constants';

// import theme reducers
// import persist key
import { REDUX_PERSIST_KEY } from 'config.js';
import settingsReducer from 'slices/settings/settingsSlice';
import layoutReducer from 'layout/layoutSlice';
import langReducer from 'lang/langSlice';
import authReducer from 'slices/auth/authSlice';
import menuReducer from 'layout/nav/main-menu/menuSlice';
import notificationReducer from 'layout/nav/notifications/notificationSlice';
import scrollspyReducer from 'components/scrollspy/scrollspySlice';
import senderDataReducer from './slices/shipping/senderDataSlice';
import receiverDataReducer from './slices/shipping/receiverDataSlice';
import parcelDataReducer from './slices/shipping/parcelDataSlice';
import insuranceDataReducer from './slices/shipping/insuranceDataSlice';
import ratesDataReducer from './slices/shipping/ratesDataSlice';
import shippingDataReducer from './slices/shipping/shippingDataSlice';
import generalDataReducer from './slices/generalData/generalData';
import addressDataReducer from './slices/addresses/addressDataSlice';
import parcelSelectedReducer from './slices/parcels/parcelSelectedSlice';

// *GLOBAL STATE SETTING*
const persistConfig = {
  key: REDUX_PERSIST_KEY,
  storage,
  whitelist: ['auth', 'menu', 'settings', 'lang'],
};
// -The Reducers are controling pieces of states which we call slice and are imported and are configured in persistedReducer
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    settings: settingsReducer,
    layout: layoutReducer,
    lang: langReducer,
    auth: authReducer,
    menu: menuReducer,
    notification: notificationReducer,
    scrollspy: scrollspyReducer,
    shipping_sender: senderDataReducer,
    shipping_receiver: receiverDataReducer,
    shipping_parcel: parcelDataReducer,
    shipping_insurance: insuranceDataReducer,
    shipping_rates: ratesDataReducer,
    shipping: shippingDataReducer,
    general_data: generalDataReducer,
    address_data: addressDataReducer,
    parcel_data: parcelSelectedReducer,
  })
);
// -The Store sets up global states whit all reducers, these are imported from their respective folders
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistedStore = persistStore(store);
export { store, persistedStore };

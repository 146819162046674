import React from 'react';
import { Col, Card, Button } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const DashboardCard = ({ img = '', title = '', subTitle = '', description = '', url = '', btnTitle = '' }) => {
  return (
    <Col xs="12" sm="6" xl="3">
      <Card className="h-100-card hover-img-scale-up mx-auto mb-3" style={{ minHeight: '300px', maxWidth: '480px', backgroundColor: '#f1f1f1' }}>
        <img src={img} className="card-img h-100 scale" alt="card image" />
        <Card.Body className="d-flex flex-column justify-content-between align-items-start  bg-transparent card-img-overlay">
          <div>
            <div className="cta-3">{title}</div>
            <div className="mb-3 cta-3 text-primary">{subTitle}</div>
            <div className="text-alternate mb-4">{description}</div>
          </div>
          <Button
            variant="outline-primary"
            className="w-auto btn btn-icon btn-icon-start ms-0 ms-sm-1"
            onClick={(e) => {
              e.preventDefault();
              window.open(url);
            }}
          >
            <CsLineIcons icon="arrow-right" />
            <span className="ps-2">{btnTitle}</span>
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
};
export default DashboardCard;

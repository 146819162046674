import React from 'react';
import { NavLink } from 'react-router-dom';
import { Card, Button, Col, Row } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const Surcharges = () => {
  const title = 'Cargos Adicionales';
  const description = 'Cargos Adicionales Plataforma Autopaquete';

  return (
    <>
      <HtmlHead title={title} description={description} />
      {/* Title Start */}
      <div className="page-title-container">
        <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="">
          <CsLineIcons icon="chevron-left" size="13" />
          <span className="align-middle text-small ms-1">Inicio</span>
        </NavLink>
        <h1 className="mb-0 pb-0 display-4" id="title">
          {title}
        </h1>
      </div>
      {/* Title End  */}
      <Card className="mb-5">
        <Card.Body>
          <Row>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <div className="text-primary"> Vigencia de guías</div>
                      {/* <Button variant="link" className="p-0 pt-1 pb-2 text-start responsive-title text-wrap">
                        Vigencia de guías
                      </Button> */}
                      <div className="responsive-text mb-2 ">A partir de la fecha de creación (Días naturales).</div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>Fedex: 5 días</li>
                        <li>Estafeta: 6 días</li>
                        <li>DHL: 7 días</li>
                        <li>UPS: 7 días</li>
                        <li>J&T Express: 3 días</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <div className="text-primary"> Cancelación de guías sin usar</div>
                      <div className="responsive-text mb-2 ">Cuando la guía se crea, pero no se utiliza y se manda a reembolso.</div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li> Fedex: Sin costo</li>
                        <li> Estafeta “E”: No reembolsable una vez creada la guía</li>
                        <li> Estafeta "D": Solo permite cancelar cuando se ha programado una recolección y la unidad no ha pasado por el paquete</li>
                        <li> Estafeta "C": Sin costo</li>
                        <li> DHL: $25</li>
                        <li> UPS: Sin costo</li>
                        <li> J&T Express: Sin costo</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <div className="text-primary">Recolecciones</div>
                      <div className="responsive-text mb-2 ">Recolección en día inhábil (fin de semana).</div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li> FedEx: $259.00</li>
                        <li> Estafeta: $319.00</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative  me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <div className="text-primary">Cargo por Combustible</div>
                      <div className="responsive-text">Entrega genero un gasto extra para la paquetería, por la zona.</div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>Variable</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative  me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <Button variant="link" className="p-0 pt-1 heading text-start responsive-title text-wrap">
                        Zonificación
                      </Button>
                      <div className="responsive-text">Cargo adicional por la distancia o zona. (Aparece en la cotización)</div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>Variable</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative  me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <div className="text-primary">Zona extendida</div>
                      <div className="responsive-text">Recolección o entrega en lugares de difícil acceso o complejidad.</div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>Fedex: $174.00</li>
                        <li>Estafeta: $219.00 (Recoleccion $199.00)</li>
                        <li>DHL $272</li>
                      </ul>
                      <div className="responsive-text">*En algunas paqueterías el cobro de zona extendida puede generarse hasta que se entrega el paquete.</div>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>

            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative  me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <div className="text-primary">Dimensiones</div>
                      <div className="responsive-text">Empaque que excedan el máximo de medidas en su lado más largo.</div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>Fedex Express (+120 cm): $335.00</li>
                        <li>Fedex Economy(+120 cm): $160.00</li>
                        <li>Fedex Economy(+157 cm): $681.00</li>
                        <li>Fedex Economy(+330 cm): $681.00 (largo + 2 veces ancho + 2 veces alto)</li>
                        <li>Estafeta Terrestre (+120 cm): $199.00</li>
                        <li>J&T Express (+60 x 60 x 40 cm): No existe servicio para dimensiones mayores.</li>
                        <li>DHL (+120 CM): $442.00</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <div className="text-primary">Devoluciones</div>
                      <div className="responsive-text">
                        Devolución o retorno por razones que no están bajo el control de la paquetería, o a solicitud del usuario.
                      </div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>General: Costo de gestión del retorno</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <div className="text-primary">Corrección o cambio de domicilio</div>
                      <div className="responsive-text">
                        Cuando la dirección del destinatario está incompleta o es incorrecta y si el teléfono no es válido. Solo se aplicará en caso de que
                        proceda el reporte. (Siempre y cuando se nos notifique antes de encontrarse en ruta de entrega).
                      </div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>
                          FedEx y Estafeta cambio: No es posible realizar cambio o redirección 
                          <em> *Consulta sección de “Soporte” para ver opciones*</em>
                        </li>
                        <li>FedEx corrección: $90 (Completar detalles de dirección como teléfono de destinatario o entrecalles).</li>
                        <li>Estafeta corrección: $99 (Completar detalles de dirección, teléfono de destinatario).</li>
                        <li>DHL cambio domicilio $90</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <div className="text-primary">Póliza de seguro</div>
                      <div className="responsive-text">
                        El seguro cubre el valor declarado del contenido previo pago del deducible (por encima de la responsabilidad estándar). Cubriendo la
                        cantidad necesaria para reparar o reemplazar un envío en caso de pérdida a daño. Especialmente en casos de robo a ruta, asalto,
                        accidente o percances externos a la paquetería.(Consulta bienes excluidos)
                      </div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>Póliza de Seguro: $ 10.00 + 2% del valor declarado + IVA.</li>
                        <li>15% de deducible.</li>
                        <li>Cobertura hasta por $ 200,000.00 por caja.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="6" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <div className="text-primary">Peso</div>
                      <div className="responsive-text"> Empaque que excedan el máximo de peso:</div>
                      <ul className="text-muted mt-2 ps-3 ps-md-5 responsive-text">
                        <li>Fedex Economy(+30 kg): $140.00</li>
                        <li>FedEx Economy(+68 kg): $681.00</li>
                        <li>Estafeta (+70 kg): $279.00</li>
                        <li>J&T Express: 28kg máximo (Para BC Sur y Norte, no puede exceder de 10kg, ni de los 60cm por lado)</li>
                        <li>DHL (+70 kg): $442.00</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
            <Col xs="12" md="12" lg="10" className="d-flex flex-row">
              <Col xs="12" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-2 me-md-4">
                <div className="w-100 d-flex sh-1" />
                <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                  <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                </div>
                <div className="w-100 d-flex h-100 justify-content-center position-relative">
                  <div className="line-w-1 bg-separator h-100 position-absolute" />
                </div>
              </Col>
              <Col className="mb-4">
                <div className="h-100">
                  <div className="d-flex flex-column justify-content-start">
                    <div className="d-flex flex-column">
                      <div className="text-primary">Manejo especial</div>

                      <div className="responsive-text">Cuando se requiere de algún manejo especial por parte de la paquetería.</div>
                      <ul className="text-muted mt-2 ps-3 ps-md-4 responsive-text">
                        <li>
                          FedEx: $144.00
                          <ul className="ps-1 ps-sm-5">
                            <li>La mercancía sobresalga del empaque principal.</li>
                            <li>
                              El empaque principal no está hecho de cartón corrugado, incluyendo, entre otros, metal, madera, lona, cuero, unicel ( ej.,
                              hieleras), plástico duro o blando, playo, costal, poliestireno expandido (ej., espuma de poliestireno).
                            </li>
                            <li>Empaque cubierto con playo o plástico.</li>
                            <li>Tenga forma redonda, cilíndrica, incluyendo (sin limitarse), tubos, latas, cubetas, barriles, llantas.</li>
                            <li>
                              Esté atado con fleje plástico, fleje metálico, soga, cuerda, cinta de papel, tela o tenga ruedas (ej., bicicletas) manijas o
                              correas, incluídos empaques con playo sin ajustar al empaque, con riesgo de romperse.
                            </li>
                            <li>Tenga más de dos paquetes unidos con diferentes dimensiones, peso máximo 34 kg.</li>
                            <li>Las cajas están dañadas, aplastadas, rotas o mojadas.</li>
                            <li>Pudiera enredarse o causar daños a otros paquetes o al sistema de clasificación FedEx.</li>
                          </ul>
                        </li>
                        <p className="my-3">
                          Nota: FedEx se reserva el derecho de aplicar los cargos adicionales por manejo de paquetes, que requieran manejo especial o que
                          requieran la aplicación de material de protección adicional, durante el trayecto. Si un envío no estándar es elegible para dos o los
                          tres cargos por manejo adicional (dimensión, peso o empaque), solo se aplicará el que tenga el cargo más alto. La forma y las
                          dimensiones del paquete pueden cambiar durante el trayecto, lo que puede afectar la elegibilidad y por lo tanto la aplicación del
                          cargo adicional. Si las dimensiones cambian durante el trayecto, FedEx puede hacer los ajustes apropiados a los cargos de manejo
                          especial en cualquier momento.
                        </p>
                        <li>
                          Estafeta: $199.00
                          <ul className="ps-1 ps-sm-5">
                            <li>La mercancía sobresale del empaque principal.</li>
                            <li>
                              El empaque principal no está hecho de cartón corrugado, incluyendo, entre otros, metal, madera, lona, cuero, unicel ( ej.,
                              hieleras), plástico duro o blando, playo, costal, poliestireno expandido (ej., espuma de poliestireno).
                            </li>
                            <li>Empaque cubierto con playo plástico.</li>

                            <li>Tiene una etiqueta de frágil o bien, tiene forma irregular el paquete.</li>
                            <li>Tiene que entregarse en una zona que sea restringido o limitado el acceso a los mensajeros.</li>
                            <li>Tiene forma redonda, cilíndrica, incluyendo (sin limitarse), tubos, latas,cubetas, barriles, llantas.</li>
                            <li>
                              Está atado con fleje plástico, fleje metálico, soga, cuerda, cinta de papel, tela o tenga ruedas (ej., bicicletas), manijaso
                              correas, incluídos empaques con playo sin ajustar al empaque, con riesgo de romperse.
                            </li>
                            <li>Tiene más de dos paquetes unidos con diferentes dimensiones, peso máximo 34 kg.</li>
                            <li>Las cajas están dañadas, aplastadas, rotas o mojadas.</li>
                            <li>Podría enredarse o causar daños a otros paquetes o al sistema de clasificación de la paquetería.</li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default Surcharges;

import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Row, Col, Button, Card, Form, InputGroup } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import * as Yup from 'yup';
// utils
import { handleAPIError, handleCatchError } from 'utils/handleErrors';

// components
import LoadingPulse from '../../../../components/loading/LoadingPulse';
import LoadingCar from '../../../../components/loading/LoadingCar';
import { ToastSuccess } from '../../../../components/alerts/toast';
// reducer
import { setParcels } from '../../../../slices/generalData/generalData';
// api
import { createParcel, getAllParcels } from '../../../../api/Api-user';

const NewParcel = () => {
  const title = 'Nuevo paquete';
  const description = 'Nuevo Paquete Plataforma Autopaquete';
  const history = useHistory();

  // - Local State
  const dispatch = useDispatch();
  // Local State
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingStart, setIsLoadingStart] = useState(true);
  const [parcel, setParcel] = useState({
    name: '',
    description: '',
    height: '',
    width: '',
    depth: '',
    weight: '',
    value: '',
    insurance: false,
  });
  // - Local State
  const [parcelErr, setParcelErr] = useState({
    name: '',
    description: '',
    height: '',
    width: '',
    depth: '',
    weight: '',
  });
  // Schema Validation
  const validationParcelSchema = Yup.object().shape({
    name: Yup.string().min(3, 'Este campo debe tener mínimo 3 caracteres').required('Campo requerido'),
    description: Yup.string().min(3, 'Este campo debe tener mínimo 3 caracteres').required('Campo requerido'),
    height: Yup.string().required('Campo requerido'),
    width: Yup.string().required('Campo requerido'),
    depth: Yup.string().required('Campo requerido'),
    weight: Yup.string().required('Campo requerido'),
  });
  // - Data Validation and Updating States with Reducers
  const updateField = (data, validation, field) => {
    if (data === '' || validation.test(data)) setParcel({ ...parcel, [field]: data });
  };
  const updateParcels = async () => {
    try {
      localStorage.removeItem('parcels');
      const response = await getAllParcels();

      if (response?.parcel) {
        // 3. Guardar la nueva data en localStorage
        localStorage.setItem('parcels', JSON.stringify(response.parcel));

        // 4. Actualizar el estado global
        dispatch(setParcels({ data: response.parcel }));
      }
    } catch (error) {
      console.error('Error al obtener paquetes:', error);
    }
  };
  const handlerCreateParcel = async () => {
    // console.log('crear parcel data:', parcel);
    try {
      const response = await createParcel(parcel);
      // console.log('Parcel res:', response);
      if (response.name !== undefined) {
        ToastSuccess('¡Paquete guardado con éxito!');
        history.push('/configuración/paquetes');
        updateParcels();
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };
  const validateForm = async (e) => {
    e.preventDefault();
    try {
      await validationParcelSchema.validate(parcel, { abortEarly: false });
      handlerCreateParcel();
    } catch (errors) {
      const formErrors = {};
      errors.inner.forEach((error) => {
        formErrors[error.path] = error.message;
      });
      setParcelErr(formErrors);
      // console.log('errores en from:', formErrors);
      // formAddressFromRef.current.scrollIntoView({ behavior: 'smooth' }); // scroll to error
    }
  };
  setTimeout(() => {
    setIsLoadingStart(false);
  }, 500);
  return (
    <>
      {isLoadingStart && <LoadingCar />}
      {isLoading && <LoadingPulse />}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container">
          <Row className="g-0">
            {/* Title Start */}
            <Col className="col-auto mb-3 mb-sm-0 me-auto">
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/configuración/paquetes">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Atrás</span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
          </Row>
        </div>
        <Row>
          <Form onSubmit={validateForm} id="cardContainer">
            <Col xs="12" lg="10" className="col-lg mt-5">
              {/* Top Buttons Start */}
              <Row className="g-0 mb-5 d-flex justify-content-end">
                <Col xs="auto">
                  <Button variant="outline-primary" className="btn-icon btn-icon-start w-100 w-md-auto" type="submit">
                    <CsLineIcons icon="save" /> <span>Guardar</span>
                  </Button>
                </Col>
              </Row>
              {/* Top Buttons End */}
              {/* Address Start */}
              <Card className="mb-4">
                <Card.Body>
                  <Row className="g-3">
                    <Col lg="6">
                      <Form.Label>Nombre</Form.Label>
                      <span className="text-primary">*</span>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="sign" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          maxLength={35}
                          placeholder="máximo 35 caracteres"
                          onChange={(e) => {
                            setParcelErr({ ...parcelErr, name: '' });
                            updateField(e.target.value, /^[A-Z0-9 ]+$/i, 'name');
                          }}
                          value={parcel.name}
                          type="text"
                        />
                        {parcelErr.name && <div className={`invalid-tooltip ${parcelErr.name ? 'd-block' : 'd-none'}`}>{parcelErr.name}</div>}
                      </InputGroup>
                    </Col>
                    <Col lg="6">
                      <Form.Label>Descripción del contenido</Form.Label>
                      <span className="text-primary">*</span>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="edit-square" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          maxLength={35}
                          placeholder="máximo 35 caracteres"
                          onChange={(e) => {
                            setParcelErr({ ...parcelErr, description: '' });
                            updateField(e.target.value, /^[A-Z0-9 ]+$/i, 'description');
                          }}
                          value={parcel.description}
                          type="text"
                        />
                        {parcelErr.description && (
                          <div className={`invalid-tooltip ${parcelErr.description ? 'd-block' : 'd-none'}`}>{parcelErr.description}</div>
                        )}
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="2">
                      <Form.Label>Largo</Form.Label>
                      <span className="text-primary">*</span>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="box" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          maxLength={3}
                          onChange={(e) => {
                            setParcelErr({ ...parcelErr, depth: '' });
                            updateField(e.target.value, /^[0-9]*$/, 'depth');
                          }}
                          value={parcel.depth}
                          type="text"
                        />
                        {parcelErr.depth && <div className={`invalid-tooltip ${parcelErr.depth ? 'd-block' : 'd-none'}`}>{parcelErr.depth}</div>}
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="2">
                      <Form.Label>Ancho</Form.Label>
                      <span className="text-primary">*</span>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="multiply" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          maxLength={3}
                          onChange={(e) => {
                            setParcelErr({ ...parcelErr, width: '' });
                            updateField(e.target.value, /^[0-9]*$/, 'width');
                          }}
                          value={parcel.width}
                          type="text"
                        />
                        {parcelErr.width && <div className={`invalid-tooltip ${parcelErr.width ? 'd-block' : 'd-none'}`}>{parcelErr.width}</div>}
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="2">
                      <Form.Label>Alto</Form.Label>
                      <span className="text-primary">*</span>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="multiply" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          maxLength={3}
                          onChange={(e) => {
                            setParcelErr({ ...parcelErr, height: '' });
                            updateField(e.target.value, /^[0-9]*$/, 'height');
                          }}
                          value={parcel.height}
                          type="text"
                        />
                        {parcelErr.height && <div className={`invalid-tooltip ${parcelErr.height ? 'd-block' : 'd-none'}`}>{parcelErr.height}</div>}
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="2">
                      <Form.Label>Peso</Form.Label>
                      <span className="text-primary">*</span>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="download" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          maxLength={2}
                          onChange={(e) => {
                            setParcelErr({ ...parcelErr, weight: '' });
                            updateField(e.target.value, /^[0-9]*$/, 'weight');
                          }}
                          value={parcel.weight}
                          type="text"
                        />
                        {parcelErr.weight && <div className={`invalid-tooltip ${parcelErr.weight ? 'd-block' : 'd-none'}`}>{parcelErr.weight}</div>}
                      </InputGroup>
                    </Col>
                    <Col sm="6" md="4">
                      <Form.Label>Valor del contenido</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <CsLineIcons icon="dollar" size="16" className="text-alternate" />
                        </InputGroup.Text>
                        <Form.Control
                          maxLength={5}
                          placeholder="$0"
                          onChange={(e) => {
                            updateField(e.target.value, /^[0-9]*$/, 'value');
                          }}
                          value={parcel.value}
                          type="text"
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {/* Address End */}
            </Col>
          </Form>
        </Row>
      </div>
    </>
  );
};

export default NewParcel;

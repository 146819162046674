import React from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';

// External libraries
import moment from 'moment';

const CardOverweight = ({ overweight }) => {
  return (
    <Card className="mb-2">
      <Row className="g-0 h-100 sh-lg-9 position-relative">
        <Col xs="auto" className="positio-relative">
          <NavLink to="/finanzas/sobrepesos">
            <img src={overweight.service_img} alt="product" className="card-img card-img-horizontal sw-9 sw-sm-11 h-100" />
          </NavLink>
        </Col>
        <Col className="py-4 py-lg-0 ps-4 pe-4 ps-sm-5 h-100">
          <Row className="g-0 h-100 align-content-center">
            <Col xs="12" sm="10" lg="4" className="d-flex flex-column mb-lg-0 mb-3 pe-3 d-flex order-1 h-lg-100 justify-content-center">
              <div className="text-muted text-small d-lg-none mb-1">Servicio / ID Envío</div>
              <div className="text-primary">
                {overweight.shipping_code}
                <div className="text-medium text-muted text-truncate">{`ID: ${overweight.shipping_id}`}</div>
              </div>
            </Col>
            <Col lg="3" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-3">
              <div className="text-muted text-small d-lg-none">Fecha cargo</div>
              <div className="lh-1 text-alternate">{`${moment(overweight.creation_date).format('DD-MM-YYYY')}`}</div>
            </Col>
            <Col sm="8" className="order-4 bg-success d-none d-sm-flex d-lg-none invisible">
              -
            </Col>
            <Col xs="8" sm="2" lg="2" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center  align-items-center order-4">
              <div className="text-muted text-small d-lg-none">Kg cobrados</div>
              <div className="lh-1 text-alternate ">{overweight.declared_kg} </div>
            </Col>
            <Col xs="4" sm="2" lg="2" className="d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start align-items-center justify-content-center order-5">
              <div className="text-muted text-small d-lg-none">Kg reales</div>
              <div className="lh-1 text-alternate">{overweight.real_kg}</div>
            </Col>
            <Col
              xs="12"
              sm="2"
              lg="1"
              className="d-flex flex-column mb-2 mb-lg-0 align-items-start align-items-end align-items-sm-center order-2 order-lg-last justify-content-lg-center"
            >
              <div className="text-muted text-small d-lg-none">Cargo</div>
              <span className="text-alternative text-primary">
                <span className="text-small text-muted text-primary">$</span>
                {overweight.charge.toFixed(2)}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
export default CardOverweight;

import { createSlice } from '@reduxjs/toolkit';
// "parcel": { "height": 1, "width": 5, "depth": 5, "weight": 1, "product_value": 0 }
const initialState = {
  insuranceData: {
    fields: {
      category: '', // only for view
      productState: '', // only for view
      isInsurance: false,
      isProductNew: null,
      commoditieId: '',
      beneficiaryName: '',
      beneficiaryRFC: '',
    },
    errors: {
      // isProductNew: false,
      // commoditieId: false,
      category: false, // only for view
      productState: false, // only for view
      beneficiaryName: false,
      beneficiaryRFC: false,
    },
  },
};

const insuranceDataSlice = createSlice({
  name: 'insuranceData',
  initialState,
  reducers: {
    setInsuranceData(state, action) {
      const { point, data } = action.payload;
      state.insuranceData.fields[point] = data;
    },
    setInsuranceDataError(state, action) {
      const { point, data } = action.payload;
      state.insuranceData.errors[point] = data;
    },
    clearInsuranceForm: (state) => {
      Object.keys(state).forEach((key) => {
        state[key] = initialState[key];
      });
    },
  },
});
export const { setInsuranceData, setInsuranceDataError, clearInsuranceForm } = insuranceDataSlice.actions; // imported in enviar.js
const insuranceDataReducer = insuranceDataSlice.reducer; // store.js set up

export default insuranceDataReducer;

import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
// import CsLineIcons from 'cs-line-icons/CsLineIcons';
// import 'react-toastify/dist/ReactToastify.css';

// components
import InputTextFetchData from '../../../components/form-elements/InputTextFetchData';
import InputText from '../../../components/form-elements/InputText';
import ButtonRequest from '../../../components/buttons/ButtonRequest';
import { ToastErrorApi, ToastErrorCath } from '../../../components/alerts/toast';
// api
import { getShippingDetails, cancelShipping } from '../../../api/Api-user';

const ShippingRefundModal = ({ setIsRefundModalOpen, isRefundModalOpen }) => {
  const initialShippingState = {
    company: '',
    serviceName: '',
    nameTo: '',
    depth: '',
    width: '',
    height: '',
    weight: '',
    volumetricWeight: '',
    totalWeight: '',
  };
  // - Local States
  const [shipping, setShipping] = useState(initialShippingState);
  const [shippingID, setShippingID] = useState('');
  const [requestReason, setRequestReason] = useState('');
  const [isGettingData, setIsGettingData] = useState(false);
  const [isShippingOk, setIsShippingOk] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isAddChargerLoader, setIsAddChargerLoader] = useState(false);
  const [requestError, seRequestError] = useState('');
  const [isRequestError, setIsRequestError] = useState(false);
  const [deleteCount, setDeleteCount] = useState(0);

  const updateField = (data, validation, fieldName) => {
    // Eliminar espacios y caracteres especiales
    const cleanedData = data.replace(/[^a-zA-Z0-9 ]/g, '').trim();
    if (data === '' || validation.test(cleanedData)) {
      setShippingID(cleanedData);
    }
  };
  const updateTextField = (data, validation, fieldName) => {
    if (data === '' || validation.test(data)) {
      setRequestReason(data);
    }
  };
  const closeModal = () => {
    setIsRefundModalOpen(false);
    setShipping(initialShippingState);
    setIsShippingOk(false);
    seRequestError('');
    setIsRequestError(false);
    setShippingID('');
    setRequestReason('');
  };
  const handlerShippingRefund = async () => {
    setIsAddChargerLoader(true);
    try {
      if (shippingID && requestReason) {
        setIsAddChargerLoader(true);
        setIsButtonDisabled(true);
        setTimeout(() => {
          setIsAddChargerLoader(false);
        }, 2000);
        const response = await cancelShipping(shippingID, requestReason);
        // console.log('reembolso response:', response);
        if (response.success !== undefined) {
          toast.success(
            '¡Gracias! Tu solicitud de reembolso fue creada con éxito. Una vez procesado por la paquetería, se sumará a tu saldo y podrás verificarlo en ‘Finanzas/Movimientos’.',
            {
              position: 'top-right',
              autoClose: false,
              hideProgressBar: true,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            }
          );
          closeModal();
        } else if (response.error) {
          const firstKey = Object.keys(response.error)[0];
          ToastErrorApi(response.error[firstKey]);
        }
      } else {
        // throw error shippingID && requestReason are required
        ToastErrorCath('Error: Por favor, completa todos los campos requeridos. Ambos datos son necesarios para continuar.');
      }
    } catch {
      ToastErrorCath();
    } finally {
      setIsAddChargerLoader(false);
    }
  };
  // - Data Validatxion

  const getShippingData = async (e) => {
    const inputValue = e.target.value;
    const cleanedInputValue = inputValue.replace(/[^a-zA-Z0-9 ]/g, '').trim();
    const key = e.nativeEvent.inputType;
    setIsGettingData(true);
    // console.log('valores antes de actualizar', pickup);
    // If the user is deleting
    if (key === 'deleteContentBackward' || key === 'deleteContentForward') {
      setDeleteCount((prevCount) => prevCount + 1);
      if (deleteCount >= 5) {
        // remove all functionality if the user deletes more than 2
        //  characters to avoid massive 406 errors
        setIsGettingData(false);
        setIsShippingOk(false);
        seRequestError('');
        setIsRequestError(false);
        return;
      }
    } else {
      // Reset the counter if the user is typing
      setDeleteCount(0);
    }
    try {
      if (inputValue.length > 5) {
        seRequestError('');
        setIsRequestError(false);
        // Retrasar la solicitud por 1 segundo
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const response = await getShippingDetails(cleanedInputValue);
        // console.log('getShipping response:', response);
        if (response.shipping_code !== undefined) {
          seRequestError('');
          setIsRequestError(false);
          setIsShippingOk(true); // to show div data
          // Set states with data from firebase Start
          setShipping({
            ...shipping,
            // id: cleanedInputValue,
            serviceName: response.shipping_code,
            company: response.shipping_company,
            nameTo: response.address_to.name,
            depth: response.parcel.depth,
            width: response.parcel.width,
            height: response.parcel.height,
            weight: response.parcel.weight,
            volumetricWeight: response.parcel.volumetric_weight,
            totalWeight: response.parcel.total_weight,
          });
        } else if (response.error) {
          const firstKey = Object.keys(response.error)[0];
          setIsRequestError(true);
          setIsShippingOk(false);
          seRequestError(response.error[firstKey]);
          setShipping(initialShippingState);
        }
      } else {
        // if shippingId is < 5 or empty clean any error and shipping data
        setIsRequestError(false);
        seRequestError('');
        setIsShippingOk(false);
        setShipping(initialShippingState);
      }
    } catch {
      setIsRequestError(true);
      setIsShippingOk(false);
      seRequestError('Error: Ha ocurrido un error inesperado. Por favor, inténtalo de nuevo. (catch error)');

      // ToastErrorCath();
    } finally {
      setIsGettingData(false);
    }
  };
  // console.log('envio:', shipping);
  return (
    <Modal show={isRefundModalOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Solicitud de Reembolso</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-infow">
        {/* className="d-flex flex-column " */}
        <>
          <div className={`mb-0 mt-0 align-items-center ${isShippingOk ? 'visible ' : 'invisible'}`}>
            <span className="me-2 text-medium text-primary py-3">{shipping.serviceName}</span>
            <span className="text-medium text-muted text-truncate">{`/ ${shipping.nameTo}`}</span>
          </div>
          <InputTextFetchData
            label=""
            icon="shipping"
            maxLength=""
            placeholder="ID Envío"
            value={shippingID}
            setField={updateField} // actualiza su value debe ser una funcion que primero valide
            isRequired="false"
            isError={isRequestError}
            error={requestError}
            setValues={getShippingData} // funcion que realiza la peticion
            isLoading={isGettingData}
            fieldName="shippingId" // este no deberia setear nungun input su respuesta de la peticion si
            validation={/^[A-Z0-9]+$/i}
            // cleanError={cleanError}
          />
          {/* <p className="invisible">bjfjebjebce</p> */}
          {shipping.company === 'ESTAFETA' ? (
            <div className="text-medium text-alternate bg-successe text-start">
              * Estafeta solo acepta la cancelación de guías no utilizadas, siempre que se haya solicitado la recolección y la unidad no se haya presentado.
            </div>
          ) : (
            <span />
          )}
          <InputText
            icon="edit-square"
            placeholder="Motivo por el que no se uso la guía"
            value={requestReason}
            isRequired
            fieldName="requestReason"
            setField={updateTextField}
          />
        </>
        {/* <hr className="mt-3" /> */}
        <div className="pre-wrap mx-auto mt-5 mb-3 text-center text-alternate bg-infox" style={{ maxWidth: '800px' }}>
          TIEMPO APROXIMADO DE RESPUESTA
          <span className="d-block text-primary">8 días hábiles, directamente en el saldo de tu usuario</span>
        </div>
        <div className="pre-wrap text-medium mx-auto text-center text-alternate">
          NO SE REEMBOLSARÁN GUÍAS CON MÁS DE 30 DÍAS NATURALES A PARTIR DE LA FECHA DE SU CREACIÓN.
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex flex-wrap justify-content-end bg-warningd">
          <ButtonRequest
            title="Enviar Solicitud"
            titleLoading="Enviando"
            handlerFn={handlerShippingRefund}
            isButtonDisabled={isButtonDisabled}
            isLoading={isAddChargerLoader}
          />
          <Button variant="outline-primary" style={{ height: '36px' }} className="w-auto ms-2 mb-2" onClick={closeModal}>
            Cancelar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ShippingRefundModal;

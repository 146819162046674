import React from 'react';
import Lottie from 'lottie-react';
import Animation from '../../animations/AnimationEmptyList.json';

function EmptyList({ message }) {
  return (
    <div className="d-flex flex-column align-items-center mt-xl-5">
      <Lottie animationData={Animation} className="w-10" />
      <h3 className="text-center mt-3">{message}</h3>
    </div>
  );
}

export default EmptyList;

// React and hooks
import React, { useEffect, useState } from 'react';
// Redux
import { useDispatch } from 'react-redux';

// React Router
import { NavLink, useHistory } from 'react-router-dom';

// UI Components
import { Row, Col, Card, Button, Alert } from 'react-bootstrap';

// External Libraries
import CsLineIcons from 'cs-line-icons/CsLineIcons';

// Redux Actions
import { setCurrentUser, setIsLoginUser } from 'slices/auth/authSlice';

// API Calls
import { verifyAccountStatus } from 'api/Api-user';

function UnauthorizedUserPanel() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [account, setAccount] = useState({
    message: 'Autopaquete servicios',
    status: 'Estado de cuenta',
    style: { color: 'danger', icon: 'clock', img: 'documentation' },
  });
  const logout = () => {
    history.push('/login');
    dispatch(setCurrentUser({}));
    dispatch(setIsLoginUser(false));
  };
  const getAssets = (status) => {
    const assets = {
      'Documentación pendiente': { color: 'warning', icon: 'clock', img: 'documentation' },
      'Documentación en revisión': { color: 'info', icon: 'clock', img: 'analytics' },
      'Documentación rechazada': { color: 'danger', icon: 'close-circle', img: 'documentation' },
      'Documentación validada': { color: 'success', icon: 'check-circle', img: 'performance' },
    };
    return assets[status];
  };
  const handlerGetStatusAccount = async () => {
    try {
      const response = await verifyAccountStatus();
      // console.log('status account response:', response);
      if (response.success) {
        setAccount({ message: response.message, status: response.docs_status_tax, style: getAssets(response.docs_status_tax) });
      }
    } catch (error) {
      console.error('Error al verificar el estado de la cuenta:', error);
    }
  };

  useEffect(() => {
    handlerGetStatusAccount();
    // set the interval to run every 15 min (900,000 ms)
    const interval = setInterval(handlerGetStatusAccount, 900000);
    // Clean the interval when the component is disassembled
    return () => clearInterval(interval);
  }, []);

  return (
    <Row className="bg-infox d-flex align-items-start">
      <Col lg="6" className="mb-5 d-flex flex-column justify-content-center order-2 order-lg-1 bg-infox">
        <Card className="sh-auto sh-lg-50 ">
          <Card.Body className="d-flex align-items-center justify-content-center h-100">
            <div className="text-center">
              <img src="/img/illustration/icon-launch.webp" className="theme-filter mb-1" alt="launch" />
              <p className="mb-3 text-primary">¡Gracias por registrarte con nosotros!</p>
              <p className="mb-3">Tu cuenta está en proceso de activación. Pronto podrás acceder a nuestros servicios.</p>
              <p className="mb-3">Si tienes alguna duda o necesitas asistencia, contactanos.</p>
              <div>
                <Button className="btn btn-primary" onClick={() => window.open(`https://wa.me/+523319774057/?text=${`Hola Paulina, ¿Podrías ayudarme?`}`)}>
                  {/* <CsLineIcons icon="support" size="15" className="me-1" /> */}
                  <span>Contactar por whatsapp</span>
                </Button>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col lg="6" className="mb-5 d-flex flex-column justify-content-center order-1 order-lg-2 bg-infox">
        <Card className="sh-auto sh-lg-50  position-relative">
          <Card.Body className="d-flex flex-column align-items-center justify-content-center h-100">
            <div className="bg-infox d-flex justify-content-end position-absolute" style={{ top: '20px', right: '0px' }}>
              <Alert variant={account.style.color} className="d-flex">
                <CsLineIcons icon={account.style.icon} className="me-1" />
                <span> {`${account.status} `}</span>
              </Alert>
            </div>
            <div className="text-center">
              <img src={`/img/illustration/icon-${account.style.img}.webp`} className="theme-filter mb-1" alt="launch" width="150px" />
              <p className="mb-3 text-primary">{account.status}</p>
              <p className="mb-3">{account.message}</p>
              {account.status === 'Documentación pendiente' && (
                <div>
                  <NavLink to="/verificacion" className="btn btn-primary mt-5">
                    {/* <CsLineIcons icon="send" size="15" className="me-1" /> */}
                    <span>Verificar cuenta</span>
                  </NavLink>
                </div>
              )}
              {account.status === 'Documentación validada' && (
                <div>
                  {/* /verificacion */}
                  <Button onClick={logout}>
                    <CsLineIcons icon="rotate-right" size="15" className="me-1" />
                    <span>Iniciar sesión</span>
                  </Button>
                </div>
              )}
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

export default UnauthorizedUserPanel;

import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-react';

const LoaderAnimation = ({ message = 'Cargando, por favor espera...', animationPath }) => {
  const startRef = useRef(null);

  useEffect(() => {
    const contentArea = document.querySelector('#pageContainer');
    if (contentArea) {
      contentArea.style.display = 'none';
    }
    startRef.current?.scrollIntoView({ behavior: 'smooth' });

    return () => {
      setTimeout(() => {
        if (contentArea) contentArea.style.display = 'block';
      }, 30);
    };
  }, []);
  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100" ref={startRef}>
      <div className="d-flex flex-column align-items-center">
        {animationPath && <Lottie animationData={animationPath} loop style={{ width: '200px' }} />}
        <h5 className="mt-3 text-center">{message}</h5>
      </div>
    </div>
  );
};

export default LoaderAnimation;

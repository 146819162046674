import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Row, Card, Button } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { useHistory } from 'react-router-dom';
// utils
import { handleAPIError, handleCatchError } from 'utils/handleErrors';
import formatMoney from 'utils/formatMoney';
// API
import { createShipping } from '../../../../api/Api-user';
// reducers
import { setShippingData } from '../../../../slices/shipping/shippingDataSlice';
import { clearSenderForm } from '../../../../slices/shipping/senderDataSlice';
import { clearReceiverForm } from '../../../../slices/shipping/receiverDataSlice';
import { clearParcelForm } from '../../../../slices/shipping/parcelDataSlice';
import { clearInsuranceForm } from '../../../../slices/shipping/insuranceDataSlice';

const RowCharge = ({ charge = '', cost = 0 }) => {
  const numericCost = Number(cost);

  if (numericCost <= 0) return null; // Prevent rendering if cost is not greater than 0

  return (
    <Row>
      <Col>
        <span>{charge}</span>
      </Col>
      <Col className="d-flex justify-content-end align-items-end">
        <span className="fw-medium align-items-end">
          <span className="text-small text-muted">$ </span>
          {numericCost.toFixed(2)}
        </span>
      </Col>
    </Row>
  );
};
const SupplierCard = ({ rate, shippingData, setIsLoading, page }) => {
  // console.log('RATE DATA:', rate);
  const underscoresRegex = /_+/g;
  const dispatch = useDispatch();
  const history = useHistory();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const goShippingForm = () => {
    // - Clear Form
    dispatch(clearSenderForm());
    dispatch(clearReceiverForm());
    dispatch(clearParcelForm());
    dispatch(clearInsuranceForm());
    history.push('/envios/enviar');
  };
  const handlerCreateShipping = async (shippingCode) => {
    try {
      setIsLoading(true);
      setIsButtonDisabled(true);
      setTimeout(() => setIsButtonDisabled(false), 3000);
      //  console.log('DATOS PARA CREAR GUIA:', shippingCode, shippingData.addressFrom, shippingData.addressTo, shippingData.parcel);
      const response = await createShipping(shippingCode, shippingData);
      if (response?.shipping_label_id !== undefined) {
        dispatch(setShippingData(response));
        history.push('/envios/enviar/documentacion');
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };
  // console.log('DATOS PARA CREAR GUIA:', shippingData.addressFrom, shippingData.addressTo, shippingData.parcel);

  return (
    <Card className="mb-4 me-3 supplier-card">
      {/* position-relative */}
      <Card.Body className="p-4">
        <Row className="g-0">
          {/* - Supplier Title start xxl="4" */}
          <Col xs="12" className="h-sm-50 d-flex w-100 mb-4 bg-secondaryx">
            <img src={rate.service_img} className="rounded" alt="thumb" style={{ height: '85px', width: '100px' }} />
            <div className="ps-3">
              <div>{rate.shipping_code?.replace(underscoresRegex, ' ')}</div>
              <div className="text-muted text-small ">{rate.delivery_days}</div>
              {/* <div className="text-medium">{rate.delivery_type === 'NORMAL' ? 'En domicilio' : 'En sucursal'}</div> */}
              {rate.is_enabled_pickup && (
                <div className="lh-sm">
                  Servicio de recolección
                  <CsLineIcons icon="check-circle" size="14" className="mb-1 ms-1 text-success" />
                </div>
              )}
              {!rate.is_enabled_pickup && (
                <div>
                  Servicio de recolección <CsLineIcons icon="close-circle" size="14" className="mb-1 text-danger" />
                </div>
              )}
              {!rate.allow_request_cancelations && (
                <div className="mb-1 text-warning">
                  Sin reembolso <CsLineIcons icon="warning-hexagon" size="15" className="mb-1 text-warning" />
                </div>
              )}
              {rate.extra_info && <div className="mb-1 fst-italicx">{rate.extra_info}</div>}
            </div>
          </Col>
          {/* - Supplier Title End */}
          {/* - Prices start  xxl="8" */}
          <Col xs="12" className="bg-warningx">
            <div style={{ fontSize: '13px', minHeight: '150px' }} className="bg-infox d-flex flex-column justify-content-end">
              <RowCharge charge="Tarifa base" cost={rate.shipping_rates?.base} />
              <RowCharge charge="Zona extendida" cost={rate.shipping_rates?.extended_area} />
              <RowCharge charge="Kg adicionales" cost={rate.shipping_rates?.extra_weight} />
              <RowCharge charge="Exceso de dimensiones" cost={rate.shipping_rates?.excess_dimensions} />
              <RowCharge charge="Zonificación" cost={rate.shipping_rates?.zonification} />
              <RowCharge charge="Exceso de peso" cost={rate.shipping_rates?.excess_weight} />
              <RowCharge charge="Combustible" cost={rate.shipping_rates?.fuel} />
              <RowCharge charge="Cargo por seguro" cost={rate.shipping_rates?.insurance} />
              <hr className="my-1 mt-2" />
              <Row className="mt-sm-2">
                <Col xs="auto" className="ms-auto ps-3 text-muted">
                  Subtotal
                </Col>
                <Col xs="auto" className="sw-13 text-end">
                  <span>
                    <span className="text-small text-muted">$ </span>
                    {formatMoney(rate.shipping_rates?.subtotal)}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col xs="auto" className="ms-auto ps-3 text-muted">
                  IVA
                </Col>
                <Col xs="auto" className="sw-13 text-end">
                  <span>
                    <span className="text-small text-muted">$ </span>
                    {rate.shipping_rates?.iva.toFixed(2)}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col xs="auto" className="mt-1 ms-auto">
                  <h5 style={{ fontWeight: 'bold', marginBottom: '0' }}>Total</h5>
                </Col>
                <Col xs="auto" className="sw-13 text-end text-primary ps-0">
                  <h4 style={{ fontWeight: 'bold' }} className="mb-0">
                    <span className="text-small text-muted">$ </span>
                    {formatMoney(rate.shipping_rates?.total)}
                  </h4>
                </Col>
              </Row>
            </div>
          </Col>
          <div className="bg-infox d-flex justify-content-end">
            <Button
              className="btn-icon w-50 w-lg-100 mt-4"
              // style={{ maxWidth: '340px' }}
              variant="primary"
              disabled={isButtonDisabled}
              onClick={() => {
                if (page === 'quote') {
                  goShippingForm();
                } else {
                  handlerCreateShipping(rate.shipping_code);
                }
              }}
            >
              <span>Crear envio</span>
              <CsLineIcons icon="chevron-right" />
            </Button>
          </div>

          {/* - Prices End  */}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SupplierCard;

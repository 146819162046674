// 1. React and core dependencies
import React from 'react';

// 2. UI Components
import { Form, InputGroup } from 'react-bootstrap';

// 3. Third-party libraries
import CsLineIcons from 'cs-line-icons/CsLineIcons';

// 4. Utility functions
import { updateFormField, deleteFormError } from 'utils/updateFormField';
// props
// label, icon, maxLength, placeholder, value, isRequired, helpText
// fieldName, validation, formObj, setErrors
// actualizar este componente para que muestre los errores
// {errors.packageTape && <div className={`invalid-tooltip ${errors.packageTape ? 'd-block' : 'd-none'}`}>{errors.packageTape}</div>}
// THIS COMPONENT HANDLES GLOBAL STATE WITH REDUX
const InputText = ({
  label = '',
  icon = '',
  maxLength = null,
  placeholder = '',
  value = '',
  isRequired = false,
  helpText = '',
  fieldName = '',
  validation = /^[A-Z0-9 ]+$/i,
  formObj = () => {},
  setErrors = () => {},
}) => {
  return (
    <>
      <Form.Label>{label}</Form.Label>
      {isRequired && <span className="text-primary ms-1">*</span>}
      <InputGroup>
        <InputGroup.Text>
          <CsLineIcons icon={icon} size="16" className="text-alternate" />
        </InputGroup.Text>
        <Form.Control
          type="text"
          maxLength={maxLength}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            // console.log('datos para setear el obj:', formObj, e.target.value, validation, fieldName);
            updateFormField(formObj, e.target.value, validation, fieldName);
            deleteFormError(setErrors, fieldName);
          }}
        />
      </InputGroup>
      <span className="text-medium">{helpText}</span>
    </>
  );
};

export default InputText;

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { Row, Col, Card, Tooltip, OverlayTrigger, Badge } from 'react-bootstrap';
// External libraries
import moment from 'moment';
// utils
import formatMoney from 'utils/formatMoney';
import trackingStatus from 'utils/validationTrackingStatus';
// components
import ShippingModal from './ShippingModal';

const ShippingCard = ({ data, index, setUpdatePage }) => {
  const [isModalAdOpen, setIsModalAdOpen] = useState(false);
  const [isModalOwOpen, setIsModalOwOpen] = useState(false);
  const { currentUser } = useSelector((state) => state.auth);
  const status = data?.current_tracking?.tracking_status;

  // Validation status
  const validatedStatus = trackingStatus(status);
  // give status color
  const getStatusColor = (state) => {
    const statusColors = {
      'Etiqueta creada': 'muted',
      Recolectado: 'tertiary',
      'Entrega actualizada': 'info',
      'En camino': 'primary',
      Entregado: 'success',
      Excepción: 'warning',
      'Proceso de Entrega': 'quaternary',
    };
    return statusColors[state] || 'secondary'; // admin
  };
  // const status = 'Etiqueta creada';
  const color = getStatusColor(validatedStatus);
  // console.log(`El color para "${status}" es:`, color);
  return (
    <>
      {/* Modal Add Charges Start */}
      {isModalAdOpen && (
        <ShippingModal
          data={data}
          isModalOpen={isModalAdOpen}
          setIsModalOpen={setIsModalAdOpen}
          title="Cargos Adicionales"
          type="ad"
          setUpdatePage={setUpdatePage}
        />
      )}
      {isModalOwOpen && (
        <ShippingModal data={data} isModalOpen={isModalOwOpen} setIsModalOpen={setIsModalOwOpen} title="Sobrepeso" type="ow" setUpdatePage={setUpdatePage} />
      )}
      {/* Modal Add Charges End */}
      <Card className="mb-2 py-4 py-xl-0 ps-5 pe-4 h-100 text-alternate">
        <Badge pill bg="primary" className="me-1 position-absolute s-n2 t-2 z-index-1 t-1">
          {index + 1}
        </Badge>
        <Row className="g-0 h-100 sh-xl-9 position-relative overflow-y-hidden">
          {/* <div className="lh-1 text-muted">{`${counter}.`}</div> */}
          <Col xs="12" xl="3" className="bg-infox d-flex flex-column mb-xl-0 mb-3 pe-3 d-flex h-xl-100 justify-content-center order-3 order-xl-1">
            <div className="text-muted text-small d-xl-none">Razón Social</div>
            {/* <OverlayTrigger delay={{ show: 800, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Ver perfil</Tooltip>}></OverlayTrigger> */}
            <NavLink className="anchor-effect-primary" to={`/users-all/detail/${data?.crm_user}`}>
              {data.razon_social.toUpperCase()}
            </NavLink>
          </Col>

          <Col xs="6" xl="1" className="bg-secondaryx d-flex flex-column pe-1 pe-xl-2 mb-2 mb-xl-0 justify-content-center order-last order-xl-2">
            <div className="text-muted text-small d-xl-none">Fecha / Hora</div>
            {/* creation_date:"2024-10-04T11:40:59.362067Z" */}
            <span className="d-flex  flex-xl-column">
              <div className="lh-1 text-alternate">{moment(data.creation_date).format('DD-MM-YY')}</div>
              <div className="lh-1 text-alternate mt-0 mt-xl-1 ms-1 ms-xl-0">{data?.creation_date.slice(11, 19)}</div>
            </span>
          </Col>

          <Col
            xs="12"
            xl="2"
            className="bg-successx mb-2 mb-xl-0 d-flex flex-column justify-content-center align-items-xl-start order-4 order-xl-3 overflow-auto"
          >
            <div className="text-muted text-small d-xl-none">ID Envío</div>
            <OverlayTrigger delay={{ show: 800, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Ver detalle de envío</Tooltip>}>
              <NavLink className="anchor-effect-primary" to={`/shipping-all/detail/${data?.shipping_label_id}/${data?.razon_social}/${data?.crm_user}`}>
                {data?.shipping_label_id}
              </NavLink>
            </OverlayTrigger>
          </Col>

          <Col xl="2" className="bg-infox d-flex flex-column pe-1 ps-xl-3 mb-2 mb-xl-0 justify-content-center order-5 order-xl-4">
            <div className="text-muted text-small d-xl-none">Servicio</div>

            <div>{data.shipping_code?.replace(/_+/g, ' ')}</div>
          </Col>
          <Col xs="12" xl="2" className="bg-secondaryx mb-2 mb-xl-0 order-3 d-flex justify-content-end justify-content-xl-center align-items-center order-xl-5">
            <Badge bg={color} className="text-uppercase fw-bolder" style={{ fontSize: '12px' }}>
              {validatedStatus}
              {/* Etiqueta creada */}
            </Badge>
          </Col>
          <Col xs="6" xl="1" className="bg-ternaryx d-flex flex-column pe-1 mb-2 mb-xl-0 justify-content-center align-items-end order-last order-xl-5 text-end">
            <div className="text-muted text-small d-xl-none">Costo</div>
            <div className={`${data.refunded ? 'text-success' : 'text-primary'}`}>
              <span className="text-small text-muted">$</span>
              {formatMoney(data.shipping_rates?.total)}
              <div className={`text-small text-success ${data.refunded ? 'd-block' : 'd-none'}`}>Guía devuelta</div>
            </div>
          </Col>
          <Col
            xs="12"
            xl="1"
            className={`bg-secondaryx ps-4 d-flex mb-2 mb-xl-0 align-items-center justify-content-end order-1 order-xl-last ${
              currentUser.access !== 'Control Total' ? 'd-none' : 'd-flex'
            }`}
          >
            <div onClick={() => setIsModalAdOpen(true)}>
              <CsLineIcons icon="wallet" className="anchor-effect-primary me-2 cursor-pointer" />
            </div>
            <div onClick={() => setIsModalOwOpen(true)}>
              <CsLineIcons icon="warning-hexagon" className="anchor-effect-primary cursor-pointer" />
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};
export default ShippingCard;

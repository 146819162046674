// libraries
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastSuccess = (msg) => {
  toast.success(msg, {
    position: 'top-right',
    autoClose: 8000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });
};
const ToastErrorApi = (array, errCode = '') => {
  let msgErr = ' ';
  array.forEach((error) => {
    msgErr = `Error: ${error}`;
  });
  toast.error(msgErr, {
    position: 'top-right',
    autoClose: 8000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });
};
const ToastErrorCath = (msg = 'Error: Ha ocurrido un error inesperado. Por favor, inténtalo de nuevo. (catch error)') => {
  toast.error(msg, {
    position: 'top-right',
    // autoClose: 8000,
    autoClose: 10000,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'dark',
  });
};

export { ToastSuccess, ToastErrorApi, ToastErrorCath };

// const firstKey = Object.keys(response.error)[0];
// ToastErrorApi(response.error[firstKey]);

// const firstKey = Object.keys(response.error)[0];
// const errorMessage = response.error.shippings[0].trim();
// if (!errorMessage.includes('No se encontraron envíos con los parámetros especificados.')) {
// solo si es diferente a este error muestralo
// es para que salga este mensaje cuando el historial esta vacio
//   ToastErrorApi(response.error[firstKey]);
// }

// dropdown  [].length < 10 && 'd-none' (en los que tienen excel debe ser d-none)
// excel {reps?.length > 0 && <RepsCSV reps={reps} />} si es mayor a 0 que se renderice
// numero de pagina [].length < 2 && 'd-none' (si tiene que estar aunque exista un item)
// Row [].length === 0 && 'invisible' (en overweight and pickups no)
// paginate totalPages < 2 && 'd-none'}

/* eslint-disable jsx-a11y/no-onchange */

// React core imports
import React, { useEffect, useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';

// Third-party libraries
import { Row, Col, Dropdown, Button } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

// Page metadata
import HtmlHead from 'components/html-head/HtmlHead';

// Utility functions
import { handleCatchError } from 'utils/handleErrors';

// API functions
import { getSupportTickets } from 'api/Api-user';

// UI Components
import { ToastErrorApi } from 'components/alerts/toast';
import Paginate from 'components/Paginate';

// Loading Components
import LoadingPulse from 'components/loading/LoadingPulse';
import LoadingCar from 'components/loading/LoadingCar';

// Page-specific Components
import CardIncident from './components/CardIncident';
import IncidentTypeModal from './components/IncidentTypeModal';

const Reports = () => {
  const title = 'Reportes';
  const description = 'Reporte Incidentes Plataforma Autopaquete';
  // const dispatch = useDispatch();
  // -Local States
  const [tickets, setTickets] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [changePage, setChangePage] = useState(1); // para la peticion
  const [changeItemsNum, setChangeItemsNum] = useState(10); // para la peticion
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  // - Use references to do scroll Up
  const startRef = useRef(null);

  // - Get all pickups
  const handlerGetTickets = async () => {
    try {
      if (tickets) {
        setIsLoading(true);
      }
      const result = await getSupportTickets(changePage, changeItemsNum);
      if (result.tickets !== undefined) {
        setTickets(result.tickets);
        setCurrentPage(result.current_page); // para actualizar la vista
        setPageSize(result.page_size); // para actualizar la vista
        setTotalPages(result.total_pages);
        if (startRef.current) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (result.error) {
        // setTotalPages(0);
        setTickets(tickets || []); // si tickets es falsy actualiza con [] para eliminar el loader
        const firstKey = Object.keys(result.error)[0];
        const errorMessage = result.error[firstKey][0].trim();
        if (!errorMessage.includes('No hay resultados para mostrar.')) {
          // solo si es diferente a este error muestralo
          ToastErrorApi(result.error[firstKey]);
        } else {
          setMessage('Aún no tienes reportes registrados.');
        }
      }
    } catch (error) {
      setTickets(tickets || []);
      handleCatchError(error);
      setMessage('');
    } finally {
      setIsLoading(false);
    }
  };
  const settingPage = (limit) => {
    // setIsLoading(true);
    setChangePage(1);
    setChangeItemsNum(limit);
  };
  useEffect(() => {
    handlerGetTickets();
  }, [changePage, changeItemsNum]);
  return (
    <>
      {tickets === null && <LoadingCar />}
      {isLoading && <LoadingPulse />}
      <IncidentTypeModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container" ref={startRef}>
          <Row className="g-0">
            {/* Title Start */}
            <Col className="col-auto mb-3 mb-sm-0 me-auto">
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Inicio</span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
            {/* Top Buttons Start */}
            <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
              <Button variant="outline-primary" style={{ height: '36px' }} className="d-flex align-items-center" onClick={() => setIsModalOpen(true)}>
                <CsLineIcons icon="plus" size="14" /> <span className="ms-1">Crear reporte</span>
              </Button>
            </Col>
            {/* Top Buttons End */}
          </Row>
        </div>

        <Row className="mb-3">
          <Col md="5" lg="3" xxl="2" className="mb-1" />

          <Col md="7" lg="9" xxl="10" className="mb-1 text-end">
            {/* Length Start */}
            <Dropdown align={{ xs: 'end' }} className={`d-inline-block ms-1 ${tickets?.length < 10 && 'invisible'}`}>
              <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-20">
                {`${pageSize} reportes`}
              </Dropdown.Toggle>
              <Dropdown.Menu className="shadow dropdown-menu-end">
                <Dropdown.Item className={`${pageSize === 10 && 'd-none'}`} onClick={() => settingPage(10)}>
                  10 reportes
                </Dropdown.Item>
                <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                  20 reportes
                </Dropdown.Item>
                <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                  50 reportes
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className={`text-muted mt-3 d-lg-none ${tickets?.length < 2 && 'd-none'}`}>{`Página ${currentPage}`}</div>
            {/* Length End */}
          </Col>
        </Row>
        <div style={{ minHeight: '50vh' }} id="cardContainer">
          {/* List Header Start */}
          <div className={`d-none d-lg-flex text-muted mb-3 ${tickets?.length === 0 && 'invisible'}`}>{`Página ${currentPage}`}</div>
          <Row className="g-0 mb-2 d-flex align-items-center ps-5 pe-5 d-none d-lg-flex">
            <Col lg="4" className="d-flex flex-column mb-lg-0 pe-3 d-flex bg-infow">
              <div className="text-muted text-small cursor-pointer">REPORTE / ID ENVÍO</div>
            </Col>
            <Col lg="2" className="d-flex flex-column pe-1 justify-content-center align-items-start bg-alternatew">
              <div className="text-muted text-small cursor-pointer">FECHA CREACIÓN</div>
            </Col>
            <Col lg="1" className="d-flex flex-column pe-1 justify-content-center align-items-center bg-successw">
              <div className="text-muted text-small cursor-pointer">ID REPORTE</div>
            </Col>
            <Col lg="2" className="d-flex flex-column pe-1 ps-3 justify-content-center align-items-end bg-successw">
              <div className="text-muted text-small cursor-pointer">ULTIMA ACTUALIZACIÓN</div>
            </Col>
            <Col lg="1" className="d-flex flex-column pe-1 justify-content-center align-items-lg-end bg-alternatew">
              <div className="text-muted text-small text-end cursor-pointer">AGENTE</div>
            </Col>
            <Col lg="2" className="d-flex flex-column pe-1 justify-content-center mb-2 mb-lg-0 order-2 order-lg-last bg-secondaryw">
              <div className="text-muted text-small text-end cursor-pointer">ESTADO</div>
            </Col>
          </Row>
          {/* List Header End */}
          {/* List Items Start */}
          {tickets?.length === 0 ? (
            <div className="py-5 mt-5 d-flex flex-column justify-content-center align-items-center w-50 m-auto">
              <h3 className="text-center my-5">{message}</h3>
            </div>
          ) : (
            <>
              {/* List tickets Items Start */}
              {tickets?.map((ticket) => (
                <CardIncident key={ticket.id} ticket={ticket} />
              ))}
              {/* List tickets Items End */}
              {/* Pagination Start */}
              <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages < 2 && 'd-none'}`}>
                <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setChangePage} />
              </div>
              {/* Pagination End */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Reports;

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Button, Card, Col, Form, Row, Dropdown, ListGroup, ListGroupItem, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
// libraries
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import * as Yup from 'yup';
import { motion, AnimatePresence } from 'framer-motion';
import NumberFormat from 'react-number-format';

// utils
import { handleAPIError, handleCatchError } from 'utils/handleErrors';

// components
import HtmlHead from 'components/html-head/HtmlHead';
import LoaderAnimation from 'components/loading/LoaderAnimation';
import animationBoxes from '../../../animations/AnimationBoxes.json';
// import LoadingCar from '../../../components/loading/LoadingCar';
import { ToastSuccess, ToastErrorCath } from '../../../components/alerts/toast';
import InsuranceToggle from './components/InsuranceToggle';
// api
import { getZipCode, getRates, createAddress, createParcel, getAllAddressesToForm, getAllParcels } from '../../../api/Api-user';
// reducers
import { setSenderData, setSenderDataError } from '../../../slices/shipping/senderDataSlice';
import { setReceiverData, setReceiverDataError } from '../../../slices/shipping/receiverDataSlice';
import { setParcelData, setParcelDataError } from '../../../slices/shipping/parcelDataSlice';
import { setRatesData } from '../../../slices/shipping/ratesDataSlice';
import { setInsuranceData, setInsuranceDataError } from '../../../slices/shipping/insuranceDataSlice';
import { setAddressesTo, setAddressesFrom, setParcels } from '../../../slices/generalData/generalData';

const ShippingForm = () => {
  const title = 'Enviar';
  const description = 'Enviar Plataforma Autopaquete';
  const dispatch = useDispatch();
  const history = useHistory();
  // - Global State from Store.js form Values
  const { currentUser } = useSelector((state) => state.auth);
  const { senderData } = useSelector((state) => state.shipping_sender);
  const { receiverData } = useSelector((state) => state.shipping_receiver);
  const { parcelData } = useSelector((state) => state.shipping_parcel);
  const { insuranceData } = useSelector((state) => state.shipping_insurance);
  const { generalData } = useSelector((state) => state.general_data);
  // - Local States
  const [isLoading, setIsLoading] = useState(false);
  const [pieces, setPieces] = useState(1);
  const [saveReceiverAddress, setSaveReceiverAddress] = useState(false);
  const [saveSenderAddress, setSaveSenderAddress] = useState(false);
  const [saveParcel, setSaveParcel] = useState(false);
  // Start Directory states
  const [directoryFrom, setDirectoryFrom] = useState([]);
  const [directoryTo, setDirectoryTo] = useState([]);
  const [directoryParcels, setDirectoryParcels] = useState([]);
  const [isHiddenAddressesTo, setIsHiddenAddressesTo] = useState(true);
  const [isHiddenAddressesFrom, setIsHiddenAddressesFrom] = useState(true);
  const [isHiddenParcels, setIsHiddenParcels] = useState(true);

  // End Directory states

  // - Use references to do scroll on this page
  const formAddressFromRef = useRef(null);
  const formAddressToRef = useRef(null);
  const formParcelRef = useRef(null);
  const multiPiecesOk = currentUser.multipieces;
  const hasSetParcels = useRef(false);
  const hasSetAddressesFrom = useRef(false);
  const hasSetAddressesTo = useRef(false);

  // - Schema Validations Start
  const validationAddressesSchema = Yup.object().shape({
    // company and references are not required
    name: Yup.string().min(3, 'El nombre debe tener al menos 3 caracteres.').required('El nombre es obligatorio.'),
    street: Yup.string().min(3, 'La calle debe tener al menos 3 caracteres.').required('La calle es obligatoria.'),
    streetNumber: Yup.string().required('El número de calle es obligatorio.'),
    neighborhood: Yup.string().min(3, 'La colonia debe tener al menos 3 caracteres.').required('La colonia es obligatoria.'),
    zip: Yup.string().min(5, 'El código postal debe tener 5 caracteres.').required('El código postal es obligatorio.'),
    phone: Yup.string().min(10, 'El número de teléfono debe tener 10 caracteres.').required('El número de teléfono es obligatorio.'),
  });
  const validationParcelSchema = Yup.object().shape({
    // name is not required
    product_description: Yup.string().min(3, 'La descripción debe tener mínimo 3 caracteres.').required('La descripción del producto es obligatoria.'),
    height: Yup.string().required('Alto, largo y ancho son requeridos.'),
    width: Yup.string().required('Alto, largo y ancho son requeridos.'),
    depth: Yup.string().required('Alto, largo y ancho son requeridos.'),
    weight: Yup.string().required('El peso del paquete es obligatorio.'),
    product_value: Yup.string().required('Obligatorio para la carta porte. Si deseas asegurar el envío, se tomará este monto.'),
    // Campos condicionales para seguro
    // product_value: Yup.string().when('$isInsurance', {
    //   is: true, // Evalúa si isInsurance es true
    //   then: Yup.string().required('El valor del seguro es obligatorio.'),
    //   otherwise: Yup.string().notRequired(),
    // }),
  });
  const validationInsuranceSchema = Yup.object().shape({
    // Campos condicionales
    commoditieId: Yup.string().when('$isInsurance', {
      is: true,
      then: Yup.string().required('Selecciona una categoría válida de la lista.'),
      otherwise: Yup.string().notRequired(),
    }),
    productState: Yup.string().when('$isInsurance', {
      is: true,
      then: Yup.string().required('Elige una opción para el estado del producto.'),
      otherwise: Yup.string().notRequired(),
    }),
    beneficiaryName: Yup.string().when('$isInsurance', {
      is: true,
      then: Yup.string().required('Selecciona un beneficiario.'),
      otherwise: Yup.string().notRequired(),
    }),
    // beneficiaryRFC: Yup.string().when('$isInsurance', {
    //   is: true,
    //   then: Yup.string().required('El RFC del beneficiario es obligatorio.'),
    //   otherwise: Yup.string().notRequired(),
    // }),
  });
  // - Schema Validations End
  // remove accents
  const cleanData = (str) => {
    if (typeof str !== 'string') return str; // Si no es string, devolver tal cual

    // Reemplazo manual de vocales acentuadas y cedilla
    const accentsMap = {
      á: 'a',
      é: 'e',
      í: 'i',
      ó: 'o',
      ú: 'u',
      Á: 'A',
      É: 'E',
      Í: 'I',
      Ó: 'O',
      Ú: 'U',
      ä: 'a',
      ë: 'e',
      ï: 'i',
      ö: 'o',
      ü: 'u',
      Ä: 'A',
      Ë: 'E',
      Ï: 'I',
      Ö: 'O',
      Ü: 'U',
      ã: 'a',
      õ: 'o',
      ñ: 'ñ',
      Ñ: 'Ñ',
      ç: 'c',
      Ç: 'C',
    };
    return str
      .split('')
      .map((char) => accentsMap[char] || char) // Reemplaza acentos manualmente
      .join('')
      .replace(/[^a-zA-Z0-9ñÑ ]/g, ''); // Elimina caracteres especiales, dejando la ñ
  };
  // - Data Validation and Updating States with Reducers
  const updateField = (reducer, data, point, max) => {
    if (
      point === 'phone' ||
      point === 'zip' ||
      point === 'weight' ||
      point === 'height' ||
      point === 'width' ||
      point === 'depth' ||
      point === 'product_value_shipping'
    ) {
      // save numbers only
      if (data === '' || /^[0-9]+$/i.test(data)) dispatch(reducer({ data, point }));
    } else {
      // save string
      // console.log('Data que da error:', data, 'tipo', typeof data, 'campo:', point);
      // eslint-disable-next-line no-lonely-if
      if (typeof data === 'string') {
        const cleanedData = cleanData(data).substring(0, max);
        dispatch(reducer({ data: cleanedData, point }));
      }
    }
  };
  // - CP validation Start
  const validateZipCode = async (zipCode, reducerData, reducerError) => {
    if (zipCode.length !== 5) {
      // Cuando el usuario está escribiendo y el código no tiene 5 dígitos
      dispatch(reducerError({ data: '', point: 'zip' }));
      dispatch(reducerData({ data: '', point: 'city' }));
      dispatch(reducerData({ data: { name: '', key: '' }, point: 'state' }));
      return; // Detiene la ejecución de la función aquí
    }
    try {
      const response = await getZipCode(zipCode);
      if (response) {
        if (response?.length > 0) {
          dispatch(reducerData({ data: response[0].city, point: 'city' }));
          dispatch(reducerData({ data: response[0].state, point: 'state' }));
        } else {
          dispatch(reducerError({ data: 'Código postal invalido', point: 'zip' }));
        }
      } else {
        dispatch(reducerError({ data: 'Error de conexión. Ingresa el código nuevamente.', point: 'zip' }));
      }
    } catch {
      dispatch(reducerError({ data: 'Sin conexión a Internet', point: 'zip' }));
    }
  };
  useEffect(() => {
    validateZipCode(senderData.fields.zip, setSenderData, setSenderDataError);
  }, [senderData.fields.zip]);
  useEffect(() => {
    validateZipCode(receiverData.fields.zip, setReceiverData, setReceiverDataError);
  }, [receiverData.fields.zip]);
  // - CP validation End
  // - If the parcel will not be saved, the errors are eliminated
  useEffect(() => {
    if (!saveParcel) {
      dispatch(setParcelDataError({ data: false, point: 'name' }));
    }
  }, [saveParcel, dispatch]);
  // - If sender address is autofill correctly, eliminate any errors (autofill function)
  useEffect(() => {
    if (
      senderData.fields.name ||
      senderData.fields.street ||
      senderData.fields.streetNumber ||
      senderData.fields.neighborhood ||
      senderData.fields.zip ||
      senderData.fields.phone
    ) {
      dispatch(setSenderDataError({ point: 'name', data: false }));
      dispatch(setSenderDataError({ point: 'streetNumber', data: false }));
      dispatch(setSenderDataError({ point: 'street', data: false }));
      dispatch(setSenderDataError({ point: 'neighborhood', data: false }));
      dispatch(setSenderDataError({ point: 'zip', data: false }));
      dispatch(setSenderDataError({ point: 'phone', data: false }));
    }
  }, [
    senderData.fields.name,
    // senderData.fields.company,
    senderData.fields.street,
    senderData.fields.streetNumber,
    senderData.fields.neighborhood,
    // senderData.fields.placeRef,
    senderData.fields.zip,
    senderData.fields.phone,
    dispatch,
  ]);
  // - If receiver address is autofill correctly, eliminate any errors
  useEffect(() => {
    if (
      receiverData.fields.name ||
      receiverData.fields.company ||
      receiverData.fields.street ||
      receiverData.fields.streetNumber ||
      receiverData.fields.neighborhood ||
      receiverData.fields.placeRef ||
      receiverData.fields.zip ||
      receiverData.fields.phone
    ) {
      dispatch(setReceiverDataError({ point: 'name', data: false }));
      dispatch(setReceiverDataError({ point: 'company', data: false }));
      dispatch(setReceiverDataError({ point: 'streetNumber', data: false }));
      dispatch(setReceiverDataError({ point: 'street', data: false }));
      dispatch(setReceiverDataError({ point: 'neighborhood', data: false }));
      dispatch(setReceiverDataError({ point: 'placeRef', data: false }));
      dispatch(setReceiverDataError({ point: 'zip', data: false }));
      dispatch(setReceiverDataError({ point: 'phone', data: false }));
    }
  }, [
    receiverData.fields.name,
    receiverData.fields.company,
    receiverData.fields.street,
    receiverData.fields.streetNumber,
    receiverData.fields.neighborhood,
    receiverData.fields.placeRef,
    receiverData.fields.zip,
    receiverData.fields.phone,
    dispatch,
  ]);
  // - If parcel is autofill correctly, eliminate any errors
  useEffect(() => {
    if (
      parcelData.fields.name ||
      parcelData.fields.product_description ||
      parcelData.fields.width ||
      parcelData.fields.height ||
      parcelData.fields.depth ||
      parcelData.fields.weight
    ) {
      dispatch(setParcelDataError({ point: 'name', data: false }));
      dispatch(setParcelDataError({ point: 'product_description', data: false }));
      dispatch(setParcelDataError({ point: 'height', data: false }));
      dispatch(setParcelDataError({ point: 'width', data: false }));
      dispatch(setParcelDataError({ point: 'depth', data: false }));
      dispatch(setParcelDataError({ point: 'weight', data: false }));
    }
  }, [
    parcelData.fields.name,
    parcelData.fields.product_description,
    parcelData.fields.width,
    parcelData.fields.height,
    parcelData.fields.depth,
    parcelData.fields.weight,
    dispatch,
  ]);
  // - If parcel is autofill correctly, eliminate any errors
  useEffect(() => {
    dispatch(setParcelData({ data: pieces, point: 'multi_pieces' }));
  }, [pieces, dispatch]);
  // - Get All Addresses From
  useEffect(() => {
    if (!hasSetAddressesFrom.current) {
      const storedAddressesFrom = JSON.parse(localStorage.getItem('addressesFrom'));
      if (storedAddressesFrom) {
        //  ** Updates global state **
        dispatch(setAddressesFrom({ data: storedAddressesFrom }));
        // ** Update local state **
        setDirectoryFrom(storedAddressesFrom);
      } else {
        getAllAddressesToForm('SEND')
          .then((response) => {
            if (response?.address) {
              localStorage.setItem('addressesFrom', JSON.stringify(response.address));
              //  ** Updates global state **
              dispatch(setAddressesFrom({ data: response.address }));
              // ** Update local state **
              setDirectoryFrom(response.address);
            }
          })
          .catch((error) => console.error('Error al obtener direcciones:', error));
      }
      hasSetAddressesFrom.current = true;
    }
  }, [dispatch]);
  // - Get All Addresses To
  useEffect(() => {
    if (!hasSetAddressesTo.current) {
      const storedAddressesTo = JSON.parse(localStorage.getItem('addressesTo'));
      if (storedAddressesTo) {
        //  ** Updates global state **
        dispatch(setAddressesTo({ data: storedAddressesTo }));
        // ** Update local state **
        setDirectoryTo(storedAddressesTo);
      } else {
        getAllAddressesToForm('RECEIVE')
          .then((response) => {
            if (response?.address) {
              // si no existen los crea en anmbas partes
              localStorage.setItem('addressesTo', JSON.stringify(response.address));
              //  ** Updates global state **
              dispatch(setAddressesTo({ data: response.address }));
              // ** Update local state **
              setDirectoryTo(response.address);
            }
          })
          .catch((error) => console.error('Error al obtener direcciones:', error));
      }
      hasSetAddressesTo.current = true;
    }
  }, [dispatch]);
  // - Get All Parcels
  useEffect(() => {
    if (!hasSetParcels.current) {
      const storedParcels = JSON.parse(localStorage.getItem('parcels'));
      if (storedParcels) {
        //  ** Updates global state **
        dispatch(setParcels({ data: storedParcels }));
        // ** Update local state **
        setDirectoryParcels(storedParcels);
      } else {
        getAllParcels()
          .then((response) => {
            if (response?.parcel) {
              localStorage.setItem('parcels', JSON.stringify(response.parcel));
              //  ** Updates global state **
              dispatch(setParcels({ data: response.parcel }));
              // ** Update local state **
              setDirectoryParcels(response.parcel);
            }
          })
          .catch((error) => console.error('Error al obtener paquetes:', error));
      }
      hasSetParcels.current = true;
    }
  }, [dispatch]);

  // - Get Address to sender address form
  const filterDirectoryFrom = (string) => {
    setIsHiddenAddressesFrom(false);
    if (string) {
      setDirectoryFrom(
        generalData.fields.addressesFrom?.filter((category) => {
          const searchTerm = string.trim().toLowerCase();
          return searchTerm === '' || category.name.toLowerCase().includes(searchTerm);
        })
      );
    } else {
      setDirectoryFrom(generalData.fields.addressesFrom);
    }
  };
  // - Get Address to receiver address form
  const handlerDirectoryTo = (string) => {
    setIsHiddenAddressesTo(false);
    if (string) {
      setDirectoryTo(
        generalData.fields.addressesTo?.filter((category) => {
          const searchTerm = string.trim().toLowerCase();
          return searchTerm === '' || category.name.toLowerCase().includes(searchTerm);
        })
      );
    } else {
      setDirectoryTo(generalData.fields.addressesTo);
    }
  };
  // - Get Parcels
  const filterDirectoryParcels = (string) => {
    setIsHiddenParcels(false);
    if (string) {
      //  console.log('filtrar con :', string);
      setDirectoryParcels(
        generalData.fields.parcels?.filter((category) => {
          const searchTerm = string.trim().toLowerCase();
          return searchTerm === '' || category.name.toLowerCase().includes(searchTerm);
        })
      );
    } else {
      setDirectoryParcels(generalData.fields.parcels);
    }
  };
  // - Autofill Sender Address Form
  const fillSenderAddress = (address) => {
    setIsHiddenAddressesFrom(true);
    // - Fill inputs
    updateField(setSenderData, address.name, 'name', 29);
    updateField(setSenderData, address.company, 'company', 29);
    updateField(setSenderData, address.street_number, 'streetNumber', 10);
    updateField(setSenderData, address.neighborhood, 'neighborhood', 35);
    updateField(setSenderData, address.zip_code, 'zip', 5);
    updateField(setSenderData, address.street, 'street', 50);
    updateField(setSenderData, address.phone, 'phone', 10);
    updateField(setSenderData, address.reference, 'placeRef', 20);
  };
  // - Autofill Receiver Address Form
  const fillReceiverAddress = (address) => {
    setIsHiddenAddressesTo(true);
    // - Fill inputs
    updateField(setReceiverData, address.name, 'name', 29);
    updateField(setReceiverData, address.company, 'company', 29);
    updateField(setReceiverData, address.street_number, 'streetNumber', 10);
    updateField(setReceiverData, address.neighborhood, 'neighborhood', 35);
    updateField(setReceiverData, address.zip_code, 'zip', 5);
    updateField(setReceiverData, address.street, 'street', 50);
    updateField(setReceiverData, address.phone, 'phone', 10);
    updateField(setReceiverData, address.reference, 'placeRef', 20);
  };
  // - Autofill Parcel Form
  const fillParcel = (parcel) => {
    setIsHiddenParcels(true);
    // - Fill inputs
    updateField(setParcelData, parcel.name, 'name', 35);
    updateField(setParcelData, parcel.product_description, 'product_description', 35);
    updateField(setParcelData, parcel.height, 'height', 3);
    updateField(setParcelData, parcel.width, 'width', 3);
    updateField(setParcelData, parcel.depth, 'depth', 3);
    updateField(setParcelData, parcel.weight, 'weight', 2);
  };
  // update adrresses
  const updateAddressesFrom = async () => {
    try {
      // console.log('se modifico direcciones de origen');
      // 1. Borrar el localStorage para forzar una nueva petición
      localStorage.removeItem('addressesFrom');

      // 2. Hacer la petición a la API
      const response = await getAllAddressesToForm('SEND');

      if (response?.address) {
        // 3. Guardar la nueva data en localStorage
        localStorage.setItem('addressesFrom', JSON.stringify(response.address));

        // 4. Actualizar el estado global
        dispatch(setAddressesFrom({ data: response.address }));

        // 5. Actualizar el estado local
        setDirectoryFrom(response.address);
      }
    } catch (error) {
      console.error('Error al obtener direcciones:', error);
    }
  };
  // update adrresses
  const updateAddressesTo = async () => {
    try {
      // 1. Borrar el localStorage para forzar una nueva petición
      localStorage.removeItem('addressesTo');

      // 2. Hacer la petición a la API
      const response = await getAllAddressesToForm('RECEIVE');

      if (response?.address) {
        // 3. Guardar la nueva data en localStorage
        localStorage.setItem('addressesTo', JSON.stringify(response.address));

        // 4. Actualizar el estado global
        dispatch(setAddressesFrom({ data: response.address }));

        // 5. Actualizar el estado local
        setDirectoryFrom(response.address);
      }
    } catch (error) {
      console.error('Error al obtener direcciones:', error);
    }
  };
  const updateParcels = async () => {
    try {
      localStorage.removeItem('parcels');
      const response = await getAllParcels();

      if (response?.parcel) {
        // 3. Guardar la nueva data en localStorage
        localStorage.setItem('parcels', JSON.stringify(response.parcel));

        // 4. Actualizar el estado global
        dispatch(setParcels({ data: response.parcel }));

        // 5. Actualizar el estado local
        setDirectoryParcels(response.parcel);
      }
    } catch (error) {
      console.error('Error al obtener paquetes:', error);
    }
  };
  // - Get Rates
  const handlerQuoter = async () => {
    try {
      const data = {
        zipFrom: senderData.fields.zip,
        zipTo: receiverData.fields.zip,
        height: parcelData.fields.height,
        width: parcelData.fields.width,
        depth: parcelData.fields.depth,
        weight: parcelData.fields.weight,
        productValue: insuranceData.fields.isInsurance ? parcelData.fields.product_value : 0,
        multi_pieces: parcelData.fields.multi_pieces,
      };
      // console.log('quoter with:', data);
      // console.log('data request rates:', parcel, senderData.fields.zip, receiverData.fields.zip);
      setIsLoading(true);
      await new Promise((resolve) => setTimeout(resolve, 7000)); // Espera 2 segundos
      const response = await getRates(data);
      if (response.zip_from !== undefined) {
        dispatch(setRatesData(response));
        // console.log('respuesta exitosa rates:', response);
        history.push('/envios/enviar/servicios');
      } else if (response.error) {
        // const firstKey = Object.keys(response.error)[0];
        // ToastErrorApi(response.error[firstKey]);
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
      // ToastErrorCath();
    } finally {
      setIsLoading(false);
    }
  };
  const validateForm = async (e) => {
    // console.log('datos para el seguro:', insuranceData.fields);
    // console.log('datos del formulario:', parcelData.fields);
    e.preventDefault();
    let addressFromOk = false;
    let addressToOk = false;
    let parcelOk = false;
    // - Address from validations
    try {
      await validationAddressesSchema.validate(senderData.fields, { abortEarly: false });
      // console.log('2 address from ok');
      addressFromOk = true;
    } catch (errors) {
      const formErrors = {};
      errors.inner.forEach((error) => {
        dispatch(setSenderDataError({ data: error.message, point: error.path }));
        formErrors[error.path] = error.message;
      });
      // console.log('errores en addres from:', formErrors);
      formAddressFromRef.current.scrollIntoView({ behavior: 'smooth' }); // scroll to error
    }
    // - Address to validations
    try {
      await validationAddressesSchema.validate(receiverData.fields, { abortEarly: false });
      // console.log('3 address to ok');
      addressToOk = true;
    } catch (errors) {
      const formErrors = {};
      errors.inner.forEach((error) => {
        dispatch(setReceiverDataError({ data: error.message, point: error.path }));
        formErrors[error.path] = error.message;
      });
      // console.log('errores en addres to:', formErrors);
      formAddressToRef.current.scrollIntoView({ behavior: 'smooth' }); // scroll to error
    }
    // - Parcel validations
    try {
      await validationParcelSchema.validate(parcelData.fields, {
        context: { isInsurance: insuranceData.fields.isInsurance },
        abortEarly: false, // Esto asegura que se capturen todos los errores en lugar de detenerse en el primero.
      });
      parcelOk = true;
      // - if saveParcel is true, name should not be empty
      if (saveParcel && !parcelData.fields.name) {
        parcelOk = false;
        dispatch(setParcelDataError({ data: 'Se requiere llenar este campo para guardar el paquete', point: 'name' }));
      }
    } catch (errors) {
      const formErrors = {};
      errors.inner.forEach((error) => {
        dispatch(setParcelDataError({ data: error.message, point: error.path }));
        formErrors[error.path] = error.message;
      });
      // console.log('errores en parcel:', formErrors);
      formParcelRef.current.scrollIntoView({ behavior: 'smooth' }); // scroll to error
    }
    // - Insurance Parcel validations
    try {
      await validationInsuranceSchema.validate(insuranceData.fields, {
        context: { isInsurance: insuranceData.fields.isInsurance, generalData },
        abortEarly: false,
      });

      //  parcelOk = true;
    } catch (errors) {
      parcelOk = false;
      const formErrors = {};
      errors.inner.forEach((error) => {
        dispatch(setInsuranceDataError({ data: error.message, point: error.path }));
        formErrors[error.path] = error.message;
      });
      // console.log('errores en insurance parcel:', formErrors);
      formParcelRef.current.scrollIntoView({ behavior: 'smooth' }); // scroll to error
    }
    // console.log('from,', addressFromOk, 'to,', addressToOk, 'parcel,', parcelOk);
    // -If there is sender address to save, saveSenderAddress is the checkbox element
    if (addressFromOk && saveSenderAddress) {
      // handlerCreateAddress(senderData.fields);
      try {
        const result = await createAddress(senderData.fields);
        // console.log('nueva direccion origen: ', result);
        if (result.name !== undefined) {
          setTimeout(() => {
            ToastSuccess('¡Dirección de origen guardada con éxito! ');
            updateAddressesFrom();
          }, 3000);
        } else if (result.error.address) {
          addressFromOk = false;
          ToastErrorCath(`Error: No es posible guardar direccion de origen. ${result.error.address[0]} `);
        }
      } catch (error) {
        // handleCatchError(error);
        // ToastErrorCath();
      }
    }
    // -If there is receiver address to save, saveReceiverAddress is the checkbox element
    if (addressToOk && saveReceiverAddress) {
      // handlerCreateAddress(receiverData.fields);
      try {
        const result = await createAddress(receiverData.fields);
        // console.log('nueva direccion destino: ', result);
        if (result.name !== undefined) {
          setTimeout(() => {
            ToastSuccess('¡Dirección de destino guardada con éxito! ');
            updateAddressesTo();
          }, 3000);
        } else if (result.error.address) {
          addressToOk = false;
          ToastErrorCath(`Error: No es posible guardar direccion de destino. ${result.error.address[0]} `);
        }
      } catch (error) {
        // handleCatchError(error);
        // ToastErrorCath();
      }
    }
    // -If there is parcel to save, saveParcel is the checkbox element
    if (parcelOk && saveParcel) {
      try {
        const parcel = {
          name: parcelData.fields.name,
          description: parcelData.fields.product_description,
          height: parcelData.fields.height,
          width: parcelData.fields.width,
          depth: parcelData.fields.depth,
          weight: parcelData.fields.weight,
          value: 0,
          insurance: false,
          // value: parcelData.fields.product_value,
          // insurance: parcelData.fields.insurance,
        };
        const result = await createParcel(parcel);
        // console.log('nuevo paquete: ', result);
        if (result.name !== undefined) {
          setTimeout(() => {
            ToastSuccess('¡Paquete guardado con éxito!');
            updateParcels();
          }, 3000);
        } else if (result.error.parcel) {
          parcelOk = false;
          ToastErrorCath(`Error: No es posible guardar este paquete. ${result.error.parcel}`);
        }
      } catch (error) {
        // handleCatchError(error);
        // ToastErrorCath();
      }
    }
    if (addressFromOk && addressToOk && parcelOk) {
      // - if forms are ok get rates
      handlerQuoter();
    }
  };

  return (
    <>
      {isLoading && <LoaderAnimation message="Buscando servicios disponibles, por favor espera…" animationPath={animationBoxes} />}
      {/* className={`${isLoading ? 'd-none' : 'd-block'}`} */}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        {/* Title Start */}
        <div className="page-title-container">
          <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/">
            <CsLineIcons icon="chevron-left" size="13" />
            <span className="align-middle text-small ms-1">Inicio</span>
          </NavLink>
          <h1 className="mb-0 pb-0 display-4" id="title">
            {title}
          </h1>
        </div>
        {/* Title End */}
        <form onSubmit={validateForm}>
          {/* Start Addresses Forn */}
          <Row>
            {/* Address From */}
            <Col xs="12" className="col-lg order-1 order-lg-0">
              <h2 className="small-title" ref={formAddressFromRef}>
                Dirección origen
              </h2>
              <Card className="mb-5">
                <Card.Body style={{ fontFamily: 'Montserrat' }}>
                  {/* onClick={() => setIsHiddenAddressesFrom(true)} */}
                  <Form>
                    <Row className="g-3">
                      {/* START SENDER NAME */}
                      <Col md="6" lg="12" className="position-relative">
                        <Form.Label>Nombre</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="user" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={29}
                            placeholder="máximo 29 caracteres"
                            value={senderData.fields.name}
                            onChange={(e) => {
                              updateField(setSenderData, e.target.value, 'name', 29);
                              dispatch(setSenderDataError({ point: 'name', data: false }));
                              filterDirectoryFrom(e.target.value);
                            }}
                          />
                          <InputGroup.Text
                            className="cursor-pointer"
                            onClick={() => {
                              setIsHiddenAddressesFrom(!isHiddenAddressesFrom);
                              if (isHiddenAddressesFrom) {
                                // si esta oculto abrir
                                setIsHiddenAddressesFrom(false);
                                filterDirectoryFrom('');
                              } else {
                                // si no esta oculro, cerrar
                                setIsHiddenAddressesFrom(true);
                              }
                            }}
                          >
                            <CsLineIcons icon="chevron-bottom" size="16" className="text-primary" />
                            {/* <CsLineIcons icon="chevron-top" size="18"/> */}
                          </InputGroup.Text>
                        </InputGroup>
                        {/* - Address List Start */}
                        <AnimatePresence>
                          {!isHiddenAddressesFrom && (
                            <motion.div
                              initial={{ opacity: 0, y: -20 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -20 }}
                              transition={{ duration: 0.3 }}
                              className="scroll-container"
                              style={{
                                position: 'absolute',
                                zIndex: 1000,
                                width: '96%',
                                maxHeight: '480px',
                                overflowY: 'scroll',
                                cursor: 'pointer',
                                fontFamily: 'Montserrat',
                                borderRadius: '10px',
                                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                              }}
                            >
                              <ListGroup>
                                {directoryFrom?.length > 0 ? (
                                  directoryFrom?.map((address, index) => (
                                    <ListGroupItem key={index} className="p-2 ps-3 rounded-0 list-group-item-hover" onClick={() => fillSenderAddress(address)}>
                                      <div className="d-flex align-items-center">
                                        <CsLineIcons icon="home" size="16" className="text-primary" />
                                        <div className="ms-3 pe-3">
                                          {address.name}
                                          <p className="text-small m-0">
                                            {`Calle: ${address.street} Colonia: ${address.neighborhood} cp: ${address.zip_code}`}{' '}
                                          </p>
                                        </div>
                                      </div>
                                    </ListGroupItem>
                                  ))
                                ) : (
                                  <ListGroupItem className="text-muted text-center p-2  position-relative z-0">No hay coincidencias</ListGroupItem>
                                )}
                              </ListGroup>
                            </motion.div>
                          )}
                        </AnimatePresence>
                        {/* - Address List End */}
                        {senderData.errors.name && (
                          <div className={`invalid-tooltip ${senderData.errors.name ? 'd-block' : 'd-none'}`}>{senderData.errors.name}</div>
                        )}
                      </Col>
                      {/* END SENDER NAME */}
                      {/* START SENDER COMPANY */}
                      <Col md="6" lg="12" className="position-relative">
                        <Form.Label>Empresa</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="building" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={29}
                            placeholder="máximo 29 caracteres"
                            value={senderData.fields.company}
                            onChange={(e) => {
                              updateField(setSenderData, e.target.value, 'company', 29);
                            }}
                          />
                        </InputGroup>
                      </Col>
                      {/* END SENDER COMPANY */}
                      {/* START SENDER STREET */}
                      <Col sm="9" lg="8" className="position-relative">
                        <Form.Label>Calle</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            {/* <i className="bi-signpost"/> */}
                            <CsLineIcons icon="home" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={50}
                            placeholder="máximo 50 caracteres"
                            value={senderData.fields.street}
                            onChange={(e) => {
                              updateField(setSenderData, e.target.value, 'street', 50);
                              dispatch(setSenderDataError({ point: 'street', data: false }));
                            }}
                          />
                        </InputGroup>
                        {senderData.errors.street && (
                          <div className={`invalid-tooltip ${senderData.errors.street ? 'd-block' : 'd-none'}`}>{senderData.errors.street}</div>
                        )}
                      </Col>
                      {/* END SENDER STREET */}
                      {/* START SENDER STREET NUMBER */}
                      <Col sm="3" lg="4" className="position-relative">
                        <Form.Label>Número</Form.Label>
                        <span className="text-primary">*</span>
                        <Form.Control
                          type="text"
                          maxLength={10}
                          placeholder="&#x23;"
                          value={senderData.fields.streetNumber}
                          onChange={(e) => {
                            updateField(setSenderData, e.target.value, 'streetNumber', 10);
                            dispatch(setSenderDataError({ point: 'street', data: false }));
                          }}
                        />
                        {senderData.errors.streetNumber && (
                          <div
                            className={`invalid-tooltip ${senderData.errors.streetNumber ? 'd-block' : 'd-none'}`}
                            style={{ width: '80%', maxWidth: '190px' }}
                          >
                            {senderData.errors.streetNumber}
                          </div>
                        )}
                      </Col>
                      {/* END SENDER STREET NUMBER */}
                      {/* START SENDER NEIGHBORHOOD */}
                      <Col sm="9" lg="8" className="position-relative">
                        <Form.Label>Colonia</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="home" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={35}
                            placeholder="máximo 35 caracteres"
                            value={senderData.fields.neighborhood}
                            onChange={(e) => {
                              updateField(setSenderData, e.target.value, 'neighborhood', 35);
                              dispatch(setSenderDataError({ point: 'neighborhood', data: false }));
                            }}
                          />
                        </InputGroup>
                        {senderData.errors.neighborhood && (
                          <div className={`invalid-tooltip ${senderData.errors.neighborhood ? 'd-block' : 'd-none'}`}>{senderData.errors.neighborhood}</div>
                        )}
                      </Col>
                      {/* END SENDER NEIGHBORHOOD */}
                      {/* START SENDER ZIP CODE */}
                      <Col sm="3" lg="4" className="position-relative">
                        <Form.Label>Código postal</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="pin" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            maxLength={5}
                            value={senderData.fields.zip}
                            onChange={(e) => {
                              updateField(setSenderData, e.target.value, 'zip', 5);
                              dispatch(setSenderDataError({ data: '', point: 'zip' }));
                            }}
                          />
                        </InputGroup>
                        {senderData.errors.zip && (
                          <div className={`invalid-tooltip ${senderData.errors.zip ? 'd-block' : 'd-none'}`} style={{ width: '80%', maxWidth: '190px' }}>
                            {senderData.errors.zip}
                          </div>
                        )}
                      </Col>
                      {/* END SENDER ZIP CODE */}
                      <Col sm="7">
                        <Form.Label>Ciudad</Form.Label>
                        <Form.Control type="text" readOnly value={senderData.fields.city} />
                      </Col>
                      <Col sm="5">
                        <Form.Label>Estado</Form.Label>
                        <Form.Control type="text" readOnly value={senderData.fields.state.name} />
                      </Col>
                      {/* START SENDER REFER */}
                      <Col sm="7">
                        <Form.Label>Referencias</Form.Label>
                        {/* <div style={{ position: 'relative' }}>                       </div> */}
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="destination" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            maxLength={20}
                            type="text"
                            placeholder="máximo 20 caracteres"
                            value={senderData.fields.placeRef}
                            onChange={(e) => {
                              updateField(setSenderData, e.target.value, 'placeRef', 20);
                            }}
                          />
                        </InputGroup>
                      </Col>
                      {/* END SENDER REFER */}
                      {/* START SENDER PHONE */}
                      <Col sm="5" className="position-relative">
                        <Form.Label>Teléfono</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="phone" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={10}
                            placeholder="10 dígitos"
                            value={senderData.fields.phone}
                            onChange={(e) => {
                              updateField(setSenderData, e.target.value, 'phone', 10);
                              dispatch(setSenderDataError({ point: 'phone', data: false }));
                            }}
                          />
                        </InputGroup>
                        {senderData.errors.phone && (
                          <div className={`invalid-tooltip ${senderData.errors.phone ? 'd-block' : 'd-none'}`} style={{ width: '80%', maxWidth: '190px' }}>
                            {senderData.errors.phone}
                          </div>
                        )}
                      </Col>
                      {/* END SENDER PHONE */}
                    </Row>
                    <Row>
                      <Col xs="12">
                        <div className="form-check mt-4 mb-4">
                          <input
                            type="checkbox"
                            data-gtm="form-save-address-from" // google analytics
                            className="form-check-input cursor-pointer"
                            name="sender"
                            checked={saveSenderAddress}
                            onClick={() => {
                              setSaveSenderAddress(!saveSenderAddress);
                            }}
                          />
                          <label className="form-label">Guardar esta dirección en mi lista de direcciones</label>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
              {/* Address  From End */}
            </Col>
            {/* Address To */}
            <Col xs="12" className="col-lg order-1 order-lg-0">
              <h2 className="small-title" ref={formAddressToRef}>
                Dirección destino
              </h2>
              <Card className="mb-5">
                <Card.Body style={{ fontFamily: 'Montserrat' }}>
                  {/* onClick={() => setIsHiddenAddressesTo(true)} */}
                  <Form>
                    <Row className="g-3">
                      {/* START RECEIVER NAME */}
                      <Col md="6" lg="12" className="position-relative">
                        <Form.Label>Nombre</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="user" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={29}
                            placeholder="máximo 29 caracteres"
                            value={receiverData.fields.name}
                            onChange={(e) => {
                              updateField(setReceiverData, e.target.value, 'name', 29);
                              dispatch(setReceiverDataError({ point: 'name', data: false }));
                              handlerDirectoryTo(e.target.value);
                            }}
                          />
                          <InputGroup.Text
                            className="cursor-pointer"
                            onClick={() => {
                              if (isHiddenAddressesTo) {
                                // open directory
                                setIsHiddenAddressesTo(false);
                                handlerDirectoryTo('');
                              } else {
                                // close directory
                                setIsHiddenAddressesTo(true);
                              }
                            }}
                          >
                            <CsLineIcons icon="chevron-bottom" size="16" className="text-primary" />
                            {/* <CsLineIcons icon="chevron-top" size="18"/> */}
                          </InputGroup.Text>
                        </InputGroup>
                        {/* - Address List Start */}
                        <AnimatePresence>
                          {!isHiddenAddressesTo && (
                            <motion.div
                              initial={{ opacity: 0, y: -20 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -20 }}
                              transition={{ duration: 0.3 }}
                              className="scroll-container"
                              style={{
                                position: 'absolute',
                                zIndex: 1000,
                                width: '96%',
                                maxHeight: '480px',
                                overflowY: 'scroll',
                                cursor: 'pointer',
                                fontFamily: 'Montserrat',
                                borderRadius: '10px',
                                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                              }}
                            >
                              <ListGroup>
                                {directoryTo?.length > 0 ? (
                                  directoryTo?.map((address, index) => (
                                    <ListGroupItem
                                      key={index}
                                      className="p-2 ps-3 rounded-0 list-group-item-hover"
                                      onClick={() => fillReceiverAddress(address)}
                                    >
                                      <div className="d-flex align-items-center">
                                        <CsLineIcons icon="pin" size="16" className="text-primary" />
                                        <div className="ms-2">
                                          {address.name}
                                          <p className="text-small m-0">
                                            {`Calle: ${address.street} Colonia: ${address.neighborhood} cp: ${address.zip_code}`}{' '}
                                          </p>
                                        </div>
                                      </div>
                                    </ListGroupItem>
                                  ))
                                ) : (
                                  <ListGroupItem className="text-muted text-center p-2  position-relative z-0">No hay coincidencias</ListGroupItem>
                                )}
                              </ListGroup>
                            </motion.div>
                          )}
                        </AnimatePresence>
                        {/* - Address List End */}
                        {receiverData.errors.name && (
                          <div className={`invalid-tooltip ${receiverData.errors.name ? 'd-block' : 'd-none'}`}>{receiverData.errors.name}</div>
                        )}
                      </Col>
                      {/* END RECEIVER NAME */}
                      {/* START RECEIVER COMPANY */}
                      <Col md="6" lg="12" className="position-relative">
                        <Form.Label>Empresa</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="building" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={29}
                            placeholder="máximo 29 caracteres"
                            value={receiverData.fields.company}
                            onChange={(e) => {
                              updateField(setReceiverData, e.target.value, 'company', 29);
                            }}
                          />
                        </InputGroup>
                      </Col>
                      {/* END RECEIVER COMPANY */}
                      {/* START RECEIVER  STREET */}
                      <Col sm="9" lg="8" className="position-relative">
                        <Form.Label>Calle</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="home" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={50}
                            placeholder="máximo 50 caracteres"
                            value={receiverData.fields.street}
                            onChange={(e) => {
                              updateField(setReceiverData, e.target.value, 'street', 50);
                              dispatch(setReceiverDataError({ point: 'street', data: false }));
                            }}
                          />
                        </InputGroup>
                        {receiverData.errors.street && (
                          <div className={`invalid-tooltip ${receiverData.errors.street ? 'd-block' : 'd-none'}`}>{receiverData.errors.street}</div>
                        )}
                      </Col>
                      {/* START RECEIVER  STREET */}
                      {/* START RECEIVER  STREET NUMBER */}
                      <Col sm="3" lg="4" className="position-relative">
                        <Form.Label>Número</Form.Label>
                        <span className="text-primary">*</span>
                        <Form.Control
                          type="text"
                          maxLength={10}
                          placeholder="&#x23;"
                          value={receiverData.fields.streetNumber}
                          onChange={(e) => {
                            updateField(setReceiverData, e.target.value, 'streetNumber', 10);
                            dispatch(setReceiverDataError({ point: 'streetNumber', data: false }));
                          }}
                        />

                        {receiverData.errors.streetNumber && (
                          <div
                            className={`invalid-tooltip ${receiverData.errors.streetNumber ? 'd-block' : 'd-none'}`}
                            style={{ width: '80%', maxWidth: '190px' }}
                          >
                            {receiverData.errors.streetNumber}
                          </div>
                        )}
                      </Col>
                      {/* END RECEIVER  STREET NUMBER */}
                      {/* START RECEIVER NEIGHBORBOOD */}
                      <Col sm="9" lg="8" className="position-relative">
                        <Form.Label>Colonia</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="home" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={35}
                            placeholder="máximo 35 caracteres"
                            value={receiverData.fields.neighborhood}
                            onChange={(e) => {
                              updateField(setReceiverData, e.target.value, 'neighborhood', 35);
                              dispatch(setReceiverDataError({ point: 'neighborhood', data: false }));
                            }}
                          />
                        </InputGroup>

                        {receiverData.errors.neighborhood && (
                          <div className={`invalid-tooltip ${receiverData.errors.neighborhood ? 'd-block' : 'd-none'}`}>{receiverData.errors.neighborhood}</div>
                        )}
                      </Col>
                      {/* END RECEIVER NEIGHBORBOOD */}
                      {/* START RECEIVER ZIP CODE */}
                      <Col sm="3" lg="4" className="position-relative">
                        <Form.Label>Código postal</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="pin" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            maxLength={5}
                            value={receiverData.fields.zip}
                            onChange={(e) => {
                              updateField(setReceiverData, e.target.value, 'zip', 5);
                              dispatch(setReceiverDataError({ data: '', point: 'zip' }));
                            }}
                          />
                        </InputGroup>

                        {receiverData.errors.zip && (
                          <div className={`invalid-tooltip ${receiverData.errors.zip ? 'd-block' : 'd-none'}`} style={{ width: '80%', maxWidth: '190px' }}>
                            {receiverData.errors.zip}
                          </div>
                        )}
                      </Col>
                      {/* END RECEIVER ZIP CODE */}

                      <Col sm="7">
                        <Form.Label>Ciudad</Form.Label>
                        <Form.Control type="text" readOnly value={receiverData.fields.city} />
                      </Col>
                      <Col sm="5">
                        <Form.Label>Estado</Form.Label>
                        <Form.Control type="text" readOnly value={receiverData.fields.state.name} />
                      </Col>
                      {/* START RECEIVER REFER */}
                      <Col sm="7">
                        <Form.Label>Referencias</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="destination" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={20}
                            placeholder="máximo 20 caracteres"
                            value={receiverData.fields.placeRef}
                            onChange={(e) => {
                              updateField(setReceiverData, e.target.value, 'placeRef', 20);
                            }}
                          />
                        </InputGroup>
                      </Col>
                      {/* END RECEIVER REFER */}
                      {/* START RECEIVER PHONE */}
                      <Col sm="5" className="position-relative">
                        <Form.Label>Teléfono</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="phone" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            // id="phone"
                            type="text"
                            maxLength={10}
                            placeholder="10 dígitos"
                            value={receiverData.fields.phone}
                            onChange={(e) => {
                              updateField(setReceiverData, e.target.value, 'phone', 10);
                              dispatch(setReceiverDataError({ point: 'phone', data: false }));
                            }}
                          />
                        </InputGroup>
                        {receiverData.errors.phone && (
                          <div className={`invalid-tooltip ${receiverData.errors.phone ? 'd-block' : 'd-none'}`} style={{ width: '80%', maxWidth: '190px' }}>
                            {receiverData.errors.phone}
                          </div>
                        )}
                      </Col>
                      {/* END RECEIVER PHONE */}
                    </Row>
                    <Row>
                      <Col xs="12">
                        <div className="form-check mt-4 mb-4">
                          <input
                            type="checkbox"
                            data-gtm="form-save-address-to" // google analytics
                            className="form-check-input cursor-pointer"
                            name="receiver"
                            checked={saveReceiverAddress}
                            onClick={() => {
                              setSaveReceiverAddress(!saveReceiverAddress);
                            }}
                          />
                          <label className="form-label">Guardar esta dirección en mi lista de direcciones</label>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
              {/* Address End */}
            </Col>
          </Row>
          {/* End Addresses Form */}
          {/* Start Package  */}
          <Row>
            <Col xs="12" lg="6">
              <h2 className="small-title" ref={formParcelRef}>
                Paquete
              </h2>
              {/* sh-lg-50 sh-lg-60  */}
              <Card className="mb-3 card-box-min-height">
                <Card.Body style={{ fontFamily: 'Montserrat' }}>
                  <Form>
                    <Row className="g-3">
                      <Col md="6" lg="12" className="position-relative">
                        <Form.Label>Nombre</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="box" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={35}
                            placeholder="máximo 35 caracteres"
                            value={parcelData.fields.name}
                            onChange={(e) => {
                              updateField(setParcelData, e.target.value, 'name', 35);
                              dispatch(setParcelDataError({ point: 'name', data: false }));
                              filterDirectoryParcels(e.target.value);
                            }}
                          />
                          <InputGroup.Text
                            className="cursor-pointer"
                            onClick={() => {
                              if (isHiddenParcels) {
                                // open it
                                setIsHiddenParcels(false);
                                filterDirectoryParcels('');
                              } else {
                                // close it
                                setIsHiddenParcels(true);
                              }
                            }}
                          >
                            <CsLineIcons icon="chevron-bottom" size="16" className="text-primary" />
                          </InputGroup.Text>
                        </InputGroup>
                        {/* - Parcels Liste Start */}
                        <AnimatePresence>
                          {!isHiddenParcels && (
                            <motion.div
                              initial={{ opacity: 0, y: -20 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -20 }}
                              transition={{ duration: 0.3 }}
                              className="scroll-container"
                              style={{
                                position: 'absolute',
                                zIndex: 1000,
                                maxHeight: '300px',
                                width: '96%',
                                overflowY: 'scroll',
                                cursor: 'pointer',
                                fontFamily: 'Montserrat',
                                borderRadius: '10px',
                                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                              }}
                            >
                              <ListGroup>
                                {directoryParcels?.length > 0 ? (
                                  directoryParcels?.map((parcel, index) => (
                                    <ListGroupItem key={index} className="p-2 ps-3 rounded-0 list-group-item-hover" onClick={() => fillParcel(parcel)}>
                                      <div className="d-flex align-items-center">
                                        <CsLineIcons icon="box" size="16" className="text-primary" />
                                        <div className="ms-3">
                                          {parcel.name}
                                          <p className="text-small m-0">
                                            {`Dimensiones: ${parcel.depth} x ${parcel.width} x ${parcel.height}  Kg: ${parcel.weight} `}{' '}
                                          </p>
                                        </div>
                                      </div>
                                    </ListGroupItem>
                                  ))
                                ) : (
                                  <ListGroupItem className="text-muted text-center p-2  position-relative z-0">No hay coincidencias</ListGroupItem>
                                )}
                              </ListGroup>
                            </motion.div>
                          )}
                        </AnimatePresence>

                        {/* - Parcels List End */}
                        {parcelData.errors.name && (
                          <div className={`invalid-tooltip ${parcelData.errors.name ? 'd-block' : 'd-none'}`}>{parcelData.errors.name}</div>
                        )}
                      </Col>
                      <Col md="6" lg="12" className="position-relative">
                        <Form.Label>Descripción del contenido</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <InputGroup.Text>
                            <CsLineIcons icon="edit-square" size="16" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            type="text"
                            maxLength={35}
                            placeholder="máximo 35 caracteres"
                            value={parcelData.fields.product_description}
                            onChange={(e) => {
                              updateField(setParcelData, e.target.value, 'product_description', 35);
                              dispatch(setParcelDataError({ point: 'product_description', data: false }));
                            }}
                          />
                        </InputGroup>
                        {parcelData.errors.product_description && (
                          <div className={`invalid-tooltip ${parcelData.errors.product_description ? 'd-block' : 'd-none'}`}>
                            {parcelData.errors.product_description}
                          </div>
                        )}
                      </Col>
                      <Col xs="12" sm="8" md="6" lg="12" xxl="8">
                        <Form.Label>Dimensiones</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup className="mb-1">
                          <InputGroup.Text>
                            <CsLineIcons icon="box" size="17" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Largo"
                            maxLength={3}
                            value={parcelData.fields.depth}
                            onChange={(e) => {
                              updateField(setParcelData, e.target.value, 'depth', 3);
                              dispatch(setParcelDataError({ point: 'depth', data: false }));
                            }}
                          />
                          <InputGroup.Text>
                            <CsLineIcons icon="multiply" size="17" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Ancho"
                            maxLength={3}
                            value={parcelData.fields.width}
                            onChange={(e) => {
                              updateField(setParcelData, e.target.value, 'width', 3);
                              dispatch(setParcelDataError({ point: 'width', data: false }));
                            }}
                          />
                          <InputGroup.Text>
                            <CsLineIcons icon="multiply" size="17" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="Alto"
                            maxLength={3}
                            value={parcelData.fields.height}
                            onChange={(e) => {
                              updateField(setParcelData, e.target.value, 'height', 3);
                              dispatch(setParcelDataError({ point: 'height', data: false }));
                            }}
                          />
                          {parcelData.errors.height && (
                            <div className={`invalid-tooltip ${parcelData.errors.height ? 'd-block' : 'd-none'}`}>{parcelData.errors.height}</div>
                          )}
                        </InputGroup>
                      </Col>
                      <Col xs="auto">
                        <Form.Label>Peso</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup className="mb-1" style={{ maxWidth: '120px' }}>
                          <InputGroup.Text id="input-peso">
                            <CsLineIcons icon="download" size="17" className="text-alternate" />
                          </InputGroup.Text>
                          <Form.Control
                            placeholder="kg"
                            maxLength={3}
                            value={parcelData.fields.weight}
                            onChange={(e) => {
                              updateField(setParcelData, e.target.value, 'weight', 2);
                              dispatch(setParcelDataError({ point: 'weight', data: false }));
                            }}
                          />
                          {parcelData.errors.weight && (
                            <div className={`invalid-tooltip ${parcelData.errors.weight ? 'd-block' : 'd-none'}`}>{parcelData.errors.weight}</div>
                          )}
                        </InputGroup>
                      </Col>
                      <Col xs="6" md="3" lg="6" xl="6" className="position-relative mb-4 bg-infox">
                        <Form.Label>Valor del contenido</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <NumberFormat
                            value={parcelData.fields.product_value}
                            onChange={(e) => {
                              updateField(setParcelData, e.target.value, 'product_value', 5);
                              dispatch(setParcelDataError({ point: 'product_value', data: false }));
                            }}
                            placeholder="$1,500 MXN"
                            className="form-control"
                            thousandSeparator=","
                            decimalSeparator="."
                            prefix="$"
                          />
                        </InputGroup>
                        {parcelData.errors.product_value && (
                          <div className={`invalid-tooltip ${parcelData.errors.product_value ? 'd-block' : 'd-none'}`}>{parcelData.errors.product_value}</div>
                        )}
                      </Col>
                      <Col xs="auto" className={`bg-secondaryx flex-column justify-content-xxl-centerx ${multiPiecesOk ? 'd-flex' : 'd-none'}`}>
                        <Form.Label>Multi-piezas</Form.Label>
                        <InputGroup>
                          <Dropdown className="d-inline-block">
                            <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-11">
                              {`${pieces} pieza`}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="shadow" style={{ maxWidth: '88px' }}>
                              <Dropdown.Item onClick={() => setPieces(1)}>1 pieza</Dropdown.Item>
                              <Dropdown.Item onClick={() => setPieces(2)}>2 piezas</Dropdown.Item>
                              <Dropdown.Item onClick={() => setPieces(3)}>3 piezas</Dropdown.Item>
                              <Dropdown.Item onClick={() => setPieces(4)}>4 piezas</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </InputGroup>
                      </Col>
                      <Col xs="auto" xxl="10" className="d-flex flex-column bg-infox mt-0">
                        <label className="form-label text-semi-large bg-successx ">
                          <span>Protege tu envío ante accidentes, robos o extravío.</span>
                          <OverlayTrigger
                            delay={{ show: 500, hide: 0 }}
                            placement="top"
                            overlay={
                              // .tooltip this is its class
                              <Tooltip id="tooltip-top">Asegurar tu paquete es opcional. Es necesario contar con factura del producto.</Tooltip>
                            }
                          >
                            <span className="cursor-pointer">
                              <CsLineIcons icon="info-hexagon" size="20" className="text-light-darker mb-1" />
                            </span>
                          </OverlayTrigger>
                        </label>
                        <div className="bg-warningx d-flex">
                          <Form.Check // prettier-ignore
                            type="switch"
                            id="switch_insurance"
                            className="bg-successx d-flex aling-items-end"
                            checked={insuranceData.fields.isInsurance}
                            onChange={(e) => {
                              dispatch(setInsuranceData({ data: e.target.checked, point: 'isInsurance' }));
                            }}
                          />
                          <span className="ms-1 d-flex aling-items-end bg-infox">¿Deseas asegurar?</span>
                        </div>
                      </Col>
                      <AnimatePresence>{insuranceData.fields.isInsurance && <InsuranceToggle />}</AnimatePresence>
                    </Row>
                    <Row>
                      <Col>
                        <div className="form-check mt-5">
                          <input
                            type="checkbox"
                            data-gtm="form-save-paq" // google analytics
                            className="form-check-input cursor-pointer"
                            name="sender"
                            checked={saveParcel}
                            onClick={() => {
                              setSaveParcel(!saveParcel);
                            }}
                          />
                          <label className="form-label">Guardar información del paquete</label>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
              </Card>
              {/* Package End */}
            </Col>
            {/* btn submit  sh-lg-auto sh-lg-60 */}
            <Col xs="12" lg="6">
              <h2 className="small-title invisible">Servicios</h2>
              <Card className="hover-img-scale-up card-btn-height mb-4">
                <img src="/img/autopaquete/enviar/btn-services.png" className="card-img h-100 card-btn-height scale" alt="card image" />
                <div className="card-img-overlay d-flex flex-column bg-transparent ">
                  <div>
                    {/* cta-1 */}
                    <div className="mb-3 text-black w-75 w-md-100 display-5">Elige el servicio ideal</div>
                    <div className="w-50 text-black d-none d-sm-block mb-3">Nos adptamos a tus necesidades ofreciendo servicios nacionales y locales.</div>
                  </div>
                  <div className="mt-5">
                    <Button className="btn btn-icon btn-icon-start" variant="primary" type="submit">
                      <CsLineIcons icon="chevron-right" /> <span>Ver servicios disponibles</span>
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
          {/*  Package  End */}
        </form>
      </div>
    </>
  );
};

export default ShippingForm;

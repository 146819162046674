import React, { useState } from 'react';
import * as Yup from 'yup';

// components
import SupportForm from '../SupportForm';
// import ReportInfo from '../sections/ReportInfo';
import AddressInfo from '../sections/AddressInfo';
import PackageInfo from '../sections/PackageInfo';
// eliminar razon social y seguro
const ExpediteDelivery = () => {
  const [values, setValues] = useState({
    shippingId: '',
    incidentType: 'Agilizar Entrega',
    serviceName: '',
    street: '',
    streetNumber: '',
    neighborhood: '',
    cp: '',
    city: '',
    state: '',
    references: '',
    phoneTo: '',
    // user typing the next fields
    email: '',
    betweenStreet: '',
    facade: '',
    // info package
    packageContent: '',
    packagingDescription: '',
    invoiceFile: '',
    comment: '',
  });
  const [errors, setErrors] = useState({
    shippingId: '',
    incidentType: '',
    email: '',
    street: '',
    streetNumber: '',
    neighborhood: '',
    cp: '',
    betweenStreet: '',
    facade: '',
    references: '',
    phoneTo: '',
    packageContent: '',
    packagingDescription: '',
    invoiceFile: '',
  });
  // Schema Validation
  const validationSchema = Yup.object().shape({
    shippingId: Yup.string().required('Por favor, introduce el ID de envío').nullable(),
    email: Yup.string().email('Por favor, introduce un correo electrónico válido').required('El correo electrónico es obligatorio'),
    street: Yup.string().required('No olvides ingresar el nombre de la calle'),
    streetNumber: Yup.string().required('Ingresa el número de la calle'),
    city: Yup.string().required('Selecciona una ciudad'),
    state: Yup.string().required('Selecciona un estado'),
    cp: Yup.string().min(5, 'El código postal debe tener 5 números.').required('Introduce el código postal'),
    neighborhood: Yup.string().required('Introduce la colonia'),
    betweenStreet: Yup.string().required('Indica las calles entre las cuales se ubica'),
    references: Yup.string().required('Proporciona una referencia'),
    phoneTo: Yup.string().min(10, 'El número de teléfono debe tener 10 dígitos').required('El número de teléfono es obligatorio'),
    facade: Yup.string().required('Proporciona la descripción de la fachada'),
    packagingDescription: Yup.string().required('Describe el tipo de embalaje'),
    invoiceFile: Yup.string().required('Por favor sube la factura del contenido'),
    packageContent: Yup.string().required('Describe el contenido del paquete'),
  });
  const infoSection =
    'Es importante confirmar los datos del domicilio de destino para garantizar que el operador realice la entrega de manera precisa y sin inconvenientes.';

  // console.log('Agilizar Entrega data:', values);
  // console.log('Agilizar Entrega errores:', errors);

  return (
    <SupportForm values={values} setValues={setValues} setErrors={setErrors} errors={errors} validationSchema={validationSchema} formTitle="Agilizar Entrega">
      {/* <ReportInfo values={values} setValues={setValues} errors={errors} setErrors={setErrors} /> */}
      <AddressInfo values={values} setValues={setValues} errors={errors} setErrors={setErrors} info={infoSection} />
      <PackageInfo values={values} setValues={setValues} errors={errors} setErrors={setErrors} invoiceOk />
    </SupportForm>
  );
};
export default ExpediteDelivery;

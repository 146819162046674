import React, { useState } from 'react';
import * as Yup from 'yup';
// components
import SupportForm from '../SupportForm';
// import ReportInfo from '../sections/ReportInfo';
import AddressInfo from '../sections/AddressInfo';
import PackageInfoDamaged from '../sections/PackageInfoDamaged';
import EvidenceInfo from '../sections/EvidenceInfo';

const DamagedPack = () => {
  const [values, setValues] = useState({
    shippingId: null,
    incidentType: 'Paquete Dañado o Incompleto',
    serviceName: '',
    street: '',
    streetNumber: '',
    neighborhood: '',
    cp: '',
    city: '',
    state: '',
    references: '',
    phoneTo: '',
    // user typing the next fields
    email: '',
    betweenStreet: '',
    facade: '',
    // info package
    packageContent: '',
    packagingDescription: '',
    // nuevos campos
    packagingInternalDescription: '',
    missingParts: '',
    damagedDescription: '',
    isRepairable: '',
    repairCost: '',
    packageTape: '',
    // evidencias
    photograph1: '',
    photograph2: '',
    photograph3: '',
    photograph4: '',
    photograph5: '',
    photograph6: '',
    protectionPhoto: '',
    contentPhoto: '',
    // invoice
    invoiceFile: '',
    comment: '',
  });

  const [errors, setErrors] = useState({
    shippingId: '',
    incidentType: '',
    email: '',
    street: '',
    streetNumber: '',
    neighborhood: '',
    cp: '',
    betweenStreet: '',
    facade: '',
    references: '',
    phoneTo: '',
    packageContent: '',
    packagingDescription: '',
    invoiceFile: '',
    // nuevos campos
    packagingInternalDescription: '',
    missingParts: '',
    damagedDescription: '',
    isRepairable: '',
    repairCost: '',
    packageTape: '',
    // evidencias
    photograph1: '',
    photograph2: '',
    photograph3: '',
    photograph4: '',
    photograph5: '',
    photograph6: '',
    protectionPhoto: '',
    contentPhoto: '',
  });
  // Schema Validation
  const validationSchema = Yup.object().shape({
    shippingId: Yup.string().required('Por favor, introduce el ID de envío').nullable(),
    email: Yup.string().email('Por favor, introduce un correo electrónico válido').required('El correo electrónico es obligatorio'),
    street: Yup.string().required('No olvides ingresar el nombre de la calle'),
    streetNumber: Yup.string().required('Ingresa el número de la calle'),
    cp: Yup.string().min(5, 'El código postal debe tener 5 números.').required('Introduce el código postal'),
    neighborhood: Yup.string().required('Introduce la colonia'),
    betweenStreet: Yup.string().required('Indica las calles entre las cuales se ubica'),
    references: Yup.string().required('Proporciona una referencia'),
    phoneTo: Yup.string().min(10, 'El número de teléfono debe tener 10 dígitos').required('El número de teléfono es obligatorio'),
    facade: Yup.string().required('Proporciona la descripción de la fachada'),
    packagingDescription: Yup.string().required('Describe el tipo de embalaje'),
    invoiceFile: Yup.string().required('Por favor sube la factura del contenido'),
    packageContent: Yup.string().required('Describe el contenido del paquete'),
    // nuevos campos
    packagingInternalDescription: Yup.string().required('Campo requerido'),
    missingParts: Yup.string().required('Campo requerido'),
    damagedDescription: Yup.string().required('Campo requerido'),
    isRepairable: Yup.string().required('Campo requerido'),
    repairCost: Yup.string().required('Campo requerido'),
    packageTape: Yup.string().required('Campo requerido'),
    // evidencias
    // photograph1: Yup.string().required('Campo requerido'),
    // photograph2: Yup.string().required('Campo requerido'),
    // photograph3: Yup.string().required('Campo requerido'),
    // photograph4: Yup.string().required('Campo requerido'),
    // photograph5: Yup.string().required('Campo requerido'),
    // photograph6: Yup.string().required('Campo requerido'),
    // protectionPhoto: Yup.string().required('Campo requerido'),
    // contentPhoto: Yup.string().required('Campo requerido'),
  });
  // console.log('Contenido Dañado data:', values);
  // console.log('Contenido Dañado error:', errors);

  const infoSection =
    'Es necesario confirmar los datos del domicilio del destinatario para avanzar con la investigación. La paquetería realizará una visita como parte del proceso de aclaración.';
  return (
    <SupportForm
      values={values}
      setValues={setValues}
      setErrors={setErrors}
      errors={errors}
      validationSchema={validationSchema}
      formTitle="Paquete Dañado o Incompleto"
    >
      {/* <ReportInfo values={values} setValues={setValues} errors={errors} setErrors={setErrors} /> */}
      <AddressInfo values={values} setValues={setValues} errors={errors} setErrors={setErrors} info={infoSection} />
      <PackageInfoDamaged values={values} setValues={setValues} errors={errors} setErrors={setErrors} invoiceOk />
      <EvidenceInfo values={values} setValues={setValues} errors={errors} setErrors={setErrors} invoiceOk />
    </SupportForm>
  );
};

export default DamagedPack;

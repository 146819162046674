import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Row, Col, Card, Button, Tooltip, OverlayTrigger, Modal } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { motion } from 'framer-motion';
// import Confetti from 'react-confetti';
// import Lottie from 'lottie-react';
// import { useSpring, animated } from '@react-spring/web';
// utils
import { handleAPIError, handleCatchError } from 'utils/handleErrors';
import { initialsUpperCase } from 'utils/textFormat';
import formatMoney from 'utils/formatMoney';

// components
import { ToastSuccess } from 'components/alerts/toast';
import NotificationModal from './components/NotificationModal';
import NotificationGallery from '../../../components/carousel/NotificationsGallery';
import ButtonRequest from '../../../components/buttons/ButtonRequest';
// import animationHappy from './components/animationHappy.json';
// api
import { getAllNotices, deleteNotice, getHomeCounters } from '../../api/Api-admin';

const AdminDashboard = () => {
  // - Global State from Store.js form Values
  const { currentUser } = useSelector((state) => state.auth);
  const [notifications, setNotifications] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [notificationSelected, setNotificationSelected] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [isDeleteNoticeLoader, setIsDeleteNoticeLoader] = useState(false);
  // const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);
  const [counters, setCounters] = useState({
    depositsDay: 0,
    depositsMonth: 0,
    shippingsDay: 0,
    shippingsMonth: 0,
    users: 0,
  });
  const [quote, setQuote] = useState('');
  const [author, setAuthor] = useState('');
  // const [showConfetti, setShowConfetti] = useState(true);

  const handlerGetNotices = async () => {
    try {
      const response = await getAllNotices();
      // console.log('avisos lista:', response);
      if (response.notices !== undefined) {
        setNotifications(response.notices);
      }
    } catch (error) {
      handleCatchError(error);
    }
  };
  const handlerHomeCounters = async () => {
    try {
      const response = await getHomeCounters();
      // console.log('response home counters:', response);
      if (response.notices !== undefined) {
        setCounters({
          depositsDay: response.deposits_per_day,
          depositsMonth: response.deposits_per_month,
          shippingsDay: response.shippings_per_day,
          shippingsMonth: response.shippings_per_month,
          users: response.total_users,
          ...response,
        });
      }
    } catch (error) {
      handleCatchError(error);
    }
  };
  const handlerDeleteNotice = async () => {
    try {
      setIsDeleteNoticeLoader(true);
      const response = await deleteNotice(notificationSelected.id);
      if (response.success !== undefined) {
        // refrescar pagina
        setTrigger(!trigger);
        setIsDeleteModalOpen(false);
        ToastSuccess('Notificación eliminada correctamente ✨');
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsDeleteNoticeLoader(false);
    }
  };

  const phrases = [
    { phrase: 'El trabajo en equipo engrandece a las personas y a las empresas.', author: 'Rodri' },
    { phrase: 'Sé agradecido por lo que tienes, y terminarás teniendo más.', author: 'Vale' },
    {
      phrase: 'El sacrificio es necesario para alcanzar el éxito. Si no estás dispuesto a dejar algo atrás, nunca llegarás a donde quieres estar.',
      author: 'Dani',
    },
    {
      phrase:
        'Aprendí que el liderazgo no se trata de la personalidad del líder, sino de la capacidad de reconocer las habilidades de los demás y aprovecharlas al máximo.',
      author: 'Hector',
    },
    { phrase: 'Todas las cosas son creadas dos veces: primero en la mente, luego en la realidad.', author: 'Vivi' },
    { phrase: 'Hoy semillas, mañana flores. 🌼', author: 'Nat' },
    { phrase: 'Entre mas dificil, más grande es el triunfo. ✨', author: 'Mili' },
    { phrase: 'Mientras más grande es el sueño, más importante es el equipo.', author: 'Manfre' },
    { phrase: 'El fracaso es parte de la vida, si no fracasas, no aprendes y si no aprendes, no cambias.', author: 'Blanquita' },
    { phrase: 'Nos debe acompañar siempre la satisfacción de lo dificil.', author: 'Bety' },
    // { phrase: 'p', author: 'Pau' },
    { phrase: 'Un pequeño esfuerzo sostenido es más poderoso que un gran esfuerzo efímero.', author: 'Julio' },
  ];

  const getRandomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const getRandomPhrase = () => {
    const item = phrases[getRandomInt(0, phrases?.length - 1)];
    setQuote(item?.phrase);
    setAuthor(item?.author);
  };
  useEffect(() => {
    getRandomPhrase();
  }, []);
  useEffect(() => {
    handlerGetNotices();
    handlerHomeCounters();
  }, [trigger]);

  // useEffect(() => {
  //   if (containerRef.current) {
  //     // console.log('valor de conteinerRef.current:', containerRef.current);
  //     const { offsetWidth, offsetHeight } = containerRef.current;
  //     setDimensions({ width: offsetWidth, height: offsetHeight });
  //   }
  // }, []);

  return (
    <div className="bg-infox" ref={containerRef}>
      {/* Start Birthday Elements height={window.innerHeight} width={window.innerWidth}  width={dimensions.width} height={dimensions.height} */}
      {/* {showConfetti && <Confetti width={window.innerWidth} height={window.height} />} */}
      {/* End Birthday Elements */}

      <NotificationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        notification={notificationSelected}
        setNotificationSelected={setNotificationSelected}
        setTrigger={setTrigger}
        trigger={trigger}
      />
      <Modal
        show={isDeleteModalOpen}
        onHide={() => {
          setIsDeleteModalOpen(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-primary">
            <span className="text-alternate">Eliminar notificación</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-infow">
          <div className="text-semi-large">¿Estas segur@ de que deseas eliminar esta notificación?</div>
          <div className="text-primary ms-2 mt-3">{notificationSelected.title}</div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex flex-wrap justify-content-end bg-warningd">
            <ButtonRequest
              title="Si, eliminar"
              titleLoading="Cargando"
              handlerFn={handlerDeleteNotice}
              isButtonDisabled={isDeleteNoticeLoader}
              isLoading={isDeleteNoticeLoader}
            />
            <Button variant="outline-primary" style={{ height: '36px' }} className="w-auto ms-2 mb-2" onClick={() => setIsDeleteModalOpen(false)}>
              Cancelar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <h1 className="mb-0 pb-0 display-4 text-center text-sm-start lh-base mt-5" id="title">
        {`Autopaquete Administrador ✨ `}
      </h1>
      <Row className="g-0 bg-successx">
        <Col lg="7" className="pe-3">
          <motion.div
            initial={{ opacity: 0, x: -100 }} // Sale desde la izquierda
            animate={{ opacity: 1, x: 0 }} // Aparece en su posición original
            transition={{ duration: 0.5, ease: 'easeOut' }} // Transición suave
          >
            <h2 className="small-title invisible">admin</h2>
            <Row className="g-2 row-cols-2 row-cols-md-3 row-cols-xl-5 mb-5">
              <Col className="sh-19">
                <Card className="h-100 d-flex justify-content-center align-items-center bg-primary">
                  <img src="../img/logo/logo-car-white.png" alt="logo" width="100px" />
                </Card>
              </Col>
              <Col className="sh-19">
                <Card className="h-100">
                  <Card.Body className="d-flex flex-column aling-items-center">
                    <img alt="agent" width="45px" className="rounded-circle mx-auto" src="/img/profile/profile-5.webp" />
                    {/* <img alt="agent" width="50px" className="rounded-circle mx-auto" src="/img/illustration/icon-yoda.png" /> */}
                    <div className="text-primary  font-weight-bold mt-2 mx-auto">{`${initialsUpperCase(currentUser.name)} `}</div>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="sh-19">
                <OverlayTrigger delay={{ show: 1000, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Ir a usuarios</Tooltip>}>
                  <NavLink to="/users-all" className="anchor-effect-primary">
                    <Card className="h-100">
                      <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                        <CsLineIcons icon="user" className="text-primary" />
                        <p className="heading mt-3 text-alternate">Usuarios</p>
                        <div className="text-medium text-primary font-weight-bold">{counters.users}</div>
                      </Card.Body>
                    </Card>
                  </NavLink>
                </OverlayTrigger>
              </Col>
              <Col className="sh-19">
                <OverlayTrigger delay={{ show: 1000, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Ir a envíos</Tooltip>}>
                  <NavLink to="/shipping-all" className="anchor-effect-primary">
                    <Card className="h-100">
                      <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                        <CsLineIcons icon="plane" className="text-primary" />
                        <p className="heading mt-3 text-alternate">Envíos</p>
                        <div className="text-medium text-primary font-weight-bold">{counters.shippingsDay}</div>
                      </Card.Body>
                    </Card>
                  </NavLink>
                </OverlayTrigger>
              </Col>
              <Col className="sh-19">
                <OverlayTrigger delay={{ show: 1000, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Ir a usuarios</Tooltip>}>
                  <NavLink to="/users-all" className="anchor-effect-primary">
                    <Card className="h-100">
                      <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                        <CsLineIcons icon="wallet" className="text-primary" />
                        <p className="heading mt-3 text-alternate">Facturación</p>
                        <div className="text-medium fw-medium text-primary font-weight-bold">{formatMoney(counters.depositsDay, { symbol: '$' })}</div>
                      </Card.Body>
                    </Card>
                  </NavLink>
                </OverlayTrigger>
              </Col>
            </Row>
            <Row className="g-2 mb-5">
              {/* <Col sm="12">
                <Card>
                  <Card.Body>
                    <Card>
                      <Card.Body className="rounded" style={{ boxShadow: '0 4px 6px rgba(var(--separator-rgb), 0.5)' }}>
                        <h1 style={{ fontFamily: "'Poppins', sans-serif", fontSize: '40px', fontWeight: 'bold', color: '#ed6789', textAlign: 'center' }}>
                          <span role="img" aria-label="confetti">
                            🎉
                          </span>{' '}
                          FELIZ CUMPLE BETY{' '}
                          <span role="img" aria-label="party face">
                            🥳
                          </span>
                        </h1>
                        <p className="quote mt-2">
                          Hoy celebramos un día especial en <strong>Autopaquete</strong> porque es tu cumpleaños.
                          <span role="img" aria-label="fiesta">
                            {' '}
                            🥳
                          </span>
                          <span role="img" aria-label="pastel de cumpleaños">
                            {' '}
                            🎂
                          </span>
                          Te deseamos muchas felicidades, que este nuevo año de vida venga lleno de éxitos, alegrías y momentos inolvidables. Gracias por tu
                          entrega, compromiso y dedicación. Tu trabajo y esfuerzo hacen la diferencia en nuestro equipo, y estamos muy agradecidos de contar
                          contigo. ¡Que cumplas muchos años más y que este día esté lleno de sonrisas, sorpresas y mucha felicidad!
                          <span role="img" aria-label="globo">
                            {' '}
                            🎈
                          </span>
                          <span role="img" aria-label="regalo">
                            {' '}
                            🎁
                          </span>
                          <span role="img" aria-label="corazón">
                            {' '}
                            💖
                          </span>
                          ¡Disfruta tu día al máximo!
                          <span role="img" aria-label="cohete">
                            {' '}
                            🚀
                          </span>
                        </p>
                      </Card.Body>
                    </Card>
                    <div className="d-flex justify-content-center aling-items-center">
                      <Lottie animationData={animationHappy} loop style={{ width: '200px' }} />
                    </div>
                  </Card.Body>
                </Card>
              </Col> */}
              <Col sm="12">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col xs="12">{`¡Hola, ${initialsUpperCase(currentUser.name)}! Aquí tienes las frases favoritas del equipo. 😊 `}</Col>
                      <Col xs="12" className="d-flex justify-content-end">
                        <div onClick={getRandomPhrase}>
                          <CsLineIcons icon="rotate-left" className="anchor-effect-primary me-2 cursor-pointer" onClick={getRandomPhrase} />
                        </div>
                      </Col>
                    </Row>
                    <Card className="mt-2">
                      <Card.Body className="rounded" style={{ boxShadow: '0 4px 6px rgba(var(--separator-rgb), 0.5)' }}>
                        <p className="quote">"{quote}"</p>
                        <p className="author">- {author}</p>
                      </Card.Body>
                    </Card>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </motion.div>
        </Col>

        <Col lg="5">
          <motion.div
            initial={{ opacity: 0, x: 100 }} // Sale desde la derecha
            animate={{ opacity: 1, x: 0 }} // Aparece en su posición original
            transition={{ duration: 0.5, ease: 'easeOut' }} // Transición suave
          >
            <h2 className="small-title">Notificaciones</h2>
            <Card>
              <Card.Body className="bg-alternatex">
                {notifications.length > 0 && <NotificationGallery notifications={notifications} />}
                <div className={`mt-5 ${currentUser.role === 'Control Medio' ? 'd-none' : 'd-block'}`}>
                  <div className="d-flex justify-content-end mb-5">
                    <Button
                      className="btn-icon w-auto btn btn-icon btn-primary btn-icon-start ms-0 ms-sm-1"
                      onClick={() => {
                        setIsModalOpen(true);
                      }}
                    >
                      <CsLineIcons icon="arrow-right" />
                      <span className="ps-2">Crear nueva</span>
                    </Button>
                  </div>
                  {notifications.length > 0 ? (
                    notifications?.map((notice, index) => (
                      <Card className="mb-2" key={index}>
                        <Row className="g-0 h-100 sh-lg-9 position-relative">
                          <Col xs="auto" className="positio-relative">
                            <img src={notice.img} alt="servicio" className="card-img card-img-horizontal sw-11 h-100" />
                          </Col>
                          <Col className="py-4 py-lg-0 ps-3 pe-4 h-100 card bg-light" style={{ boxShadow: '0 4px 6px rgba(var(--separator-rgb), 0.5)' }}>
                            <Row className="g-0 h-100 align-content-center">
                              <Col xs="10" className="d-flex flex-column mb-lg-0 mb-3 pe-3 d-flex order-1 h-lg-100 justify-content-center order-1">
                                <div className="text-medium text-small">Titulo</div>
                                <div className="text-primary">{notice.title}</div>
                              </Col>
                              <Col xs="2" className="ps-4 d-flex mb-2 mb-lg-0 align-items-center justify-content-end order-1 order-lg-last">
                                <div
                                  onClick={() => {
                                    setNotificationSelected(notice);
                                    setIsModalOpen(true);
                                  }}
                                >
                                  <CsLineIcons icon="edit" className="anchor-effect-primary me-2 cursor-pointer" />
                                </div>
                                <div
                                  onClick={() => {
                                    setNotificationSelected(notice);
                                    setIsDeleteModalOpen(true);
                                  }}
                                >
                                  <CsLineIcons icon="bin" className="anchor-effect-primary cursor-pointer" />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    ))
                  ) : (
                    <div>No hay notificaciones disponibles</div>
                  )}
                </div>
              </Card.Body>
            </Card>
          </motion.div>
        </Col>
      </Row>
    </div>
  );
};

export default AdminDashboard;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button, Dropdown, Card, Form, Modal } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import * as Yup from 'yup';
import ClipLoader from 'react-spinners/ClipLoader';
// utils
import { handleCatchError, handleAPIError } from 'utils/handleErrors';
// components
import AdminLoader from '../../../components/loading/AdminLoader';
import LoadingPulse from '../../../components/loading/LoadingPulse';
import { ToastErrorCath } from '../../../components/alerts/toast';
// api
import { getRateList, createUser, getSellers } from '../../api/Api-admin';

const AdminUserRegister = () => {
  const title = 'Registrar Usuario';
  const description = 'Registrar Usuario Plataforma Autopaquete';
  const history = useHistory();
  // Local State
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userOk, setUserOk] = useState({});
  const [ratesList, setRatesList] = useState([]);
  const [sellerList, setSellerList] = useState([]);

  const [user, setUser] = useState({
    crmUser: '',
    seller: '',
    customerType: '',
    rate: '',
    razon: '',
    legalRep: '',
    rfc: '',
    fiscal: '',
    zip: '',
    email: '',
    password: '',
  });
  const [errorsUser, setErrorsUser] = useState({
    crmUser: '',
    seller: '',
    customerType: '',
    rate: '',
    razon: '',
    legalRep: '',
    rfc: '',
    fiscal: '',
    zip: '',
    email: '',
    password: '',
  });
  const validationSchema = Yup.object().shape({
    crmUser: Yup.string().required('El nombre de usuario de CRM es obligatorio'),
    seller: Yup.string().required('Por favor, selecciona un vendedor'),
    customerType: Yup.string().required('Por favor, elige el tipo de cliente'),
    rate: Yup.string().required('La tarifa es obligatoria'),
    razon: Yup.string().required('La razón social es obligatoria'),
    legalRep: Yup.string().required('Introduce el nombre del representante legal'),
    rfc: Yup.string().required('El RFC es obligatorio'),
    fiscal: Yup.string().required('La información fiscal es obligatoria'),
    zip: Yup.string().min(5, 'El código postal debe tener 5 dígitos').required('Introduce el código postal'),
    email: Yup.string().email('Introduce un correo electrónico válido').required('El correo electrónico es obligatorio'),
    password: Yup.string().required('La contraseña es obligatoria'),
  });
  const updateFormField = (data, validation, field) => {
    if (data === '' || validation.test(data)) {
      setUser((prevValues) => {
        return { ...prevValues, [field]: data };
      });
    }
  };
  const deleteFormError = (field) => {
    setErrorsUser((prevValue) => {
      return { ...prevValue, [field]: '' };
    });
  };
  const handleCreateUser = async () => {
    try {
      setIsLoading(true);
      const response = await createUser(user);
      // console.log('create user response', response);
      if (response.crm_user_id !== undefined) {
        setIsModalOpen(true);
        setUserOk(response);
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };
  // Validation
  const validateForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(user, { abortEarly: false });
      // console.log('ok puedes crear al usuario con los sig datos:', user);
      handleCreateUser();
    } catch (errors) {
      const formErrors = {};
      errors.inner.forEach((error) => {
        formErrors[error.path] = error.message;
      });
      setErrorsUser(formErrors);
      ToastErrorCath('Por favor, revisa y completa todos los campos.');
    }
  };
  const handlerGetSellers = async () => {
    try {
      const response = await getSellers();
      // console.log(response);
      if (response.list) {
        setSellerList(response.list);
      }
    } catch (error) {
      handleCatchError(error);
    }
  };
  const handlerGetRates = async () => {
    try {
      const response = await getRateList();
      if (response.rates_list) {
        setRatesList(response.rates_list);
      } else if (response.error) {
        handleAPIError(response);
        // ToastErrorCath('Error: No se pudo cargar el tarifario. Por favor, verifica tu conexión a Internet e inténtalo nuevamente.');
      }
    } catch (error) {
      // handleCatchError(error);
      ToastErrorCath('Error: No se pudo cargar el tarifario. Por favor, verifica tu conexión a Internet e inténtalo nuevamente.');
    }
  };
  useEffect(() => {
    handlerGetRates();
    handlerGetSellers();
    setTimeout(() => {
      setIsInitialLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    // console.log('objeto user:', user);
  }, [user]);
  return (
    <>
      {/* Success Modal Start */}
      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton />
        <Modal.Body>
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          <h3 className="mb-4">{` 🎉 ${userOk.message} 🎉`} </h3>
          <div className="text-alternate mb-2">
            Crm id:<span className="text-primary ms-2">{userOk.crm_user_id}</span>
          </div>
          <div className="text-alternate mb-2">
            Correo:<span className="text-primary ms-2">{userOk.email}</span>
          </div>
          <div className="text-alternate mb-2">
            Razón Social:<span className="text-primary ms-2">{userOk.razon_social}</span>
          </div>
          <div className="text-alternate mb-2">
            Vendedor:<span className="text-primary ms-2">{userOk.seller_code}</span>
          </div>
          <div className="text-alternate mb-2">
            Cliente Tipo:<span className="text-primary ms-2">{userOk.customer_type}</span>
          </div>
          <div className="text-alternate mb-2">
            Tarifario:<span className="text-primary ms-2">{userOk.rates_list}</span>
          </div>
          <div className="text-alternate mb-2">
            Clabe:<span className="text-primary ms-2">{userOk.clabe_stp}</span>
          </div>
          <div className="text-alternate mb-2">
            Factura Actomática:<span className="text-primary ms-2">{userOk.automatic_invoice ? 'Si' : 'No'}</span>
          </div>
          <div className="text-alternate mb-2">
            Saldo Automático:<span className="text-primary ms-2">{userOk.automatic_balance ? 'Si' : 'No'}</span>
          </div>
        </Modal.Body>
      </Modal>
      {/* Success Modal End */}
      {isInitialLoading ? (
        <AdminLoader />
      ) : (
        <>
          {isLoading && <LoadingPulse />}
          <HtmlHead title={title} description={description} />
          {/* Title Start */}
          <div className="page-title-container">
            <Row className="g-0">
              {/* Title Start */}
              <Col className="col-auto mb-3 mb-sm-0 me-auto">
                <div className="primary-link pb-1 d-inline-block hidden breadcrumb-back cursor-pointer invisible" onClick={() => history.goBack()}>
                  <CsLineIcons icon="chevron-left" size="13" />
                  <span className="align-middle text-small ms-1">Atrás</span>
                </div>
                <h1 className="mb-5 pb-0 display-4" id="title">
                  {title}
                </h1>
              </Col>
              {/* Title End */}
            </Row>
          </div>
          <Form onSubmit={validateForm} id="cardContainer">
            {/* Title End */}
            <Row className="g-0">
              {/* Customer Info */}
              <Col xs="12" lg="10" className="col-lg">
                <h2 className="small-title">Nuevo usuario</h2>
                <Card className="mb-5">
                  <Card.Body>
                    <Row>
                      <Col xs="12" sm="6" lg="2" className="mb-2 position-relative order-1">
                        <Form.Label>Crm id</Form.Label>
                        <div className="filled form-group tooltip-end-top">
                          <CsLineIcons icon="user" />
                          <Form.Control
                            type="text"
                            value={user.crmUser}
                            onChange={(e) => {
                              updateFormField(e.target.value, /^[A-Z0-9]+$/i, 'crmUser');
                              deleteFormError('crmUser');
                            }}
                          />
                        </div>
                        <div className={`mt-1 invalid-tooltip ${errorsUser.crmUser ? 'd-block' : 'd-none'}`}>{errorsUser.crmUser}</div>
                      </Col>
                      <Col xs="12" md="6" className="mb-2 position-relative order-2 order-sm-3 order-md-2">
                        <Form.Label>Correo</Form.Label>
                        <div className="filled form-group tooltip-end-top">
                          <CsLineIcons icon="email" />
                          <Form.Control
                            type="text"
                            value={user.email}
                            onChange={(e) => {
                              updateFormField(e.target.value, /^\S+$/, 'email');
                              deleteFormError('email');
                            }}
                          />
                        </div>
                        <div className={`mt-1 invalid-tooltip ${errorsUser.email ? 'd-block' : 'd-none'}`}>{errorsUser.email}</div>
                      </Col>
                      <Col xs="12" sm="6" lg="4" className="mb-2 position-relative order-3 order-sm-2 order-md-3">
                        <Form.Label>Contraseña</Form.Label>
                        <div className="filled form-group tooltip-end-top">
                          <CsLineIcons icon="lock-off" />
                          <Form.Control
                            type="text"
                            value={user.password}
                            onChange={(e) => {
                              updateFormField(e.target.value, /^\S+$/, 'password');
                              deleteFormError('password');
                            }}
                          />
                        </div>
                        <div className={`mt-1 invalid-tooltip ${errorsUser.password ? 'd-block' : 'd-none'}`}>{errorsUser.password}</div>
                      </Col>
                      <Col xs="12" md="6" className="mb-2 position-relative order-4">
                        <Form.Label>Razón Social</Form.Label>
                        <div className="filled form-group tooltip-end-top position-relative d-flex align-items-center">
                          <CsLineIcons icon="building-small" />
                          <Form.Control
                            type="text"
                            value={user.razon}
                            onChange={(e) => {
                              setUser((prevValues) => {
                                return { ...prevValues, razon: e.target.value };
                              });
                              deleteFormError('razon');
                            }}
                          />
                        </div>
                        <div className={`mt-1 invalid-tooltip ${errorsUser.razon ? 'd-block' : 'd-none'}`}>{errorsUser.razon}</div>
                      </Col>
                      <Col xs="12" md="6" className="mb-2 position-relative order-5">
                        <Form.Label>Representante Legal</Form.Label>
                        <div className="filled form-group tooltip-end-top position-relative d-flex align-items-center">
                          <CsLineIcons icon="suitcase" />
                          <Form.Control
                            type="text"
                            value={user.legalRep}
                            onChange={(e) => {
                              setUser((prevValues) => {
                                return { ...prevValues, legalRep: e.target.value };
                              });
                              deleteFormError('legalRep');
                            }}
                          />
                        </div>
                        <div className={`mt-1 invalid-tooltip ${errorsUser.legalRep ? 'd-block' : 'd-none'}`}>{errorsUser.legalRep}</div>
                      </Col>
                      <Col xs="12" sm="6" lg="3" className="mb-2 position-relative order-5">
                        <Form.Label>RFC</Form.Label>
                        <div className="filled form-group tooltip-end-top">
                          <CsLineIcons icon="invoice" />
                          <Form.Control
                            type="text"
                            value={user.rfc}
                            onChange={(e) => {
                              updateFormField(e.target.value, /^[A-Z0-9]+$/i, 'rfc');
                              deleteFormError('rfc');
                            }}
                          />
                        </div>
                        <div className={`mt-1 invalid-tooltip ${errorsUser.rfc ? 'd-block' : 'd-none'}`}>{errorsUser.rfc}</div>
                      </Col>
                      <Col xs="12" sm="6" lg="4" className="mb-2 position-relative order-5">
                        <Form.Label>Código Postal</Form.Label>
                        <div className="filled form-group tooltip-end-top">
                          <CsLineIcons icon="pin" />
                          <Form.Control
                            type="text"
                            value={user.zip}
                            onChange={(e) => {
                              updateFormField(e.target.value, /^[0-9]+$/i, 'zip');
                              deleteFormError('zip');
                            }}
                          />
                        </div>
                        <div className={`mt-1 invalid-tooltip ${errorsUser.zip ? 'd-block' : 'd-none'}`}>{errorsUser.zip}</div>
                      </Col>
                      <Col xs="12" md="6" lg="5" className="mb-2 position-relative order-5">
                        <Form.Label>Regimen</Form.Label>
                        <div className="filled form-group tooltip-end-top">
                          <CsLineIcons icon="suitcase" />
                          <Form.Control
                            type="text"
                            value={user.fiscal}
                            onChange={(e) => {
                              setUser((prevValues) => {
                                return { ...prevValues, fiscal: e.target.value };
                              });
                              deleteFormError('fiscal');
                            }}
                          />
                        </div>
                        <div className={`mt-1 invalid-tooltip ${errorsUser.fiscal ? 'd-block' : 'd-none'}`}>{errorsUser.fiscal}</div>
                      </Col>
                      <Col xs="12" className="mt-5 pe-2 bg-warningw order-5">
                        <div className="d-flex align-items-center mb-2 w-auto position-relative">
                          <CsLineIcons icon="shield" size="19" className="text-primary" />
                          <div className="text-alternate mx-2"> CLIENTE TIPO </div>
                          <Dropdown
                            onClick={() => {
                              deleteFormError('customerType');
                            }}
                          >
                            <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1 text-primary">
                              {user.customerType}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="shadow dropdown-menu-start">
                              <Dropdown.Item
                                className={`${user.customerType === 'T' && 'd-none'}`}
                                onClick={() => {
                                  setUser((prevValues) => {
                                    return { ...prevValues, customerType: 'T' };
                                  });
                                }}
                              >
                                T
                              </Dropdown.Item>
                              <Dropdown.Item
                                className={`${user.customerType === 'C' && 'd-none'}`}
                                onClick={() => {
                                  setUser((prevValues) => {
                                    return { ...prevValues, customerType: 'C' };
                                  });
                                }}
                              >
                                C
                              </Dropdown.Item>
                              <Dropdown.Item
                                className={`${user.customerType === 'D' && 'd-none'}`}
                                onClick={() => {
                                  setUser((prevValues) => {
                                    return { ...prevValues, customerType: 'D' };
                                  });
                                }}
                              >
                                D
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                          <div className={`mt-1 invalid-tooltip ${errorsUser.customerType ? 'd-block' : 'd-none'}`}>{errorsUser.customerType}</div>
                        </div>
                      </Col>
                      <Col xs="12" className="mt-2 pe-2 bg-infow order-5">
                        <div className="d-flex align-items-center mb-2 w-auto position-relative">
                          <CsLineIcons icon="support" size="19" className="text-primary" />
                          <div className="text-alternate mx-2"> VENDEDOR </div>
                          <Dropdown
                            onClick={() => {
                              deleteFormError('seller');
                            }}
                          >
                            <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1 text-primary">
                              {user.seller}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="shadow dropdown-menu-start">
                              {sellerList?.map((seller, index) => (
                                <Dropdown.Item
                                  key={index}
                                  className={`${user.seller === seller && 'd-none'}`}
                                  onClick={() =>
                                    setUser((prevValues) => {
                                      return { ...prevValues, seller };
                                    })
                                  }
                                >
                                  {seller}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          <div className={`mt-1 invalid-tooltip ${errorsUser.seller ? 'd-block' : 'd-none'}`}>{errorsUser.seller}</div>
                        </div>
                      </Col>
                      <Col xs="12" className="mt-2 pe-2 bg-successw order-5">
                        <div className="d-flex align-items-center mb-2 w-auto position-relative">
                          <CsLineIcons icon="star" size="19" className="text-primary" />
                          <div className="text-alternate mx-2"> TARIFARIO </div>
                          <Dropdown
                            onClick={() => {
                              deleteFormError('rate');
                            }}
                          >
                            <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1 text-primary">
                              {user.rate}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="shadow dropdown-menu-start" style={{ height: '200px', overflowY: 'scroll' }}>
                              {ratesList?.map((rates) => (
                                <Dropdown.Item
                                  key={rates.id}
                                  onClick={() => {
                                    setUser((prevValues) => {
                                      return { ...prevValues, rate: rates.name };
                                    });
                                  }}
                                >
                                  {rates.name}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                          <div className={`mt-1 invalid-tooltip ${errorsUser.rate ? 'd-block' : 'd-none'}`}>{errorsUser.rate}</div>
                        </div>
                      </Col>
                    </Row>
                    {/* Button */}
                    <Col xs="12" className="mt-5 text-end">
                      <Button variant="primary" size="lg" className="btn-icon btn-icon-start w-100 w-md-auto" type="submit">
                        {/* <CsLineIcons icon="save" /> <span>Registrar</span> */}
                        {isLoading && <ClipLoader color="#FFFFFF" loading={isLoading} size={10} />}
                        {isLoading ? ' Cargando' : 'Registrar'}
                      </Button>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </>
  );
};

export default AdminUserRegister;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  generalData: {
    fields: {
      incidentType: false,
      isVerifiedToReport: false,
      insuranceCategories: [],
      policyBeneficiaries: [],
      addressesFrom: [],
      addressesTo: [],
      parcels: [],
    },
  },
};
const generalDataSlice = createSlice({
  name: 'generalData',
  initialState,
  reducers: {
    setGeneralData(state, action) {
      const { point, data } = action.payload;
      state.generalData.fields[point] = data;
    },
    setInsuranceCategories(state, action) {
      const { data } = action.payload;
      state.generalData.fields.insuranceCategories = data;
    },
    setPolicyBeneficiaries(state, action) {
      const { data } = action.payload;
      state.generalData.fields.policyBeneficiaries = data;
    },
    setAddressesTo(state, action) {
      const { data } = action.payload;
      state.generalData.fields.addressesTo = data;
    },
    setAddressesFrom(state, action) {
      const { data } = action.payload;
      state.generalData.fields.addressesFrom = data;
    },
    setParcels(state, action) {
      const { data } = action.payload;
      state.generalData.fields.parcels = data;
    },
  },
});
export const { setGeneralData, setInsuranceCategories, setPolicyBeneficiaries, setAddressesTo, setAddressesFrom, setParcels } = generalDataSlice.actions; // imported in file.js
const generalDataReducer = generalDataSlice.reducer; // store.js setup
export default generalDataReducer;

import React from 'react';
import { useSelector } from 'react-redux';

import { Col, Row, Card } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

const { FormatMoney } = require('format-money-js');

const ShippingInfoDetail = () => {
  // - Global State from Store.js form Values
  const { ratesData } = useSelector((state) => state.shipping_rates);
  const { senderData } = useSelector((state) => state.shipping_sender);
  const { receiverData } = useSelector((state) => state.shipping_receiver);
  const { parcelData } = useSelector((state) => state.shipping_parcel);
  const { insuranceData } = useSelector((state) => state.shipping_insurance);

  // console.log('datos del paquete:', parcelData);
  // console.log('datos del seguro:', insuranceData);
  // console.log('address from:', senderData);
  // console.log('address To:', receiverData);

  const fmDecimal = new FormatMoney({
    decimals: 0,
  });
  return (
    // sw-lg-35
    <Card className="mb-5 w-100 sw-lg-40">
      <Card.Body>
        <div className="mb-5">
          <p className="text-small text-muted mb-2">DIRECCIÓN DE ORIGEN</p>
          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="user" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{senderData.fields.name.toUpperCase()}</Col>
          </Row>
          {senderData.fields.company && (
            <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="building" size="17" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">{senderData.fields.company.toUpperCase()}</Col>
            </Row>
          )}
          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="pin" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">
              <div>{`${senderData.fields.street.toUpperCase()} # ${senderData.fields.streetNumber}, ${senderData.fields.neighborhood.toUpperCase()}. `}</div>
              <div>{`${senderData.fields?.zip} ${senderData.fields?.city.toUpperCase()}, ${senderData.fields.state?.name.toUpperCase()}.`}</div>
            </Col>
          </Row>
          {senderData.fields?.placeRef && (
            <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="destination" size="17" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">{senderData.fields?.placeRef.toUpperCase()}</Col>
            </Row>
          )}
          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="phone" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{senderData.fields?.phone}</Col>
          </Row>
        </div>

        <div className="mb-5">
          <p className="text-small text-muted mb-2">DIRECCIÓN DE DESTINO</p>
          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="user" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{receiverData.fields?.name.toUpperCase()}</Col>
          </Row>
          {receiverData.fields?.company && (
            <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="building" size="17" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">{receiverData.fields?.company.toUpperCase()}</Col>
            </Row>
          )}

          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="pin" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">
              <div>{`${receiverData.fields.street.toUpperCase()} # ${
                receiverData.fields.streetNumber
              }, ${receiverData.fields.neighborhood.toUpperCase()}. `}</div>
              <div>{`${receiverData.fields?.zip} ${receiverData.fields?.city.toUpperCase()}, ${receiverData.fields.state?.name.toUpperCase()}.`}</div>
            </Col>
          </Row>
          {receiverData.fields?.placeRef && (
            <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="destination" size="17" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">{receiverData.fields?.placeRef.toUpperCase()}</Col>
            </Row>
          )}
          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="phone" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{receiverData.fields?.phone}</Col>
          </Row>
        </div>

        <div className="mb-5">
          <p className="text-small text-muted mb-2">PAQUETE</p>
          {parcelData.fields?.name && (
            <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="sign" size="17" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">{parcelData.fields?.name.toUpperCase()}</Col>
            </Row>
          )}

          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="edit-square" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{parcelData.fields?.product_description.toUpperCase()}</Col>
          </Row>
          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="box" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{`${parcelData.fields?.depth} x ${parcelData.fields?.width} x ${parcelData.fields?.height} - ${parcelData.fields?.weight} kg`}</Col>
          </Row>
          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="abacus" size="17" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{`${ratesData.parcel?.volumetric_weight} kg Volumétrico `}</Col>
          </Row>
          <Row className="g-0 mb-2">
            <Col xs="auto">
              <div className="sw-3 me-1">
                <CsLineIcons icon="dollar" size="18" className="text-primary" />
              </div>
            </Col>
            <Col className="text-alternate">{`${ratesData.parcel?.total_weight} kg Total`}</Col>
          </Row>
          {ratesData.parcel?.multi_pieces > 1 && (
            <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="print" size="18" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">{`Multi-piezas ${ratesData.parcel?.multi_pieces} `}</Col>
            </Row>
          )}
        </div>
        {/* parcelData.fields?.product_value */}
        {parcelData.fields?.product_value && (
          <div className="mb-5">
            <p className="text-small text-muted mb-2">SEGURO</p>
            <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="dollar" size="17" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">{`${fmDecimal.from(Number(parcelData.fields?.product_value) || 0, { symbol: '$' })} - ${
                insuranceData.fields?.productState
              } `}</Col>
            </Row>
            <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="user" size="17" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">{insuranceData.fields?.beneficiaryName.toUpperCase()}</Col>
            </Row>
            <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="invoice" size="17" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">{insuranceData.fields?.beneficiaryRFC}</Col>
            </Row>

            <Row className="g-0 mb-2">
              <Col xs="auto">
                <div className="sw-3 me-1">
                  <CsLineIcons icon="edit-square" size="17" className="text-primary" />
                </div>
              </Col>
              <Col className="text-alternate">{insuranceData.fields?.category}</Col>
            </Row>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
export default ShippingInfoDetail;

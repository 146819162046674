// 1. React and core dependencies
import React, { useState } from 'react';

// 2. Third-party libraries
import * as Yup from 'yup';

// 3. State management
import { useSelector } from 'react-redux';

// 4. UI components
import { Col, Row } from 'react-bootstrap';

// 5. Custom form components
import InputText from 'components/form-elements/InputText2';

// 6. Main layout components
import SupportForm from '../SupportForm';

// 7. Section-based components
import ReportInfo from '../sections/ReportInfo';
import PackageInfo from '../sections/PackageInfo';

const ReturnPack = () => {
  // - Global State from Store.js form Values
  const { generalData } = useSelector((state) => state.general_data);
  const [values, setValues] = useState({
    shippingId: null,
    incidentType: 'Retornar Paquete',
    serviceName: '',
    // user typing the next fields
    email: '',
    betweenStreetFrom: '',
    referencesFrom: '',
    // info package
    packageContent: '',
    packagingDescription: '',
    // evidence
    invoiceFile: '',
    comment: '',
  });

  const [errors, setErrors] = useState({
    shippingId: '',
    incidentType: '',
    email: '',
    packageContent: '',
    packagingDescription: '',
    invoiceFile: '',
    betweenStreetFrom: '',
    referencesFrom: '',
  });
  // Schema Validation
  const validationSchema = Yup.object().shape({
    shippingId: Yup.string().required('Por favor, introduce el ID de envío').nullable(),
    email: Yup.string().email('Por favor, introduce un correo electrónico válido').required('El correo electrónico es obligatorio'),
    betweenStreetFrom: Yup.string().required('Indica las calles entre las cuales se ubica'),
    referencesFrom: Yup.string().required('Proporciona una referencia'),
    packagingDescription: Yup.string().required('Describe el tipo de embalaje'),
    // invoiceFile: Yup.string().required('Sube la factura'),
    packageContent: Yup.string().required('Describe el contenido del paquete'),
  });
  // console.log('Retorno de Paquete data:', values);
  // console.log('Retorno de Paquete errors:', errors);

  // props
  const infoSection = 'Por favor, confirma las calles y las referencias del domicilio de origen.';

  return (
    <SupportForm values={values} setValues={setValues} setErrors={setErrors} errors={errors} validationSchema={validationSchema} formTitle="Retornar Paquete">
      {/* <ReportInfo values={values} setValues={setValues} errors={errors} setErrors={setErrors} /> */}
      <Row className={`g-3 mb-5 ${generalData.fields.isVerifiedToReport ? 'd-flex' : 'd-none'}`}>
        <h2 className="small-title">Dirección Origen</h2>
        <div>{infoSection}</div>
        <Col md="8" className="position-relative">
          <InputText
            label="Entre Calles origen"
            icon="home"
            placeholder="Ej: Entre Hidalgo y Morelos"
            value={values.betweenStreetFrom}
            isRequired
            fieldName="betweenStreetFrom"
            formObj={setValues}
            setErrors={setErrors}
          />
          {errors.betweenStreetFrom && <div className={`invalid-tooltip ${errors.betweenStreetFrom ? 'd-block' : 'd-none'}`}>{errors.betweenStreetFrom}</div>}
        </Col>
        <Col sm="8" className="position-relative">
          <InputText
            label="Referencias del lugar"
            icon="destination"
            placeholder='Ej: A un lado de lavanderia "Burbujas"'
            value={values.referencesFrom}
            isRequired
            fieldName="referencesFrom"
            formObj={setValues}
            setErrors={setErrors}
          />
          {errors.referencesFrom && <div className={`invalid-tooltip ${errors.referencesFrom ? 'd-block' : 'd-none'}`}>{errors.referencesFrom}</div>}
        </Col>
      </Row>
      <PackageInfo values={values} setValues={setValues} errors={errors} setErrors={setErrors} />
    </SupportForm>
  );
};

export default ReturnPack;

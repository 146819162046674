import React, { useState, useEffect } from 'react';
import { NavLink, useParams, useHistory } from 'react-router-dom';
import { Row, Col, Card, Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import moment from 'moment';
// utils
import { handleAPIError } from 'utils/handleErrors';
import formatMoney from 'utils/formatMoney';
// components
import { ToastErrorApi, ToastErrorCath } from 'components/alerts/toast';
import LoadingCar from '../../../components/loading/LoadingCar';
// api
import { getOrderById, getShippingPDF, getOrderZip } from '../../../api/Api-user';

const DetailOrder = () => {
  const title = 'Detalle orden';
  const description = 'Detalle orden Plataforma Autopaquete';
  const history = useHistory();
  // Local State
  const { orderId } = useParams();
  const [order, setOrder] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const handlerGetOrderById = async () => {
    try {
      const response = await getOrderById(orderId);
      // console.log('response order detail:', response);
      if (response.order_id !== undefined) {
        setOrder(response);
      } else if (response.error) {
        handleAPIError(response);
        history.push('/envios/ordenes');
      }
    } catch {
      history.push('/envios/ordenes');
    } finally {
      setIsLoading(false);
    }
  };

  const base64ToBlob = (base64, contentType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };

  const downloadPdf = async (labelId) => {
    // console.log('LABEL ID:', labelId);
    getShippingPDF(labelId)
      .then((response) => {
        // console.log('LABEL RES:', response);
        if (response.label !== undefined) {
          const blob = base64ToBlob(response.label, 'application/pdf');
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = `${labelId}.pdf`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        } else if (response.error) {
          const firstKey = Object.keys(response.error)[0];
          ToastErrorApi(response.error[firstKey]);
        }
      })
      .catch(() => {
        ToastErrorCath();
      });
  };
  const downloadZip = (idOrder) => {
    getOrderZip(idOrder).then((response) => {
      // console.log('ORDER RES:', response);
      const blob = base64ToBlob(response.zip_base64, 'application/zip');
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `Orden-${response.order_id}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    });
  };
  useEffect(() => {
    handlerGetOrderById();
  }, []);
  // console.log('orden detail:', order);
  return (
    <>
      {isLoading && <LoadingCar />}
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        <div className="page-title-container mb-5">
          <Row className="g-0">
            {/* Title Start */}
            <Col className="col-auto mb-3 mb-sm-0 me-auto">
              <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/envios/ordenes">
                <CsLineIcons icon="chevron-left" size="13" />
                <span className="align-middle text-small ms-1">Atrás</span>
              </NavLink>
              <h1 className="mb-0 pb-0 display-4" id="title">
                {title}
              </h1>
            </Col>
            {/* Title End */}
            {/* Top Buttons Start */}
            <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
              <Button variant="primary" onClick={() => downloadZip(order?.order_id)}>
                {/* <CsLineIcons icon="download" /> */}
                <span>Descargar Zip</span>
              </Button>
            </Col>
            {/* Top Buttons End */}
          </Row>
        </div>
        {/* Counters Start */}
        <Row className="g-2 mb-3 mt-5">
          <Col sm="6" lg="3">
            <Card className="sh-13 sh-lg-15 sh-xl-14">
              <Card.Body className="h-100 py-3 d-flex align-items-center">
                <Row className="g-0 align-items-center">
                  <Col xs="auto" className="pe-3">
                    <div className="border border-primary sw-4 sh-4 rounded-xl d-flex justify-content-center align-items-center">
                      <CsLineIcons icon="clipboard" className="text-primary" />
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex align-items-center lh-1-25">ID Orden</div>
                    <div className="text-primary">{order?.order_id}</div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="6" lg="3">
            <Card className="sh-13 sh-lg-15 sh-xl-14">
              <Card.Body className="h-100 py-3 d-flex align-items-center">
                <Row className="g-0 align-items-center">
                  <Col xs="auto" className="pe-3">
                    <div className="border border-primary sw-4 sh-4 rounded-xl d-flex justify-content-center align-items-center">
                      <CsLineIcons icon="calendar" className="text-primary" />
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex align-items-center lh-1-25">Fecha Creación</div>
                    <div className="text-primary">{moment(order?.created).utcOffset('-06:00').format('DD-MM-YYYY HH:mm:ss')}</div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="6" lg="3">
            <Card className="sh-13 sh-lg-15 sh-xl-14">
              <Card.Body className="h-100 py-3 d-flex align-items-center">
                <Row className="g-0 align-items-center">
                  <Col xs="auto" className="pe-3">
                    <div className="border border-primary sw-4 sh-4 rounded-xl d-flex justify-content-center align-items-center">
                      <CsLineIcons icon="shipping" className="text-primary" />
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex align-items-center lh-1-25">Envíos solicitados</div>
                    <div className="text-primary">{order?.requested_shippings}</div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <h2 className="small-title">Estado de guías</h2>
        <Row className="g-2 mb-5">
          <Col sm="6" lg="3">
            <Card className="sh-13 sh-lg-15 sh-xl-14">
              <Card.Body className="h-100 py-3 d-flex align-items-center">
                <Row className="g-0 align-items-center">
                  <Col xs="auto" className="pe-3">
                    <div className="border border-primary sw-4 sh-4 rounded-xl d-flex justify-content-center align-items-center">
                      <CsLineIcons icon="check" className="text-primary" />
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex align-items-center lh-1-25">Exitosas</div>
                    <div className="text-primary">{order?.completed_shippings}</div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="6" lg="3">
            <Card className="sh-13 sh-lg-15 sh-xl-14">
              <Card.Body className="h-100 py-3 d-flex align-items-center">
                <Row className="g-0 align-items-center">
                  <Col xs="auto" className="pe-3">
                    <div className="border border-primary sw-4 sh-4 rounded-xl d-flex justify-content-center align-items-center">
                      <CsLineIcons icon="multiply" className="text-primary" />
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex align-items-center lh-1-25">Fallidas</div>
                    <div className="text-primary">{order?.failed_shippings}</div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col sm="6" lg="3">
            <Card className="sh-13 sh-lg-15 sh-xl-14">
              <Card.Body className="h-100 py-3 d-flex align-items-center">
                <Row className="g-0 align-items-center">
                  <Col xs="auto" className="pe-3">
                    <div className="border border-primary sw-4 sh-4 rounded-xl d-flex justify-content-center align-items-center">
                      <CsLineIcons icon="spinner" className="text-primary" />
                    </div>
                  </Col>
                  <Col>
                    <div className="d-flex align-items-center lh-1-25">Pendientes</div>
                    <div className="text-primary">{order?.pending_shippings}</div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* Counters End */}
        {/* List Header Start */}
        <h2 className="small-title">Envíos</h2>
        <Row className="g-0 mb-2 d-none d-lg-flex">
          <Col xs="auto" className="sw-11 d-none d-lg-flex" />
          <Col>
            <Row className="g-0 h-100 align-content-center custom-sort ps-5 pe-4 h-100">
              <Col xs="3" className="d-flex flex-column mb-lg-0 pe-3 d-flex">
                <div className="text-muted text-small cursor-pointer">SERVICIO / ID ENVIO</div>
              </Col>
              <Col xs="2" lg="3" className="d-flex flex-column pe-1 justify-content-center">
                <div className="text-muted text-small cursor-pointer">FECHA / HORA</div>
              </Col>
              <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center">
                <div className="text-muted text-small cursor-pointer">COSTO</div>
              </Col>
              <Col xs="2" lg="2" className="d-flex flex-column pe-1 justify-content-center">
                <div className="text-muted text-small cursor-pointer">PESO COBRADO</div>
              </Col>
              <Col xs="2" lg="1" className="d-flex flex-column pe-1 justify-content-center">
                <div className="text-muted text-small cursor-pointer">INDEX</div>
              </Col>
            </Row>
          </Col>
        </Row>
        {/* List Header End */}
        {/* List shipment Items Start */}
        {order?.results !== null &&
          order?.results.map((shipping, index) =>
            shipping.shipping_id === 'error' ? (
              <Card className="mb-2" key={index}>
                <Row className="g-0 h-100 sh-lg-9 position-relative ">
                  <Col xs="auto" className="position-relative d-flex justify-content-center align-items-center" style={{ width: '88px' }}>
                    <div className="border border-primary sw-4 sh-4 rounded-xl d-flex justify-content-center align-items-center">
                      <CsLineIcons icon="multiply" className="text-primary" />
                    </div>
                  </Col>
                  <Col className="py-4 py-lg-0 ps-5 pe-4 h-100">
                    <Row className="g-0 h-100 align-content-center">
                      <Col xs="12" sm="10" lg="3" className="d-flex flex-column mb-lg-0 mb-1 mb-sm-2 pe-3 order-1 h-lg-100 justify-content-center">
                        <OverlayTrigger
                          delay={{ show: 0, hide: 0 }}
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-top">
                              {typeof shipping?.result === 'string' ? shipping?.result : `${shipping?.result.error.shippings}`}
                            </Tooltip>
                          }
                        >
                          <p className="text-semi-large text-primary m-0 cursor-pointer">error</p>
                        </OverlayTrigger>
                        <div className="text-small text-truncate">error</div>
                      </Col>
                      <Col xs="9" sm="12" lg="3" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-3">
                        <div className="text-muted text-small d-lg-none">Fecha / Hora</div>
                        <div className="lh-1 text-alternate">error</div>
                      </Col>
                      <Col xs="3" sm="4" lg="2" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center align-item-center order-4">
                        <div className="text-muted text-small d-lg-none">Costo</div>
                        <div className="text-alternate">
                          <span className="text-small text-muted">$</span> 0
                        </div>
                      </Col>
                      <Col xs="9" sm="4" lg="2" className="d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start justify-content-center order-5">
                        <div className="text-muted text-small d-lg-none">Peso Cobrado</div>
                        <div className="text-alternate">
                          0 <span className="text-small text-muted me-1">kg</span>
                        </div>
                      </Col>
                      <Col xs="3" sm="4" lg="1" className="d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start justify-content-center order-5">
                        <div className="text-muted text-small d-lg-none mb-1">Index</div>
                        {shipping?.index}
                      </Col>
                      <Col
                        xs="12"
                        sm="2"
                        lg="1"
                        className="d-flex flex-column mb-3 mb-sm-2 mb-lg-0 align-items-end order-2 order-lg-last justify-content-lg-center text-primary cursor-pointer"
                      >
                        <OverlayTrigger
                          delay={{ show: 0, hide: 0 }}
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-top">
                              {typeof shipping?.result === 'string'
                                ? shipping?.result
                                : `${shipping?.result.error.shippings}. Código: ${shipping?.result.error_code}`}
                            </Tooltip>
                          }
                        >
                          <div className="heading text-primary m-0 cursor-pointer">Ver error</div>
                        </OverlayTrigger>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            ) : (
              <Card className="mb-2" key={shipping?.index}>
                <Row className="g-0 h-100 sh-lg-9 position-relative">
                  <Col xs="auto" className="position-relative">
                    <NavLink to={`/envios/historial/detalle/${shipping.result?.shipping_label_id}`}>
                      <img src={shipping.result?.service_img} alt="product" className="card-img card-img-horizontal sw-11 h-100" />
                    </NavLink>
                  </Col>
                  <Col className="py-4 py-lg-0 ps-5 pe-4 h-100">
                    <Row className="g-0 h-100 align-content-center">
                      <Col xs="11" lg="3" className="d-flex flex-column mb-lg-0 mb-3 pe-3 d-flex order-1 h-lg-100 justify-content-center">
                        <NavLink to={`/envios/historial/detalle/${shipping.result?.shipping_label_id}`}>
                          {shipping.result.shipping_code?.replace(/_+/g, ' ')}
                          <div className="text-small text-muted text-truncate">{shipping.result?.shipping_label_id}</div>
                          <div className="text-small text-muted text-truncate">{shipping.result?.label_name}</div>
                        </NavLink>
                      </Col>
                      <Col lg="3" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-3">
                        <div className="lh-1 text-alternate">{moment(shipping.result?.creation_date).utcOffset('-06:00').format('DD-MM-YY HH:mm')}</div>
                      </Col>
                      <Col lg="2" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-4">
                        <div className="lh-1 text-primary">
                          <span className="text-small text-muted">$</span>
                          {formatMoney(shipping.result.shipping_rates?.total)}
                        </div>
                      </Col>
                      <Col lg="2" className="d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start justify-content-center order-5">
                        <div className="lh-1 text-alternate">
                          {`${shipping.result.parcel?.total_weight}  `}
                          <span className="text-small text-muted">kg</span>
                        </div>
                      </Col>
                      <Col xs="2" lg="1" className="d-flex flex-column pe-1 mb-2 mb-lg-0 align-items-start justify-content-center order-5">
                        <div className="text-muted text-small d-lg-none mb-1">Index</div>
                        {shipping?.index}
                      </Col>
                      <Col xs="1" lg="1" className="d-flex flex-column mb-2 mb-lg-0 align-items-end order-2 order-lg-last justify-content-lg-center">
                        <Button variant="link" onClick={() => downloadPdf(shipping.result?.shipping_label_id)}>
                          <CsLineIcons icon="download" />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Card>
            )
          )}
        {/* List shipment Items End */}
      </div>
    </>
  );
};
export default DetailOrder;

// 1. React and core dependencies
import React from 'react';
import { useSelector } from 'react-redux';

// 2. UI Components
import { Row, Col } from 'react-bootstrap';

// 3. Components
import SelectFile from 'components/form-elements/SelectFile';

const EvidenceInfo = ({ setValues, errors, setErrors }) => {
  // - Global State from Store.js form Values
  const { generalData } = useSelector((state) => state.general_data);
  return (
    <Row className={`g-3 my-5 ${generalData.fields.isVerifiedToReport ? 'd-flex' : 'd-none'}`}>
      <h2 className="small-title">Evidencia Fotográfica</h2>
      {/* <div>
        <span className="text-primary"> Importante:</span> Conserva el paquete en las condiciones como fueron recibidas para la investigación.
      </div> */}
      <div>Es de total importancia contar con las fotografías solicitadas por las paqueterías de la siguiente manera:</div>
      <div className="mt-5">
        <span className="text-primary"> Daño en el exterior: </span>
        Por favor, proporciona fotografías de las seis caras de la caja. Es fundamental que en las imágenes se vea claramente la guía adherida al paquete con el
        número de rastreo legible.
      </div>

      <Col xs="6" className="position-relative">
        <SelectFile placeholder="fotografía 1" fieldName="photograph1" formObj={setValues} setErrors={setErrors} />
        {errors.photograph1 && <div className={`invalid-tooltip ${errors.photograph1 ? 'd-block' : 'd-none'}`}>{errors.photograph1}</div>}
      </Col>
      <Col xs="6" className="position-relative">
        <SelectFile placeholder="fotografía 2" fieldName="photograph2" formObj={setValues} setErrors={setErrors} />
        {errors.photograph2 && <div className={`invalid-tooltip ${errors.photograph2 ? 'd-block' : 'd-none'}`}>{errors.photograph2}</div>}
      </Col>
      <Col xs="6" className="position-relative">
        <SelectFile placeholder="fotografía 3" fieldName="photograph3" formObj={setValues} setErrors={setErrors} />
        {errors.photograph3 && <div className={`invalid-tooltip ${errors.photograph3 ? 'd-block' : 'd-none'}`}>{errors.photograph3}</div>}
      </Col>
      <Col xs="6" className="position-relative">
        <SelectFile placeholder="fotografía 4" fieldName="photograph4" formObj={setValues} setErrors={setErrors} />
        {errors.photograph4 && <div className={`invalid-tooltip ${errors.photograph4 ? 'd-block' : 'd-none'}`}>{errors.photograph4}</div>}
      </Col>
      <Col xs="6" className="position-relative">
        <SelectFile placeholder="fotografía 5" fieldName="photograph5" formObj={setValues} setErrors={setErrors} />
        {errors.photograph5 && <div className={`invalid-tooltip ${errors.photograph5 ? 'd-block' : 'd-none'}`}>{errors.photograph5}</div>}
      </Col>
      <Col xs="6" className="position-relative">
        <SelectFile placeholder="fotografía 6" fieldName="photograph6" formObj={setValues} setErrors={setErrors} />
        {errors.photograph6 && <div className={`invalid-tooltip ${errors.photograph6 ? 'd-block' : 'd-none'}`}>{errors.photograph6}</div>}
      </Col>
      <div className="mt-5 pt-5">
        <span className="text-primary"> Protección interna: </span>1 fotografía donde se aprecien los artículos usados como empaque interno tales como papel,
        burbuja, unicel, cartón u otros materiales descritos en el cuestionario.
      </div>
      <Col sm="9" className="position-relative">
        <SelectFile placeholder="Sube o arrastra tu fotografía" fieldName="protectionPhoto" formObj={setValues} setErrors={setErrors} />
        {errors.protectionPhoto && <div className={`invalid-tooltip ${errors.protectionPhoto ? 'd-block' : 'd-none'}`}>{errors.protectionPhoto}</div>}
      </Col>
      <div className="mt-5 pt-5">
        <span className="text-primary"> Artículo dañado: </span> 1 fotografía donde se pueda visualizar la afectación y en donde también pueda apreciarse el
        artículo completo. De ser necesario puede agregar además acercamientos a las anomalías presentadas. Por favor, considera que, si el daño se presenta en
        más de una pieza, requerimos una imagen de todos los artículos dañados también con visibilidad completa.
      </div>

      <Col sm="9" className="position-relative">
        <SelectFile placeholder="Sube o arrastra tu fotografía" fieldName="contentPhoto" formObj={setValues} setErrors={setErrors} />
        {errors.contentPhoto && <div className={`invalid-tooltip ${errors.contentPhoto ? 'd-block' : 'd-none'}`}>{errors.contentPhoto}</div>}
      </Col>
      <div className="mt-5 pt-5">
        <span className="text-primary"> Importante:</span> Conserva el paquete en las mismas condiciones en que fue recibido para facilitar el proceso de
        investigación.
      </div>
    </Row>
  );
  //   {/* <SelectMultipleFile
  //   label="Subir fotografías de evidencia"
  //   placeholder="Sube o arrastra fotografías"
  //   isRequired="true"
  //   fieldName="photographsOk"
  //   formObj={setValues}
  //   setErrors={setErrors}
  //   values={values}
  //   arrFieldName={['photograph1', 'photograph2', 'photograph3', 'photograph4', 'photograph5', 'photograph6']}
  //   helpText="Fotografías de las 6 caras de la caja donde se vea el número de guía, la protección utilizada como burbujas, unicel, cartón, etc., la afectación y el artículo completo. Conserva el paquete en las condiciones en que fue recibido."
  // /> */}
};

export default EvidenceInfo;

import React, { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { Application, FileSelector, DatePicker } from 'react-rainbow-components';
import { useSelector } from 'react-redux';
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
// utils
import { handleAPIError, handleCatchError } from 'utils/handleErrors';
// api
import { ToastSuccess, ToastErrorCath } from 'components/alerts/toast';
import { createNotice, updateNotice } from '../../../api/Api-admin';

const NotificationModal = ({ isModalOpen, setIsModalOpen, notification, setNotificationSelected, setTrigger, trigger }) => {
  const currentDate = new Date();

  const { color } = useSelector((state) => state.settings);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [startDate, setStartDate] = useState(''); // to request
  const [endDate, setEndDate] = useState(''); // to request
  const [selectStartDate, setSelectStartDate] = useState({ date: undefined }); // for view
  const [selectEndDate, setSelectEndDate] = useState({ date: undefined }); // for view
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const theme = {
    rainbow: {
      palette: {
        brand: '#850000',
      },
    },
  };
  // Theme for dark mode
  const darkTheme = {
    rainbow: {
      palette: {
        brand: '#F0F0F0', // Cambia los colores del tema aquí
        mainBackground: '#1d1d1d',
      },
    },
  };
  // Detectar modo edición y establecer los valores iniciales
  useEffect(() => {
    if (isModalOpen) {
      // console.log('notification en el modal:', notification);
      if (notification) {
        setTitle(notification.title || '');
        setBody(notification.message_body || '');
        setStartDate(moment(notification.validity_start).format('YYYY-MM-DD') || '');
        setEndDate(moment(notification.validity_end).format('YYYY-MM-DD') || '');
        setSelectStartDate({ date: notification.validity_start ? new Date(notification.validity_start) : undefined });
        setSelectEndDate({ date: notification.validity_end ? new Date(notification.validity_end) : undefined });
        setFile(notification.img || null); // Establecer el archivo actual si existe
      } else {
        setTitle('');
        setBody('');
        setStartDate('');
        setEndDate('');
        setSelectStartDate({ date: undefined });
        setSelectEndDate({ date: undefined });
        setFile(null);
      }
    }
  }, [notification, isModalOpen]);

  const closeModal = () => {
    setTitle('');
    setBody('');
    setStartDate('');
    setEndDate('');
    setSelectStartDate({ date: undefined });
    setSelectEndDate({ date: undefined });
    setIsModalOpen(false);
    setNotificationSelected(false);
  };
  const handleCreateNotice = async () => {
    // if (
    //   // !file || // Si no hay archivo
    //   !file[0] || // Si el archivo está vacío
    //   !title.trim() || // Si el título es vacío o solo tiene espacios
    //   !startDate?.date || // Si no hay fecha de inicio válida
    //   !endDate?.date // Si no hay fecha de fin válida
    // ) {
    //   ToastErrorCath('Error: Por fa, revisa los campos que son requeridos.');
    //   return; // Terminar la ejecución si falta algún dato requerido
    // }
    try {
      setIsBtnLoading(true);
      const formData = new FormData();
      formData.append('img', file[0]);
      formData.append('title', title);
      formData.append('message_body', body);
      formData.append('validity_start', startDate);
      formData.append('validity_end', endDate);
      const response = await createNotice(formData);
      // console.log('handleCreateNotice res:', response);
      if (response.success !== undefined) {
        ToastSuccess('Notificación creada con éxito ✨');
        setTrigger(!trigger);
        closeModal();
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsBtnLoading(false);
    }
  };
  const handleUpdateNotice = async () => {
    // if (!title || !startDate.date || !endDate.date) {
    //   ToastErrorCath('Error: Por fa, revisa los campos que son requeridos.');
    //   return; // Terminar la ejecución si no hay archivo
    // }
    try {
      setIsBtnLoading(true);
      const formData = new FormData();
      // Agregar el archivo solo si es un nuevo archivo (no una URL/string)
      if (file && typeof file !== 'string') {
        formData.append('img', file[0]); // Agregar el nuevo archivo
      }
      formData.append('title', title);
      formData.append('message_body', body);
      formData.append('validity_start', startDate);
      formData.append('validity_end', endDate);
      const response = await updateNotice(notification.id, formData);
      // console.log('handleUpdateNotice res:', response);
      if (response.success !== undefined) {
        ToastSuccess('Notificación editada con éxito ✨');
        setTrigger(!trigger);
        closeModal();
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsBtnLoading(false);
    }
  };
  const handleSave = () => {
    // if (!title || !startDate || !endDate) {
    //   ToastErrorCath('Error: Por favor, revisa los campos requeridos.');
    //   return;
    // }

    if (notification) {
      // Modo edición
      // console.log('Actualizar notificación:', notification.id);
      handleUpdateNotice();
    } else {
      // Modo creación
      // console.log('Crear nueva notificación:');
      handleCreateNotice();
    }
  };
  useEffect(() => {
    return () => {
      closeModal();
    };
  }, []);
  return (
    <Modal show={isModalOpen} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title> {`${notification ? 'Editar' : 'Nueva'} notificación`}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-infox">
        <Form.Control
          type="text"
          className="mb-4"
          // maxLength={maxLength}
          placeholder="Titulo (requerido)"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
        <Form.Control
          as="textarea"
          className="mb-4"
          rows={5} // Número de líneas visibles
          placeholder="Escribe aquí el cuerpo del mensaje (opcional)"
          value={body} // Estado que contiene el texto
          onChange={(e) => {
            setBody(e.target.value); // Actualiza el estado al escribir
          }}
        />
        <Application theme={color === 'dark-red' ? darkTheme : theme}>
          <FileSelector
            className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto p-0 mb-4"
            // style={containerStyles}
            // label="Subir imagen"
            placeholder={`Sube o arrastra el ${notification ? 'nuevo' : ''} archivo`}
            bottomHelpText="archivo jpeg, jpg o webp (requerido)"
            onChange={setFile}
            borderRadius="semi-rounded"
          />
          <Form.Label>Fecha en que la notificación será visible</Form.Label>

          <div className="d-flex bg-primaryx">
            <DatePicker
              className="datepicker rainbow-p-around_medium p-0 pe-2 text-alternate"
              borderRadius="semi-rounded"
              placeholder="desde (requerido)"
              value={selectStartDate.date}
              minDate={currentDate}
              onChange={(value) => {
                if (value) {
                  setSelectStartDate({ ...selectStartDate, date: value });
                  setStartDate(moment(value).format('YYYY-MM-DD'));
                }
              }}
            />
            <DatePicker
              className="datepicker rainbow-p-around_medium p-0 pt-0 text-alternate"
              borderRadius="semi-rounded"
              placeholder="hasta (requerido)"
              value={selectEndDate.date}
              minDate={currentDate}
              onChange={(value) => {
                if (value) {
                  setSelectEndDate({ ...selectEndDate, date: value });
                  setEndDate(moment(value).format('YYYY-MM-DD'));
                }
              }}
            />
          </div>
        </Application>
      </Modal.Body>
      <Modal.Footer>
        <Button size="lg" variant="primary" onClick={handleSave} disabled={isBtnLoading}>
          {isBtnLoading && <ClipLoader color="#FFFFFF" loading={isBtnLoading} size={10} />}
          {isBtnLoading && 'Cargando'}
          {!isBtnLoading && notification && 'Actualizar'}
          {!isBtnLoading && !notification && 'Crear'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NotificationModal;

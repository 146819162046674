import { DEFAULT_PATHS } from 'config.js';
import Dashboard from 'views/dashboard/Dashboard';

// ENVIAR
import verifyAccount from 'views/default/VerifyAccount';
import ShippingForm from 'views/shipments/shipping/ShippingForm';
import Services from 'views/shipments/shipping/Services';
import Documentation from 'views/shipments/shipping/Documentation';
import Quote from 'views/shipments/quote/Quote';
import History from 'views/shipments/history/History';
import Detail from 'views/shipments/history/Detail';
import Pickup from 'views/shipments/pickup/Pickup';
import NewPickup from 'views/shipments/pickup/components/NewPickup';
import Orders from 'views/shipments/order/Orders';
import DetailOrder from 'views/shipments/order/DetailOrder';

// FINANZAS
import Statements from 'views/finances/statements/Statements';
import Overweight from 'views/finances/overweights/Overweight';
import Billing from 'views/finances/billing/Billing';
// AJUSTES
import Addresses from 'views/settings/addresses/Addresses';
import Parcels from 'views/settings/parcels/Parcels';
import EditAddress from 'views/settings/addresses/components/EditAddress';
import NewAddress from 'views/settings/addresses/components/NewAddress';
import NewParcel from 'views/settings/parcels/components/NewParcel';
// SOPORTE
import Reports from 'views/support/Reports';
import NewReport from 'views/support/NewReport';
import reportDetail from 'views/support/ReportDetail';
// INFO
import Surcharges from 'views/additional/surcharges/Surcharges';
import Packaging from 'views/additional/packaging/Packaging';
// SETTINGS
import AvailableServices from 'views/settings/services/AvailableServices';

// ADMIN IMPORTS
import AdminDashboard from 'views-admin/views/dashboard/adminDashboard';

import Shippings from 'views-admin/views/shippings/Shippings';
import ShippingDetail from 'views-admin/views/shippings/ShippingDetail';
import AdminUserRegister from 'views-admin/views/newUser/AdminUserRegister';

import Users from './views-admin/views/users/Users';
import UserDetail from './views-admin/views/users/UserDetail';
import BillingAdmin from './views-admin/views/users/components/finance/BillingAdmin';
import ShipmentHistory from './views-admin/views/users/components/movements/shippings/History';
import OverweightHistory from './views-admin/views/users/components/movements/overweight/History';
import Cancellations from './views-admin/views/cancellations/Cancellations';

import StatementHistory from './views-admin/views/users/components/movements/statement/History';
import OrderOverweight from './views-admin/views/overweight/OrderOverweight';
import OrderDetail from './views-admin/views/overweight/OrderDetail';
import Tariff from './views-admin/views/rates/Tariff';

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;
const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: '/',
      exact: true,
      redirect: true,
      roles: ['user'],
      to: 'inicio',
    },
    {
      path: `${appRoot}/inicio`,
      roles: ['user'],
      component: Dashboard,
      label: 'Inicio',
      icon: 'home',
    },
    {
      path: `${appRoot}/verificacion`,
      roles: ['user'],
      hideInMenu: true,
      component: verifyAccount,
      label: 'Activar Cuenta',
      icon: 'online-class',
    },
    {
      path: `${appRoot}/envios`,
      exact: true,
      redirect: true,
      to: `${appRoot}/envios/enviar`,
      label: 'Envios',
      icon: 'shipping',
      roles: ['user'],
      subs: [
        {
          path: '/enviar',
          label: 'Enviar',
          component: ShippingForm,
          icon: 'plane',
          roles: ['user'],
          subs: [
            { path: '/servicios', component: Services, hideInMenu: true },
            { path: '/documentacion', component: Documentation, hideInMenu: true },
          ],
        },
        {
          path: '/recolectar',
          label: 'Recolectar',
          component: Pickup,
          icon: 'destination',
          roles: ['user'],
          subs: [{ path: '/programar', component: NewPickup, hideInMenu: true }],
        },
        { path: '/cotizar', label: 'Cotizar', component: Quote, icon: 'tag', roles: ['user'] },
        {
          path: '/historial',
          label: 'Historial',
          component: History,
          icon: 'book',
          roles: ['user'],
          subs: [{ path: '/detalle/:labelId', component: Detail, hideInMenu: true }],
        },
        {
          path: '/ordenes',
          label: 'Ordenes',
          component: Orders,
          icon: 'boxes',
          roles: ['user'],
          subs: [{ path: '/detalle/:orderId', component: DetailOrder, hideInMenu: true }],
        },
      ],
    },
    {
      path: `${appRoot}/finanzas`,
      exact: true,
      redirect: true,
      to: `${appRoot}/finanzas/movimientos`,
      label: 'Finanzas',
      icon: 'dollar',
      roles: ['user'],
      subs: [
        { path: '/movimientos', label: 'Movimientos', component: Statements, icon: 'inbox', roles: ['user'] },
        { path: '/sobrepesos', label: 'Sobrepesos', component: Overweight, icon: 'boxes', roles: ['user'] },
        { path: '/facturacion', label: 'Facturacion', component: Billing, icon: 'invoice', roles: ['user'] },
      ],
    },
    {
      path: `${appRoot}/reportes`,
      exact: true,
      redirect: true,
      to: `${appRoot}/reportes`,
      label: 'Reportes',
      icon: 'support',
      roles: ['user'],
      subs: [
        { path: '/historial', label: 'Historial', component: Reports, icon: 'book', roles: ['user'] },
        { path: '/crear-reporte', component: NewReport, hideInMenu: true, label: 'Nuevo Reporte', icon: 'warning-hexagon', roles: ['user'] },
        { path: '/detalle/:ticketId', component: reportDetail, hideInMenu: true },
      ],
    },
    {
      path: `${appRoot}/información`,
      exact: true,
      redirect: true,
      to: `${appRoot}/información/embalajes`,
      label: 'Información',
      icon: 'book-open',
      roles: ['user'],
      subs: [
        { path: '/embalajes', label: 'Embalajes', component: Packaging, icon: 'archive', roles: ['user'] },
        { path: '/recargos', label: 'Recargos', component: Surcharges, icon: 'money', roles: ['user'] },
      ],
    },
    {
      path: `${appRoot}/configuración`,
      exact: true,
      redirect: true,
      to: `${appRoot}/configuración/servicios`,
      label: 'Configuración',
      icon: 'gear',
      roles: ['user'],
      subs: [
        { path: '/servicios', label: 'Servicios', component: AvailableServices, icon: 'list', roles: ['user'] },
        {
          path: '/direcciones',
          label: 'Direcciones',
          component: Addresses,
          icon: 'pin',
          roles: ['user'],
          subs: [
            { path: '/editar/:addressId', component: EditAddress, hideInMenu: true },
            { path: '/agregar', component: NewAddress, hideInMenu: true },
          ],
        },
        {
          path: '/paquetes',
          label: 'Paquetes',
          component: Parcels,
          icon: 'box',
          roles: ['user'],
          subs: [{ path: '/agregar', component: NewParcel, hideInMenu: true }],
        },
      ],
    },
    {
      path: `${appRoot}/admin-dashboard`,
      roles: ['Control Alto', 'Control Total', 'Control Medio'],
      component: AdminDashboard,
      label: 'Inicio',
      icon: 'home',
    },
    {
      path: `${appRoot}/users-all`,
      // roles: ['admin'],
      roles: ['Control Alto', 'Control Total', 'Control Medio'],
      component: Users,
      label: 'Usuarios',
      icon: 'user',
      subs: [
        { path: '/detail/:crmUserId', component: UserDetail, hideInMenu: true },
        { path: '/billings/:crmUserId/:userName', component: BillingAdmin, hideInMenu: true },
        { path: '/shippings/:crmUserId/:userName', component: ShipmentHistory, hideInMenu: true },
        { path: '/overweights/:crmUserId/:userName', component: OverweightHistory, hideInMenu: true },
        { path: '/statements/:crmUserId/:userName', component: StatementHistory, hideInMenu: true },
      ],
    },
    {
      path: `${appRoot}/shipping-all`,
      roles: ['admin', 'Control Alto', 'Control Total', 'Control Medio'],
      component: Shippings,
      label: 'Envios',
      icon: 'plane',
      subs: [{ path: '/detail/:labelId/:userName/:crmId', component: ShippingDetail, hideInMenu: true }],
    },
    {
      path: `${appRoot}/overweight`,
      roles: ['Control Total'],
      component: OrderOverweight,
      label: 'Sobrepesos',
      icon: 'warning-hexagon',
      subs: [{ path: '/detail/:orderId', component: OrderDetail, hideInMenu: true }],
    },
    {
      path: `${appRoot}/cancellations`,
      roles: ['Control Total'],
      component: Cancellations,
      label: 'Cancelaciones',
      icon: 'error-hexagon',
    },
    {
      path: `${appRoot}/tarifario`,
      roles: ['Control Alto', 'Control Total', 'Control Medio'],
      component: Tariff,
      label: 'Tarifario',
      icon: 'dollar',
    },
    {
      path: `${appRoot}/new-user`,
      roles: ['Control Total'],
      component: AdminUserRegister,
      label: 'Nuevo Usuario',
      icon: 'online-class',
    },
  ],
  sidebarItems: [],
};
export default routesAndMenuItems;

import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import moment from 'moment';

const Item = ({ data = {} }) => {
  return (
    <>
      <Row className="g-0">
        <Col xs="auto" className="sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-4">
          <div className="w-100 d-flex sh-1" />
          <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
            <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
          </div>
          <div className="w-100 d-flex h-100 justify-content-center position-relative">
            <div className="line-w-1 bg-separator h-100 position-absolute" />
          </div>
        </Col>
        <Col className="mb-4">
          <div className="h-100">
            <div className="d-flex flex-column justify-content-start">
              <div className="d-flex flex-column text-primary">
                {data.tracking_status}
                <div className="text-alternate">{moment(data.time).format('DD-MM-YYYY HH:mm:ss')}</div>
                <div className="text-muted mt-1">{data.description}</div>
                <div className="text-muted mt-1">{data.location.toUpperCase()}</div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

function CardTracking({ tracking = [] }) {
  return (
    <>
      <h2 className="small-title">Rastreo</h2>
      <Card className="mb-5 scroll-container" style={{ maxHeight: '500px', overflow: 'auto' }}>
        <Card.Body>
          {tracking.map((item, index) => (
            <Item key={index} data={item} />
          ))}
        </Card.Body>
      </Card>
    </>
  );
}

export default CardTracking;

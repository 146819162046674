// 1. React and core dependencies
import React, { useEffect, useState, useRef, useCallback } from 'react';

// 2. Third-party libraries
import { motion, AnimatePresence } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Row, ListGroup, ListGroupItem, InputGroup, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

// utils
import { handleAPIError, handleCatchError } from 'utils/handleErrors';

// 6. Custom components
import InputText from 'components/form-elements/InputText2';
import ButtonRequest from 'components/buttons/ButtonRequest';
import { ToastSuccess } from 'components/alerts/toast';

// 7. Redux reducers/actions
import { setInsuranceData, clearInsuranceForm, setInsuranceDataError } from 'slices/shipping/insuranceDataSlice';
import { setParcelData, setParcelDataError } from 'slices/shipping/parcelDataSlice';
import { setInsuranceCategories, setPolicyBeneficiaries } from 'slices/generalData/generalData';

// 8. API calls
import { getCommodities, getPolicyBeneficiaries, createPolicyBeneficiary, editPolicyBeneficiary, deletePolicyBeneficiary } from 'api/Api-user';

const BeneficiaryModal = ({ isModalOpen, setIsModalOpen, data = {}, setData = () => {}, updateBeneficiariesList = () => {}, title = '' }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const handleButtonAction = () => {
    setIsButtonDisabled(true); // disable button
    setIsLoader(true); // active loader in the button
    // Rehabilitar el botón después de 3 segundos
    setTimeout(() => setIsButtonDisabled(false), 3000);
  };
  const handlerCreateBeneficiary = async () => {
    try {
      handleButtonAction();
      const response = await createPolicyBeneficiary(data);
      // console.log('create beneficiary response:', response);
      if (response.name !== undefined) {
        ToastSuccess('Beneficiario agregado correctamente.');
        updateBeneficiariesList(); // Actualiza la lista de beneficiarios
        setData({ name: '', rfc: '' });
        setIsModalOpen(false);
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoader(false);
    }
  };
  const handlerEditBeneficiary = async () => {
    try {
      handleButtonAction();
      const response = await editPolicyBeneficiary(data);
      // console.log('edit beneficiary response:', response);
      if (response.name !== undefined) {
        ToastSuccess('El beneficiario fue editado correctamente.');
        updateBeneficiariesList(); // Actualiza la lista de beneficiarios
        setData({ name: '', rfc: '' });
        setIsModalOpen(false);
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoader(false);
    }
  };
  return (
    <Modal
      show={isModalOpen}
      onHide={() => {
        setIsModalOpen(false);
        setData({ name: '', rfc: '' });
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h5">{`${title} Beneficiario`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-4">
          <InputText
            label="Nombre completo del beneficiario"
            icon="user"
            // placeholder=''
            value={data.name}
            isRequired
            fieldName="name"
            formObj={setData}
            // setErrors={setErrors}
          />
          <span className="text-medium">El nombre del beneficiario debe aparecer en la factura del producto.</span>
        </div>

        <InputText
          label="RFC del beneficiario"
          icon="invoice"
          // placeholder=''
          value={data.rfc}
          isRequired
          fieldName="rfc"
          formObj={setData}
          // setErrors={setErrors}
        />
        <span className="text-medium">El RFC es obligatorio para que la póliza sea válida. No uses un RFC genérico, ya que esto anulará la cobertura.</span>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end ">
          <ButtonRequest
            title={title}
            titleLoading={title === 'Editar' ? 'Editando' : 'Guardando'}
            handlerFn={title === 'Editar' ? handlerEditBeneficiary : handlerCreateBeneficiary}
            isButtonDisabled={isButtonDisabled}
            isLoading={isLoader}
          />
          <Button
            variant="outline-primary"
            style={{ height: '36px' }}
            onClick={() => {
              setIsModalOpen(false);
              setData({ name: '', rfc: '' });
            }}
          >
            Cancelar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
const DeleteModal = ({ isModalOpen = false, setIsModalOpen = () => {}, beneficiary = {}, updateBeneficiariesList = () => {} }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const handleButtonAction = () => {
    setIsButtonDisabled(true); // disable button
    setIsLoader(true); // active loader in the button
    // Rehabilitar el botón después de 3 segundos
    setTimeout(() => setIsButtonDisabled(false), 3000);
  };
  // - Delete beneficiary
  const handlerDeleteBeneficiary = async () => {
    try {
      handleButtonAction();
      const response = await deletePolicyBeneficiary(beneficiary?.id);
      // console.log('response delete:', response);
      if (response.message !== undefined) {
        ToastSuccess('El beneficiario fue eliminado correctamente.');
        updateBeneficiariesList(); // Actualiza la lista de beneficiarios
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsModalOpen(false);
      setIsLoader(false);
    }
  };
  return (
    <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">Eliminar Beneficiario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <h4>Eliminar beneficiario</h4> */}
        <h5>
          {beneficiary?.name}
          <div>{`RFC: ${beneficiary?.rfc}`}</div>
        </h5>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-end ">
          <ButtonRequest
            title="Eliminar"
            titleLoading="Eliminando"
            handlerFn={handlerDeleteBeneficiary}
            isButtonDisabled={isButtonDisabled}
            isLoading={isLoader}
          />
          <Button variant="outline-primary" style={{ height: '36px' }} onClick={() => setIsModalOpen(false)}>
            Cancelar
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
function InsuranceToggle() {
  // - Start insurance form
  const [isProductStateActive, setIsProductStateActive] = useState(false);
  const [isShowInsuranceCategories, setIsShowInsuranceCategories] = useState(false);
  const [commodities, setCommodities] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  // - End insurance form
  // - Start Policy Beneficiaries
  const [isShowBeneficiaries, setIsShowBeneficiaries] = useState(false);
  const [beneficiarySearch, setBeneficiarySearch] = useState('');
  const [beneficiariesList, setBeneficiariesList] = useState([]);
  const [beneficiaryData, setBeneficiaryData] = useState({ name: '', rfc: '' });

  // const [beneficiary, setBeneficiary] = useState({ name: 'Viviana gomez', rfc: 'GOPV9205298X7' });
  // - End Policy Beneficiaries
  const [isBeneficiaryModalOpen, setIsBeneficiaryModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [operationType, setOperationType] = useState('');

  // - Global states
  const { parcelData } = useSelector((state) => state.shipping_parcel);
  const { insuranceData } = useSelector((state) => state.shipping_insurance);
  const { generalData } = useSelector((state) => state.general_data);
  const dispatch = useDispatch();
  const hasSetCategories = useRef(false);
  // - remove accents
  const cleanData = (str) => {
    if (typeof str !== 'string') return str; // Si no es string, devolver tal cual

    // Reemplazo manual de vocales acentuadas y cedilla
    const accentsMap = {
      á: 'a',
      é: 'e',
      í: 'i',
      ó: 'o',
      ú: 'u',
      Á: 'A',
      É: 'E',
      Í: 'I',
      Ó: 'O',
      Ú: 'U',
      ä: 'a',
      ë: 'e',
      ï: 'i',
      ö: 'o',
      ü: 'u',
      Ä: 'A',
      Ë: 'E',
      Ï: 'I',
      Ö: 'O',
      Ü: 'U',
      ã: 'a',
      õ: 'o',
      ñ: 'ñ',
      Ñ: 'Ñ',
      ç: 'c',
      Ç: 'C',
    };
    return str
      .split('')
      .map((char) => accentsMap[char] || char) // Reemplaza acentos manualmente
      .join('')
      .replace(/[^a-zA-Z0-9ñÑ ]/g, ''); // Elimina caracteres especiales, dejando la ñ
  };
  // - Data Validation and Updating States with Reducers
  const updateField = (reducer, data, point, max) => {
    if (
      point === 'phone' ||
      point === 'zip' ||
      point === 'weight' ||
      point === 'height' ||
      point === 'width' ||
      point === 'depth' ||
      point === 'product_value_shipping'
    ) {
      // save numbers only
      if (data === '' || /^[0-9]+$/i.test(data)) dispatch(reducer({ data, point }));
    } else {
      // save string
      // console.log('Data que da error:', data, 'tipo', typeof data, 'campo:', point);
      // eslint-disable-next-line no-lonely-if
      if (typeof data === 'string') {
        const cleanedData = cleanData(data).substring(0, max);
        dispatch(reducer({ data: cleanedData, point }));
      }
    }
  };
  const filterCategories = (searchTerm, list) => {
    if (!list) return [];
    const trimmedTerm = searchTerm.trim().toLowerCase();
    return trimmedTerm === '' ? list : list.filter((item) => item.name.toLowerCase().includes(trimmedTerm));
  };
  const filterBeneficiaries = (searchTerm, list, key = 'name') => {
    if (!list) return [];
    const trimmedTerm = searchTerm.trim().toUpperCase();
    return trimmedTerm === '' ? list : list.filter((item) => item[key].toUpperCase().includes(trimmedTerm));
  };
  // - Get Beneficiaries list
  const handlerGetPolicyBeneficiaries = useCallback(() => {
    const storedBeneficiaries = localStorage.getItem('policyBeneficiaries');
    if (storedBeneficiaries) {
      dispatch(setPolicyBeneficiaries({ data: JSON.parse(storedBeneficiaries) }));
    } else {
      getPolicyBeneficiaries().then((response) => {
        if (response.beneficiary !== undefined) {
          const formattedBeneficiaries = response.beneficiary.map((item) => ({
            ...item,
            name: item.name.toUpperCase(), // Normaliza el nombre
          }));

          localStorage.setItem('policyBeneficiaries', JSON.stringify(formattedBeneficiaries));
          dispatch(setPolicyBeneficiaries({ data: formattedBeneficiaries }));
        }
      });
    }
  }, [dispatch]);
  // - Get insurance categories
  const handlerGetInsuranceCategories = useCallback(() => {
    if (!hasSetCategories.current) {
      const storedCategories = localStorage.getItem('insuranceCategories');
      if (storedCategories) {
        dispatch(setInsuranceCategories({ data: JSON.parse(storedCategories) }));
      } else {
        getCommodities().then((response) => {
          if (response?.insurance_categories) {
            localStorage.setItem('insuranceCategories', JSON.stringify(response.insurance_categories));
            dispatch(setInsuranceCategories({ data: response.insurance_categories }));
          }
        });
      }
      hasSetCategories.current = true;
    }
  }, [dispatch]);
  // - Update Policy Beneficiaries List
  const updatePolicyBeneficiary = async () => {
    try {
      // 1. Borrar el localStorage para forzar una nueva petición
      localStorage.removeItem('policyBeneficiaries');

      // 2. Hacer la petición a la API
      const response = await getPolicyBeneficiaries();

      if (response.beneficiary !== undefined) {
        const formattedBeneficiaries = response.beneficiary.map((item) => ({
          ...item,
          name: item.name.toUpperCase(),
        }));

        localStorage.setItem('policyBeneficiaries', JSON.stringify(formattedBeneficiaries));
        dispatch(setPolicyBeneficiaries({ data: formattedBeneficiaries }));
      }
    } catch (error) {
      console.error('Error al obtener beneficiarios:', error);
    }
  };
  useEffect(() => {
    handlerGetInsuranceCategories();
  }, [handlerGetInsuranceCategories]);

  useEffect(() => {
    handlerGetPolicyBeneficiaries();
  }, [handlerGetPolicyBeneficiaries]);
  // - filter insurance categories
  useEffect(() => {
    setCategoryList(filterCategories(commodities, generalData.fields.insuranceCategories));
  }, [commodities, generalData.fields.insuranceCategories]);
  useEffect(() => {
    setBeneficiariesList(filterBeneficiaries(beneficiarySearch, generalData.fields.policyBeneficiaries));
  }, [beneficiarySearch, generalData.fields.policyBeneficiaries]);
  // - clean insurance inputs
  useEffect(() => {
    if (!insuranceData.fields.isInsurance) {
      //  dispatch(setParcelData({ data: '', point: 'product_value' }));
      dispatch(setParcelDataError({ point: 'product_value', data: false }));
      dispatch(clearInsuranceForm());
      setIsShowInsuranceCategories(false);
      setIsProductStateActive(false);
      setCommodities('');
    }
  }, [insuranceData.fields.isInsurance, dispatch]);
  // for testing
  // useEffect(() => {
  //   console.log('datos para el seguro:', insuranceData);
  // }, [insuranceData]);
  // useEffect(() => {
  //   console.log('data:', beneficiaryData);
  // }, [beneficiaryData]);
  return (
    <motion.div
      key="insurance-form"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5 }}
      className="bg-infox"
    >
      {/* - Start  Create/Edit Modal */}
      <BeneficiaryModal
        isModalOpen={isBeneficiaryModalOpen}
        setIsModalOpen={setIsBeneficiaryModalOpen}
        data={beneficiaryData}
        setData={setBeneficiaryData}
        updateBeneficiariesList={updatePolicyBeneficiary}
        title={operationType}
      />
      {/*  - End  Create/Edit Modal */}
      {/* - Start Delete Modal */}
      <DeleteModal
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
        beneficiary={beneficiaryData}
        updateBeneficiariesList={updatePolicyBeneficiary}
      />
      {/* - End Delete Modal */}
      <Row>
        <hr className="text-alternate" />
        <label className="form-label bg-successx mb-4">
          Completa los siguientes campos con información correcta y precisa para asegurar la cobertura de tu póliza.
        </label>

        <Col sm="6" lg="12" xl="6" className="position-relative pe-2 mb-4">
          <Form.Label>Estado del contenido</Form.Label>
          <span className="text-primary">*</span>
          <InputGroup>
            <Form.Control type="text" placeholder="Selecciona una opción" value={insuranceData.fields.productState} readOnly />
            <InputGroup.Text
              className="cursor-pointer"
              onClick={() => {
                setIsProductStateActive(!isProductStateActive);
                dispatch(setInsuranceDataError({ data: false, point: 'productState' }));
              }}
            >
              <CsLineIcons icon="chevron-bottom" size="16" className="text-primary" />
            </InputGroup.Text>
          </InputGroup>
          <AnimatePresence>
            {isProductStateActive && (
              <motion.div
                key="insurance-form"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="mb-5"
                style={{
                  position: 'absolute',
                  zIndex: 1000,
                  // maxHeight: '300px',
                  width: '50%',
                  overflowY: 'scroll',
                  cursor: 'pointer',
                  fontFamily: 'Montserrat',
                  borderRadius: '10px',
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                }}
              >
                <ListGroup>
                  <ListGroupItem
                    className="p-2 ps-0 rounded-0 list-group-item-hover"
                    onClick={() => {
                      dispatch(setInsuranceData({ data: 'Usado', point: 'productState' }));
                      dispatch(setInsuranceData({ data: false, point: 'isProductNew' }));
                      dispatch(setInsuranceDataError({ data: false, point: 'productState' }));
                      setIsProductStateActive(false);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      {/* <CsLineIcons icon="box" size="16" className="text-primary" /> */}
                      <div className="ms-3">Usado</div>
                    </div>
                  </ListGroupItem>
                  <ListGroupItem
                    className="p-2 ps-0 rounded-0 list-group-item-hover"
                    onClick={() => {
                      dispatch(setInsuranceData({ data: true, point: 'isProductNew' }));
                      dispatch(setInsuranceData({ data: 'Nuevo', point: 'productState' }));
                      dispatch(setInsuranceDataError({ data: false, point: 'productState' }));
                      setIsProductStateActive(false);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="ms-3">Nuevo</div>
                    </div>
                  </ListGroupItem>
                </ListGroup>
              </motion.div>
            )}
          </AnimatePresence>
          {insuranceData.errors.productState && (
            <div className={`invalid-tooltip ${insuranceData.errors.productState ? 'd-block' : 'd-none'}`}>{insuranceData.errors.productState}</div>
          )}
        </Col>

        <Col xs="12" className="position-relative mb-4">
          <Form.Label>
            Nombre del beneficiario
            <span className="text-primary">*</span>
            <OverlayTrigger
              delay={{ show: 500, hide: 0 }}
              placement="top"
              overlay={<Tooltip id="tooltip-top">El nombre del beneficiario debe aparecer en la factura del producto.</Tooltip>}
            >
              <span className="cursor-pointer ps-1">
                <CsLineIcons icon="info-hexagon" size="20" className="text-light-darker mb-1" />
              </span>
            </OverlayTrigger>
          </Form.Label>
          <InputGroup>
            <Form.Control type="text" placeholder="Selecciona un beneficiario" value={insuranceData.fields.beneficiaryName} readOnly />
            <InputGroup.Text
              className="cursor-pointer"
              onClick={() => {
                setBeneficiarySearch('');
                setIsShowBeneficiaries(!isShowBeneficiaries);
                dispatch(setInsuranceDataError({ data: false, point: 'beneficiaryName' }));
              }}
            >
              <CsLineIcons icon="chevron-bottom" size="16" className="text-primary" />
            </InputGroup.Text>
          </InputGroup>
          <AnimatePresence>
            {isShowBeneficiaries && (
              <motion.div
                key="insurance-form"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="scroll-container"
                style={{
                  position: 'absolute',
                  zIndex: 1000,
                  // minHeight: '200px',
                  maxHeight: '300px',
                  width: '96%',
                  overflowY: 'scroll',
                  cursor: 'pointer',
                  fontFamily: 'Montserrat',
                  borderRadius: '10px',
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                }}
              >
                <ListGroup>
                  {generalData.fields.policyBeneficiaries.length > 0 ? (
                    <>
                      <ListGroupItem className="px-3 py-2">
                        <div className="text-end mt-3">
                          <Button
                            variant="outline-primary"
                            className="w-auto btn btn-icon btn-icon-start ms-0 ms-sm-1"
                            onClick={() => {
                              setIsBeneficiaryModalOpen(true);
                              setOperationType('Guardar');
                            }}
                          >
                            <CsLineIcons icon="plus" />
                            <span className="ps-2">Agregar beneficiario</span>
                          </Button>
                        </div>
                        {generalData.fields.policyBeneficiaries.length >= 5 && (
                          <div className="mt-2 filled form-group tooltip-end-top">
                            <CsLineIcons icon="search" />
                            <Form.Control
                              type="text"
                              name="searh"
                              placeholder="Buscar beneficiario por nombre"
                              value={beneficiarySearch}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                setBeneficiarySearch(inputValue);
                              }}
                            />
                          </div>
                        )}
                      </ListGroupItem>
                      {/* - Start list map */}
                      {beneficiariesList.length > 0 ? (
                        beneficiariesList?.map((beneficiary, index) => (
                          <ListGroupItem key={index} className="px-4 py-3 rounded-0 list-group-item-hover">
                            <div className="bg-infox">
                              <div
                                onClick={() => {
                                  // The user must select an option from the list.
                                  dispatch(setInsuranceData({ data: beneficiary.name, point: 'beneficiaryName' })); // to the request
                                  dispatch(setInsuranceData({ data: beneficiary.rfc, point: 'beneficiaryRFC' })); // to the request
                                  // setBeneficiarySearch(beneficiary.name); // it is just for view
                                  setIsShowBeneficiaries(false);
                                  //  dispatch(setInsuranceDataError({ data: false, point: 'beneficiaryRFC' })); // Limpiar errores
                                }}
                              >
                                {beneficiary.name} <div>{`RFC: ${beneficiary.rfc}`}</div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <OverlayTrigger delay={{ show: 300, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Editar</Tooltip>}>
                                  <span
                                    onClick={() => {
                                      setIsBeneficiaryModalOpen(true);
                                      setBeneficiaryData(beneficiary);
                                      setOperationType('Editar');
                                    }}
                                  >
                                    <CsLineIcons icon="edit" size="18" className="me-3 btn-link" />
                                  </span>
                                </OverlayTrigger>
                                <OverlayTrigger delay={{ show: 300, hide: 0 }} placement="top" overlay={<Tooltip id="tooltip-top">Eliminar</Tooltip>}>
                                  <span
                                    onClick={() => {
                                      setIsDeleteModalOpen(true);
                                      setBeneficiaryData(beneficiary);
                                    }}
                                  >
                                    <CsLineIcons icon="bin" size="18" className="btn-link" />
                                  </span>
                                </OverlayTrigger>
                              </div>
                            </div>
                          </ListGroupItem>
                        ))
                      ) : (
                        <ListGroupItem>
                          <p className="p-2">No hay coincidencias</p>
                        </ListGroupItem>
                      )}
                      {/* - End list map */}
                    </>
                  ) : (
                    <ListGroupItem className="text-center position-relative z-0">
                      <div className="p-2">Tu lista de beneficiarios está vacía. Agrega uno y selecciónalo para continuar.</div>
                      <Button
                        variant="outline-primary"
                        data-gtm="init-report"
                        className="w-auto btn btn-icon btn-icon-start ms-0 ms-sm-1"
                        onClick={() => {
                          setIsBeneficiaryModalOpen(true);
                          setOperationType('Guardar');
                        }}
                      >
                        <CsLineIcons icon="plus" />
                        <span className="ps-2">Agregar beneficiario</span>
                      </Button>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </motion.div>
            )}
          </AnimatePresence>
          {insuranceData.errors.beneficiaryName && (
            <div className={`invalid-tooltip ${insuranceData.errors.beneficiaryName ? 'd-block' : 'd-none'}`}>{insuranceData.errors.beneficiaryName}</div>
          )}
          {/* lista */}
        </Col>

        <Col xs="12" className="position-relative pe-2 ">
          <Form.Label>Identificador de mercancía</Form.Label>
          <span className="text-primary">*</span>
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Selecciona una categoria"
              value={commodities}
              onChange={(e) => {
                const inputValue = e.target.value;
                setCommodities(inputValue);
                setIsShowInsuranceCategories(true); // Mostrar la lista si el usuario está escribiendo
                dispatch(setInsuranceData({ data: '', point: 'commoditieId' })); // fot the request
                dispatch(setInsuranceData({ data: inputValue, point: 'category' })); // for the view
                dispatch(setInsuranceDataError({ data: false, point: 'commoditieId' })); // clean error
              }}
            />
            <InputGroup.Text
              className="cursor-pointer"
              onClick={() => {
                setCommodities('');
                setIsShowInsuranceCategories(!isShowInsuranceCategories);
                dispatch(setInsuranceDataError({ data: false, point: 'commoditieId' }));
              }}
            >
              <CsLineIcons icon="chevron-bottom" size="16" className="text-primary" />
            </InputGroup.Text>
          </InputGroup>
          {/* productCategoryList */}
          <AnimatePresence>
            {isShowInsuranceCategories && (
              <motion.div
                key="insurance-form"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
                className="mb-5 scroll-container"
                style={{
                  position: 'absolute',
                  zIndex: 1000,
                  // minHeight: '200px',
                  maxHeight: '300px',
                  width: 'auto',
                  overflowY: 'scroll',
                  cursor: 'pointer',
                  fontFamily: 'Montserrat',
                  borderRadius: '10px',
                  boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                }}
              >
                <ListGroup>
                  {categoryList.length > 0 ? (
                    categoryList?.map((category, index) => (
                      <ListGroupItem
                        key={index}
                        className="p-2 ps-0 rounded-0 list-group-item-hover"
                        onClick={() => {
                          // The user must select an option from the list.
                          dispatch(setInsuranceData({ data: category.name, point: 'category' })); // Actualizar vista
                          dispatch(setInsuranceData({ data: category.commoditie_id, point: 'commoditieId' })); // Reset para la petición
                          setCommodities(category.name); // it is just for view
                          setIsShowInsuranceCategories(false);
                          dispatch(setInsuranceDataError({ data: false, point: 'commoditieId' })); // Limpiar errores
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <div className="ms-3">{category.name}</div>
                        </div>
                      </ListGroupItem>
                    ))
                  ) : (
                    <ListGroupItem className="text-muted text-center p-2  position-relative z-0">No hay coincidencias</ListGroupItem>
                  )}
                </ListGroup>
              </motion.div>
            )}
          </AnimatePresence>
          <div className="tooltip-end-top">
            {insuranceData.errors.commoditieId && (
              <div className={`invalid-tooltip ${insuranceData.errors.commoditieId ? 'd-block' : 'd-none'}`}>{insuranceData.errors.commoditieId}</div>
            )}
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}

export default InsuranceToggle;

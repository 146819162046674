// React and hooks
import React, { useState } from 'react';

// React Router
import { NavLink } from 'react-router-dom';

// UI Components
import { Row, Col, Card, Button } from 'react-bootstrap';

// External Libraries
import { motion } from 'framer-motion';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

// Custom Components
import Loading from '../../../components/loading/LoadingRed';
import ShippingRefundModal from '../components/ShippingRefundModal';
import VideoModal from '../components/VideoModal';
import ShipmentCard from '../components/ShipmentCard';
import DashboardCard from '../../../components/cards/DashboardCard';
import IncidentTypeModal from '../../support/components/IncidentTypeModal';
import NotificationGallery from '../../../components/carousel/NotificationsGallery';

function AuthorizedUserPanel({ isUserAllowed = false, lastShippings = [], notifications = [], isLoading = false }) {
  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const cardVariants = {
    hidden: { opacity: 0, y: 50 }, // Empieza invisible y desplazado hacia abajo
    visible: { opacity: 1, y: 0 }, // Se vuelve visible y se posiciona
  };
  return (
    <>
      <ShippingRefundModal setIsRefundModalOpen={setIsRefundModalOpen} isRefundModalOpen={isRefundModalOpen} />
      <IncidentTypeModal isModalOpen={isReportModalOpen} setIsModalOpen={setIsReportModalOpen} />
      {/* Warning Modal */}
      {/* <WarningModal isOpen={isWarningModalOpen} onClose={handleModalClose} /> */}
      <VideoModal
        isOpen={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
        videoId="YSbmX0oNCUU" // ID del video de YouTube
      />
      <Row className="bg-warningx g-0 d-flex align-items-center">
        {/* Last Shippings Start */}
        {/* si el usuario esta autorizado y la lista esta vacia el loader se pone en true   */}
        {isLoading && isUserAllowed && (
          <Col lg="6" xxl="7" className="mb-5 order-2 order-lg-1 bg-infox">
            <h2 className="small-title d-none">Últimos Envíos Realizados</h2>
            <div style={{ minHeight: '150px' }}>
              {isLoading && (
                <div className="d-flex justify-content-center align-items-center" style={{ height: '150px' }}>
                  <Loading />
                </div>
              )}
            </div>
          </Col>
        )}
        {/* si el usuario esta permitido y el array no esta cargando ni esta vacio */}
        {isUserAllowed && lastShippings?.length > 0 && (
          <Col lg="6" xxl="7" className="mb-5 order-2 order-lg-1 bg-successx">
            <h2 className="small-title">Últimos Envíos Realizados</h2>
            {lastShippings.map((shipping, index) => (
              <motion.div
                key={index}
                initial="hidden"
                animate="visible"
                transition={{
                  duration: 0.5,
                  delay: index * 0.2, // Retraso para animación secuencial
                }}
                variants={cardVariants}
              >
                <ShipmentCard shipping={shipping} />
              </motion.div>
            ))}
          </Col>
        )}
        {/* si el usuario esta permitido pero su lista esta vacia o sea no ha hecho su primer envio */}
        {lastShippings?.length === 0 && (
          <Col lg="6" xxl="7" className="mb-5 order-2 order-lg-1 bg-infox">
            <h2 className="small-title">Últimos Envíos Realizados</h2>
            <Card className="sh-40 h-xl-100-card">
              {/* && lastShippings?.length === 0 */}
              <Card.Body className="d-flex align-items-center justify-content-center h-100">
                <div className="text-center">
                  <img src="/img/illustration/icon-storage.webp" className="theme-filter mb-1" alt="launch" />
                  <p className={`mb-3 text-primary ${isUserAllowed ? 'visible' : 'invisible'}`}>Crea tu primer envio!</p>
                  <p className="mb-3">Aquí encontrarás un listado de tus últimos envíos.</p>
                  <div className={isUserAllowed ? 'visible' : 'invisible'}>
                    <NavLink to="/envios/enviar" className="btn btn-primary" id="goFormFirstTime">
                      <CsLineIcons icon="arrow-double-left" size="15" className="me-1" />
                      <span>Crear envío</span>
                    </NavLink>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        )}
        {/* Last Shippings End */}

        {/* Notifications Start */}
        {isUserAllowed && (
          <Col sm="8" lg="6" xxl="5" className="mx-auto mb-5 ps-lg-3 pt-lg-3 order-1 order-lg-2 bg-successx">
            <h2 className="small-title d-none">Notificaciones</h2>
            <div className=" bg-infox" style={{ maxWidth: '746px' }}>
              <div style={{ minHeight: '150px' }} className={`bg-infox ${notifications.length === 0 ? 'd-none' : 'd-block'}`}>
                <motion.div
                  initial={{ opacity: 0, y: 50 }} // Empieza transparente y desplazado hacia abajo
                  animate={{ opacity: 1, y: 0 }} // Se muestra y vuelve a su posición
                  transition={{ duration: 0.5, ease: 'easeOut' }} // Transición suave
                >
                  {notifications.length > 0 && <NotificationGallery notifications={notifications} />}
                </motion.div>
              </div>
            </div>
          </Col>
        )}

        {/* Notifications End */}
      </Row>
      {/* Cards Start */}
      <Row className="bg-infox">
        <Col xs="12" sm="6" xl="3">
          <Card className="h-100-card hover-img-scale-up mx-auto mb-3" style={{ minHeight: '300px', maxWidth: '480px', backgroundColor: '#f1f1f1' }}>
            <img src="/img/autopaquete/enviar/manual.webp" className="card-img h-100 scale" alt="card image" />
            <Card.Body className="d-flex flex-column justify-content-between align-items-start  bg-transparent card-img-overlay">
              <div>
                <div className="cta-3">¿Nuevo por aquí?</div>
                <div className="mb-3 cta-3 text-primary">Introducción</div>
                <div className="text-alternate mb-4">Descubre cómo usar nuestra plataforma con este breve video.</div>
              </div>
              <Button
                variant="outline-primary"
                id="initVideo"
                className="w-auto btn btn-icon  btn-icon-start ms-0 ms-sm-1"
                onClick={() => {
                  setIsVideoModalOpen(true);
                }}
              >
                <CsLineIcons icon="arrow-right" />
                <span className="ps-2">Ver Video</span>
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <DashboardCard
          img="/img/autopaquete/enviar/recarga.webp"
          title="¿Tienes dudas?"
          subTitle="Manual de usuario"
          description="Consulta nuestra guía de preguntas frecuentes para resolver todas tus dudas."
          url="https://autopaquete-log.glide.page/dl/d0a5f4"
          btnTitle="Ver manual"
        />
        <Col xs="12" sm="6" xl="3">
          <Card className="h-100-card hover-img-scale-up mx-auto mb-3" style={{ minHeight: '300px', maxWidth: '480px', backgroundColor: '#f1f1f1' }}>
            <img src="/img/autopaquete/enviar/soporte.webp" className="card-img h-100 scale" alt="card image" />
            <Card.Body className="d-flex flex-column justify-content-between align-items-start  bg-transparent card-img-overlay">
              <div>
                <div className="cta-3">¿Tu paquete tiene incidencia?</div>
                <div className="mb-3 cta-3 text-primary">Realizar reporte</div>
                <div className="text-alternate mb-4">Proporciónanos los datos de tu paquete y nosotros nos encargamos del reporte.</div>
              </div>
              <Button
                variant="outline-primary"
                data-gtm="init-report"
                className={`w-auto btn btn-icon btn-icon-start ms-0 ms-sm-1 ${isUserAllowed ? 'visible' : 'invisible'}`}
                onClick={() => {
                  setIsReportModalOpen(true);
                }}
              >
                <CsLineIcons icon="arrow-right" />
                <span className="ps-2">Nuevo Reporte</span>
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col xs="12" sm="6" xl="3">
          <Card className="h-100-card hover-img-scale-up mx-auto mb-3" style={{ minHeight: '300px', maxWidth: '480px', backgroundColor: '#f1f1f1' }}>
            <img src="/img/autopaquete/enviar/reembolso.webp" className="card-img h-100 scale" alt="card image" />
            <Card.Body className="d-flex flex-column justify-content-between align-items-start  bg-transparent card-img-overlay">
              <div>
                <div className="cta-3">¿Guía sin usar?</div>
                <div className="mb-3 cta-3 text-primary">Reembolso de guías</div>
                <div className="text-alternate mb-4">Facilitamos tu reembolso de guías con un proceso simple y sin complicaciones.</div>
              </div>
              <Button
                variant="outline-primary"
                data-gtm="init-cancellations"
                className={`w-auto btn btn-icon btn-icon-start ms-0 ms-sm-1 ${isUserAllowed ? 'visible' : 'invisible'}`}
                onClick={() => {
                  setIsRefundModalOpen(true);
                }}
              >
                <CsLineIcons icon="arrow-right" />
                <span className="ps-2">Solicitar reembolso</span>
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {/* Cards End */}
    </>
  );
}

export default AuthorizedUserPanel;

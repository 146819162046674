// 1. React and core dependencies
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

// 2. UI Components
import { Button, Card, Col, Form, Row, ListGroup, ListGroupItem, InputGroup, Modal } from 'react-bootstrap';

// 3. Libraries
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import 'react-toastify/dist/ReactToastify.css';
import { motion, AnimatePresence } from 'framer-motion';
import * as Yup from 'yup';
import ClipLoader from 'react-spinners/ClipLoader';

// 4. Utils
import { handleAPIError, handleCatchError } from 'utils/handleErrors';

// 5. Components
import HtmlHead from 'components/html-head/HtmlHead';
import InputText from 'components/form-elements/InputText';
import SelectFile from 'components/form-elements/SelectFile';

// 6. API calls
import { getCommodities, verifyAccountDocuments } from 'api/Api-user';

// 7. Redux slices (reducers)
import { setInsuranceCategories } from 'slices/generalData/generalData';

function VerifyAccount() {
  const title = 'Verificar Cuenta';
  const description = 'Verificar Cuenta Plataforma Autopaquete';
  const history = useHistory();
  const dispatch = useDispatch();
  const { generalData } = useSelector((state) => state.general_data);
  const hasSetCategories = useRef(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isShowInsuranceCategories, setIsShowInsuranceCategories] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const [messageSuccess, setMessageSuccess] = useState('');

  const initialStates = {
    fullName: '',
    businessName: '',
    legalName: '',
    webpage: '',
    businessProducts: '',
    taxCertificate: '',
    addressVerification: undefined,
    frontINE: undefined,
    backINE: undefined,
  };
  const [data, setData] = useState(initialStates);
  const [errors, setErrors] = useState(initialStates);

  // Schema Validation
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('El nombre completo es obligatorio'),
    businessName: Yup.string().required('El nombre de la empresa es obligatorio'),
    webpage: Yup.string().required('La página web es obligatoria'),
    // webpage: Yup.string()
    // .matches(
    //   /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=%]*)?$/i,
    //   'La página web no es válida. Ejemplo: ejemplo.com, ejemplo.com.mx o https://ejemplo.com/'
    // )
    // .required('La página web es obligatoria'),
    businessProducts: Yup.string().required('La descripción de los productos de la empresa es obligatoria'),
    addressVerification: Yup.string().required('El documento de verificación de dirección es obligatorio'),
    frontINE: Yup.string().required('La imagen frontal del INE es obligatoria'),
    backINE: Yup.string().required('La imagen posterior del INE es obligatoria'),
    // conditional fields to insurance
    legalName: Yup.string().when('$isInvoice', {
      is: true,
      then: Yup.string().required('El nombre legal es obligatorio para facturar.'),
      otherwise: Yup.string().notRequired(),
    }),
    taxCertificate: Yup.string().when('$isInvoice', {
      is: true,
      then: Yup.string()
        .nullable() // Permite que el valor sea `null`
        .required('El certificado fiscal es obligatorio para facturar.'),
      otherwise: Yup.string().notRequired(),
    }),
  });
  const updateField = (value, validation, field) => {
    // Remove spaces and special characters solo si el campo NO es 'webpage'
    // const cleanedData = field === 'webpage' ? value : cleanData(value);
    if (value === '' || validation.test(value)) {
      setData((prevValues) => ({ ...prevValues, [field]: value }));
    }
  };
  // - cleanError
  const cleanError = (value, field) => {
    if (errors[field]) {
      // console.log('si hay un error en el campo:', field);
      setErrors((prevValues) => ({ ...prevValues, [field]: value }));
    }
  };
  const handleValidateAccount = async () => {
    try {
      setIsLoading(true);
      // console.log('Create ticket data:', updatedValues);
      const response = await verifyAccountDocuments(data);
      // console.log('response validate account ticket:', response);
      if (response.success) {
        setIsModalSuccess(true);
        setMessageSuccess(response.message);
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
    }
  };
  // Validation
  const validateForm = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(data, {
        context: { isInvoice: isChecked },
        abortEarly: false, // Esto asegura que se capturen todos los errores en lugar de detenerse en el primero.
      });
      handleValidateAccount();
    } catch (error) {
      const formErrors = {};
      error.inner.forEach((err) => {
        formErrors[err.path] = err.message;
      });
      setErrors(formErrors);
    }
  };
  const closeModal = () => {
    history.push('/inicio');
    setIsModalSuccess(false);
  };
  const filterCategories = (searchTerm, list) => {
    if (!list) return [];
    const trimmedTerm = searchTerm.trim().toLowerCase();
    return trimmedTerm === '' ? list : list.filter((item) => item.name.toLowerCase().includes(trimmedTerm));
  };
  // Get insurance categories
  useEffect(() => {
    if (!hasSetCategories.current) {
      const storedCategories = localStorage.getItem('insuranceCategories');
      // console.log('categoria de productos localstorage:', storedCategories);
      if (storedCategories) {
        dispatch(setInsuranceCategories({ data: JSON.parse(storedCategories) }));
      } else {
        getCommodities()
          .then((response) => {
            // console.log('request categories res:', response);
            if (response?.insurance_categories) {
              localStorage.setItem('insuranceCategories', JSON.stringify(response.insurance_categories));
              dispatch(setInsuranceCategories({ data: response.insurance_categories }));
            }
          })
          .catch((error) => {
            console.error('Error obteniendo categorías:', error);
          });
      }
      hasSetCategories.current = true;
    }
  }, [dispatch]);
  // filter insurance categories
  useEffect(() => {
    // - filter insurance categories
    setCategoryList(filterCategories(searchValue, generalData.fields.insuranceCategories));
  }, [searchValue, generalData.fields.insuranceCategories]);

  const ModalSuccess = ({ message = '' }) => {
    return (
      <Modal show={isModalSuccess} onHide={() => closeModal()} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title />
        </Modal.Header>
        <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
          <img src="img/animation/success-docs.gif" alt="registro exitoso" width="250px" />
          <h1 className="h1 text-primary my-3">¡Gracias por completar tu registro!</h1>
          <p className="h4 text-center w-90">{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => closeModal()}>
            Cerrar
          </Button>
          {/* <Button onClick={() => setIsModalSuccess(false)}>Understood</Button> */}
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <ModalSuccess message={messageSuccess} />
      <div id="pageContainer">
        <HtmlHead title={title} description={description} />
        {/* Title Start */}
        <div className="page-title-container">
          <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to="/inicio">
            <CsLineIcons icon="chevron-left" size="13" />
            <span className="align-middle text-small ms-1">Inicio</span>
          </NavLink>
          <h1 className="mb-0 pb-0 display-4" id="title">
            {title}
          </h1>
        </div>
        {/* Title End */}
        <Row>
          <Col xs="12" lg="10">
            <Card>
              <Card.Body>
                <Form onSubmit={validateForm} className="my-5 d-flex justify-content-center">
                  <Col lg="9">
                    <Row className="g-0 bg-infox">
                      <Col lg="12" className="position-relative mb-3">
                        <InputText
                          label="Nombre completo"
                          icon="user"
                          maxLength="30"
                          isRequired
                          setField={updateField}
                          fieldName="fullName"
                          value={data.fullName}
                          cleanError={cleanError}
                        />
                        <div className={`invalid-tooltip ${errors.fullName ? 'd-block' : 'd-none'}`}>{errors.fullName}</div>
                      </Col>
                      <Col lg="12" className="position-relative mb-3">
                        <InputText
                          label="Nombre comercial"
                          icon="shop"
                          maxLength="30"
                          value={data.businessName}
                          isRequired
                          fieldName="businessName"
                          setField={updateField}
                          cleanError={cleanError}
                        />
                        <div className={`invalid-tooltip ${errors.businessName ? 'd-block' : 'd-none'}`}>{errors.businessName}</div>
                      </Col>
                      <Col lg="12" className="position-relative mb-3">
                        <Form.Label>Tipo de mercancia</Form.Label>
                        <span className="text-primary">*</span>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            placeholder="Selecciona una categoria"
                            value={data.businessProducts}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              setSearchValue(inputValue);
                              setIsShowInsuranceCategories(true); // Show the list if the user is typing
                              setData((prevValues) => ({ ...prevValues, businessProducts: inputValue }));
                              if (errors.businessProducts) {
                                setErrors((prevValues) => ({ ...prevValues, businessProducts: '' }));
                              }
                            }}
                          />
                          <InputGroup.Text
                            className="cursor-pointer"
                            onClick={() => {
                              setSearchValue('');
                              //  console.log('cursor-pointer lista de categorias completa:', generalData.fields.insuranceCategories);

                              // setData((prevValues) => ({ ...prevValues, businessProducts: '' }));
                              setIsShowInsuranceCategories(!isShowInsuranceCategories);
                              if (errors.businessProducts) {
                                setErrors((prevValues) => ({ ...prevValues, businessProducts: '' }));
                              }
                            }}
                          >
                            <CsLineIcons icon="chevron-bottom" size="16" className="text-primary" />
                          </InputGroup.Text>
                        </InputGroup>
                        <div className={`invalid-tooltip ${errors.businessProducts ? 'd-block' : 'd-none'}`}>{errors.businessProducts}</div>

                        {/* productCategoryList */}
                        <AnimatePresence>
                          {isShowInsuranceCategories && (
                            <motion.div
                              key="insurance-form"
                              initial={{ opacity: 0, y: -20 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -20 }}
                              transition={{ duration: 0.5 }}
                              className="mb-5"
                              style={{
                                position: 'absolute',
                                zIndex: 1000,
                                // minHeight: '200px',
                                maxHeight: '300px',
                                width: 'auto',
                                overflowY: 'scroll',
                                cursor: 'pointer',
                                fontFamily: 'Montserrat',
                                borderRadius: '10px',
                                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
                              }}
                            >
                              <ListGroup>
                                {categoryList.length > 0 ? (
                                  categoryList?.map((category, index) => (
                                    <ListGroupItem
                                      key={index}
                                      className="p-2 ps-0 rounded-0 list-group-item-hover"
                                      onClick={() => {
                                        // The user must select an option from the list.
                                        setData((prevValues) => ({ ...prevValues, businessProducts: category.name }));

                                        setIsShowInsuranceCategories(false);
                                        // delete error
                                        // dispatch(setInsuranceDataError({ data: false, point: 'commoditieId' }));
                                      }}
                                    >
                                      <div className="d-flex align-items-center">
                                        <div className="ms-3">{category.name}</div>
                                      </div>
                                    </ListGroupItem>
                                  ))
                                ) : (
                                  <ListGroupItem className="text-muted text-center p-2  position-relative z-0">No hay coincidencias</ListGroupItem>
                                )}
                              </ListGroup>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </Col>
                      <Col lg="12" className="position-relative mb-3">
                        <InputText
                          label="Enlace a tu sitio web o perfil en redes sociales"
                          icon="at-sign"
                          value={data.webpage}
                          isRequired
                          placeholder="Ejemplo: tusitio.com, instagram.com/tuperfil o facebook.com/tuperfil"
                          fieldName="webpage"
                          setField={updateField}
                          cleanError={cleanError}
                          validation={/^[A-Z0-9-._~:/?#[\]@!$&'()*+,;=%]+$/i}
                        />
                        <div className={`invalid-tooltip ${errors.webpage ? 'd-block' : 'd-none'}`}>{errors.webpage}</div>
                      </Col>
                      <div className="bg-warningx d-flex my-3">
                        <Form.Check // prettier-ignore
                          type="switch"
                          id="switch_insurance"
                          className="bg-successx d-flex aling-items-end"
                          checked={isChecked}
                          onChange={(e) => {
                            setIsChecked(e.target.checked);
                          }}
                        />
                        <span className="ms-1 d-flex aling-items-end bg-infox">¿Deseas facturar?</span>
                      </div>
                      {isChecked && (
                        <AnimatePresence>
                          <motion.div
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.3 }}
                            className="scroll-container mt-3"
                          >
                            <Col lg="12" className="position-relative">
                              <InputText
                                label="Razón social"
                                icon="suitcase"
                                maxLength="30"
                                isRequired
                                helpText="Asegúrate de que el nombre coincida con el de la constancia."
                                value={data.legalName}
                                fieldName="legalName"
                                setField={updateField}
                                cleanError={cleanError}
                              />
                              <div className={`invalid-tooltip ${errors.legalName ? 'd-block' : 'd-none'}`}>{errors.legalName}</div>
                            </Col>
                            <Col xl="12" className="position-relative bg-successx">
                              <SelectFile
                                placeholder="Sube tu constancia de situación fiscal"
                                formObj={setData}
                                isRequired
                                helpText="La constancia no debe tener una antigüedad mayor a 3 meses."
                                fieldName="taxCertificate"
                                setErrors={setErrors}
                              />
                              <div className={`invalid-tooltip ${errors.taxCertificate ? 'd-block' : 'd-none'}`}>{errors.taxCertificate}</div>
                            </Col>
                          </motion.div>
                        </AnimatePresence>
                      )}
                      <Row className="g-0 bg-mutedx">
                        <Col xl="12" className="position-relative mb-3 bg-secondaryx">
                          <SelectFile
                            isRequired
                            placeholder="Sube la foto del anverso de tu INE"
                            helpText="Asegúrate de que la foto sea clara y muestre tu nombre, foto y firma legibles."
                            formObj={setData}
                            fieldName="frontINE"
                            setErrors={setErrors}
                          />
                          <div className={`invalid-tooltip ${errors.frontINE ? 'd-block' : 'd-none'}`}>{errors.frontINE}</div>
                        </Col>
                        <Col xl="12" className="position-relative mb-3 bg-infox">
                          <SelectFile
                            isRequired
                            placeholder="Sube la foto del reverso de tu INE"
                            formObj={setData}
                            fieldName="backINE"
                            setErrors={setErrors}
                            helpText="La imagen debe ser nítida y mostrar el código QR y los datos visibles sin reflejos."
                          />
                          <div className={`invalid-tooltip ${errors.backINE ? 'd-block' : 'd-none'}`}>{errors.backINE}</div>
                        </Col>
                        <Col xl="12" className="position-relative mb-3 bg-mutedx pt-1">
                          <SelectFile
                            isRequired
                            placeholder="Sube un comprobante de domicilio"
                            formObj={setData}
                            helpText="Comprobante de luz, agua, teléfono o internet (máx. 3 meses de antigüedad)"
                            fieldName="addressVerification"
                            setErrors={setErrors}
                          />
                          <div className={`invalid-tooltip ${errors.addressVerification ? 'd-block' : 'd-none'}`}>{errors.addressVerification}</div>
                        </Col>
                      </Row>

                      <div className="mt-5 d-flex justify-content-center">
                        <Button size="lg" type="submit" disabled={isLoading}>
                          {isLoading && <ClipLoader color="#FFFFFF" loading={isLoading} size={10} />}
                          {isLoading ? 'Enviando...' : ' Enviar'}
                        </Button>
                      </div>
                    </Row>
                  </Col>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default VerifyAccount;
// {/* <Application theme={theme}>
//   <FileSelector
//     // className="rainbow-m-vertical_x-large rainbow-p-horizontal_medium rainbow-m_auto mx-auto"
//     // style={containerStyles}
//     // label="INE"
//     placeholder="Sube la foto del anverso de tu INE"
//     // bottomHelpText="Selecciona solo un archivo"
//     // onChange={setFile}
//     borderRadius="semi-rounded"
//   />
// </Application> */}

import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col, Dropdown, Form, Button } from 'react-bootstrap';
import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import moment from 'moment';
import Confetti from 'react-confetti';
// utils
import { handleCatchError, handleAPIError } from '../../../utils/handleErrors';
// components
import CancellationsCard from './components/CancellationsCard';
// import { ToastErrorApi, ToastErrorCath } from '../../../components/alerts/toast';
import RainCalendar from '../../../components/calendars/RainCalendar';
import AdminLoader from '../../../components/loading/AdminLoader';
import LoadingPulse from '../../../components/loading/LoadingPulse';
import Paginate from '../../components/Paginate';
// api
import { getCancellations, getFilteredCancellations } from '../../api/Api-admin';

const { FormatMoney } = require('format-money-js');

const Cancellations = () => {
  const title = 'Cancelaciones';
  const description = 'Cancelaciones de Envíos Plataforma Autopaquete';
  // - Use references to do scroll Up
  const startRef = useRef(null);
  // -Local States
  const [isLoading, setIsLoading] = useState(false);
  const [cancellations, setCancellations] = useState([]);
  const [isInitialLoader, setIsInitialLoader] = useState(true);
  // const [updatePage, setUpdatePage] = useState(false);
  // Start Pagination States
  const [currentPage, setCurrentPage] = useState(1); // solo para la vista
  const [pageSize, setPageSize] = useState(20); // solo para la vista
  const [totalItems, setTotalItems] = useState(0);
  const [changePage, setChangePage] = useState(currentPage); // para la peticion al inicio si valor es 1
  const [changeItemsNum, setChangeItemsNum] = useState(pageSize); // para la peticion al inicio si valor es 20
  const [totalPages, setTotalPages] = useState(null); // update with the response of request
  // End Pagination States
  // Start Filter States
  const [shippingState, setShippingState] = useState('');
  const [stateTitle, setStateTitle] = useState(''); // for view

  const [identifier, setIdentifier] = useState('');
  const [boolean, setBoolean] = useState(true);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  // End Filter States
  // Start States for Components for Filtering
  const [selectStartDate, setSelectStartDate] = useState({ date: undefined });
  const [selectEndDate, setSelectEndDate] = useState({ date: undefined });

  const [filteredMode, setFilteredMode] = useState(false);
  const [filteredSuccess, setFilteredSuccess] = useState('');

  const fm = new FormatMoney({
    decimals: 0, // No decimales
  });

  const settingPage = (record) => {
    setChangePage(1);
    setChangeItemsNum(record);
  };
  const handlerGetCancellations = async () => {
    try {
      setIsLoading(true);
      const response = await getCancellations(changePage, changeItemsNum);
      // console.log('cancelaciones res:', response);
      if (response.cancelations !== undefined) {
        setCancellations(response.cancelations);
        setTotalPages(response.total_pages);
        setTotalItems(response.total_items);
        setCurrentPage(response.current_page); // actualizar la vista de la paginacion
        setPageSize(response.page_size); // para actualizar la vista
        if (startRef.current) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
      setIsInitialLoader(false);
    }
  };
  // , startDate, endDate, shippingState, boolean  changePage, changeItemsNum,
  const handlerGetFilteredCancellation = async () => {
    try {
      setIsLoading(true);
      const response = await getFilteredCancellations(changePage, changeItemsNum, identifier, shippingState, boolean, startDate, endDate);
      // console.log('filtrado:', response);
      if (response.cancelations !== undefined) {
        setCancellations(response.cancelations);
        setTotalPages(response.total_pages);
        setTotalItems(response.total_items);
        setCurrentPage(response.current_page); // actualizar la vista de la paginacion
        setPageSize(response.page_size); // para actualizar la vista
        setFilteredSuccess(
          `Filtro ${identifier ? ` / ${identifier}` : ''} ${stateTitle ? `/ ${stateTitle}` : ''} ${
            startDate ? ` del ${moment(startDate).format('DD-MM-YY')}` : ''
          } ${endDate ? `al ${moment(endDate).format('DD-MM-YY')}` : ''}`
        );
        if (startRef.current) {
          startRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsLoading(false);
      setFilteredMode(true); // respuesta exitosa o no es necesario para mantener el modo filtrando y que aparezca el btn regresar
    }
  };
  // Clean Dates
  const initialDatesValues = () => {
    setSelectStartDate({ date: undefined });
    setSelectEndDate({ date: undefined });
    setStartDate('');
    setEndDate('');
  };
  // Reset Values
  const resetInitialValues = () => {
    initialDatesValues();
    setStateTitle('');
    setShippingState('');
    setIdentifier('');
    setFilteredSuccess(''); // para eliminar el mensaje "Filtro por nombre..."
  };
  const resetInitialMode = () => {
    setFilteredMode(false);
    resetInitialValues();
    if (changePage > 1) {
      setChangePage(1); // ejecutara el useEffect que decide que fn ejecutar pero con la pag 1
    } else {
      // si changePage es 1 no ejecuta el cambio de estado ni el useEffect es necesario llamar a la funcion
      handlerGetCancellations();
    }
  };
  useEffect(() => {
    if (filteredMode) {
      handlerGetFilteredCancellation();
    } else {
      handlerGetCancellations();
    }
  }, [changePage, changeItemsNum]);

  return (
    <>
      {isLoading && <LoadingPulse />}

      {isInitialLoader ? (
        <AdminLoader />
      ) : (
        <>
          <HtmlHead title={title} description={description} />
          <div className="page-title-container" ref={startRef}>
            <Row className="g-0">
              {/* Title Start */}
              <Col className="col-auto mb-3 mb-sm-0 me-auto order-1">
                <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back invisible" to="/">
                  <CsLineIcons icon="chevron-left" size="13" />
                  <span className="align-middle text-small ms-1">Atrás</span>
                </NavLink>
                <h1 className="mb-0 pb-0 display-4" id="title">
                  {title}
                </h1>
              </Col>
              {/* Title End */}
            </Row>
          </div>
          {/* este div es para ocultar el contenido cuando esta cargando  */}
          <div style={{ minHeight: '50vh' }} id="cardContainer">
            {/* *** Dropdown and Search Buttons Start *** */}
            <Row className="g-0 mb-2 mt-sm-5">
              {/* Filters Start */}
              <Col xs="auto" xl="9" className="bg-successx">
                <Row className="g-0">
                  <Col xs="12" sm="auto" className="pe-2 bg-successx">
                    <Row className="g-0">
                      <Col xs="12" sm="auto" className="d-inline-block pe-2">
                        <Form.Control
                          type="text"
                          className="mb-2"
                          style={{ fontFamily: 'Montserrat', width: '275px' }}
                          placeholder="Crm o id envío"
                          value={identifier}
                          onChange={(e) => setIdentifier(e.target.value)}
                        />
                      </Col>
                      {/* poner un sected */}
                      <Col xs="12" sm="auto" className="mb-2 pe-2">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="foreground-alternate"
                            className="text-end shadow d-flex justify-content-between align-items-center w-100"
                            style={{
                              fontFamily: 'Montserrat',
                              minWidth: '200px',
                              maxWidth: '275px',
                            }}
                          >
                            {`${stateTitle || ''}`.trim() || 'Estado'}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="shadow" align="end" style={{ fontFamily: 'Montserrat', maxHeight: '250px', overflowY: 'scroll' }}>
                            <Dropdown.Item
                              onClick={() => {
                                setStateTitle('');
                                setShippingState('');
                              }}
                            >
                              Ninguno
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setStateTitle('Devueltas');
                                setShippingState('refunded');
                                setBoolean(true);
                              }}
                            >
                              Devueltas
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setStateTitle('Canceladas');
                                setShippingState('canceled');
                                setBoolean(true);
                              }}
                            >
                              Canceladas
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setStateTitle('Utilizadas');
                                setShippingState('consumed');
                                setBoolean(true);
                              }}
                            >
                              Utilizadas
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setStateTitle('Finalizadas');
                                setShippingState('done');
                                setBoolean(true);
                              }}
                            >
                              Finalizadas
                            </Dropdown.Item>
                            {/* Valores Verdadero/Falso */}
                            <Dropdown.Header>----------------------------</Dropdown.Header>
                            <Dropdown.Item
                              onClick={() => {
                                setStateTitle('No devueltas');
                                setShippingState('refunded');
                                setBoolean(false);
                              }}
                            >
                              No devueltas
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setStateTitle('No canceladas');
                                setShippingState('canceled');
                                setBoolean(false);
                              }}
                            >
                              No canceladas
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setStateTitle('No utilizadas');
                                setShippingState('consumed');
                                setBoolean(false);
                              }}
                            >
                              No utilizadas
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setStateTitle('No finalizadas');
                                setShippingState('done');
                                setBoolean(false);
                              }}
                            >
                              No finalizadas
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                    <Row className="g-0">
                      <Col xs="auto" className="pt-0 mb-2 mb-xl-0 pe-2">
                        <RainCalendar
                          selectDate={selectStartDate}
                          setSelectDate={setSelectStartDate}
                          setState={setStartDate}
                          state={startDate}
                          holder="Fecha Inicial"
                        />
                      </Col>
                      <Col xs="auto" className="mb-2 mb-xl-0">
                        <RainCalendar selectDate={selectEndDate} setSelectDate={setSelectEndDate} setState={setEndDate} holder="Fecha Final" />
                      </Col>
                    </Row>
                  </Col>
                  {/* Filter buttons Start */}
                  <Col xs="12" sm="auto" className="pb-2 pb-xl-0 d-flex align-items-end bg-infox">
                    <Row className="g-0">
                      <Col xs="auto" className="pe-2">
                        <Button
                          variant="primary"
                          style={{ height: '36px' }}
                          className="w-100 mb-0 mt-2"
                          onClick={() => {
                            if (changePage > 1) {
                              // la primera vez el estado filteredMode es falso
                              setFilteredMode(true); // para que el useEffect funcione bien
                              setChangePage(1); // ejecutara el useEffect que decide que fn ejecutar pero con la pag 1
                            } else {
                              handlerGetFilteredCancellation();
                            }
                          }}
                        >
                          <span>Buscar</span>
                        </Button>
                      </Col>
                      {filteredMode && (
                        <Col xs="auto" className="p-0">
                          <Button variant="outline-primary" style={{ height: '36px' }} onClick={resetInitialMode} className="w-100 mb-0 mt-2">
                            <span>Regresar</span>
                          </Button>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  {/* Name to, crm id and dates Start */}
                </Row>
                {/* Filter buttons End */}
              </Col>
              {/* Filters End */}
              {/* Items selector Start */}
              <Col xs="12" xl="3" className="pb-2 pt-sm-5 pt-xl-0 pb-xl-0 d-flex justify-content-end align-items-end bg-infow">
                <Row className="g-0">
                  {/* Excel Buttons Start */}
                  {/* Excel  Buttons End */}
                  <Col xs="auto" className="p-0 ms-2">
                    <Dropdown align={{ xs: 'end' }} className={`d-inline-block ${cancellations.length <= 5 && 'd-none'}`}>
                      <Dropdown.Toggle variant="foreground-alternate" className="shadow sw-22">
                        {`${pageSize} Cancelaciones`}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="shadow dropdown-menu-end">
                        <Dropdown.Item className={`${pageSize === 20 && 'd-none'}`} onClick={() => settingPage(20)}>
                          20 Cancelaciones
                        </Dropdown.Item>
                        <Dropdown.Item className={`${pageSize === 50 && 'd-none'}`} onClick={() => settingPage(50)}>
                          50 Cancelaciones
                        </Dropdown.Item>
                        <Dropdown.Item className={`${pageSize === 100 && 'd-none'}`} onClick={() => settingPage(100)}>
                          100 Cancelaciones
                        </Dropdown.Item>
                        <Dropdown.Item className={`${pageSize === 200 && 'd-none'}`} onClick={() => settingPage(200)}>
                          200 Cancelaciones
                        </Dropdown.Item>
                        <Dropdown.Item className={`${pageSize === 300 && 'd-none'}`} onClick={() => settingPage(300)}>
                          300 Cancelaciones
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    {/* Length End */}
                  </Col>
                  {/* <div className={`text-end text-muted d-lg-none mt-3 mb-2 ${shippings?.length < 2 && 'invisible'}`}>{`Página ${currentPage}`}</div> */}
                </Row>
              </Col>
              {/* Items selector End */}
            </Row>
            <hr className="mt-3 mb-4 mt-xl-5 bg-muted" />
            {/* *** Dropdown and Search Buttons End *** */}
            {/* *** Main Page Start *** */}
            {/* List Header Start */}
            {filteredSuccess && <div className="text-muted mb-3">{filteredSuccess}</div>}
            <div className={`d-flex justify-content-between mb-3 text-muted  ${cancellations?.length < 2 && 'd-none'}`}>
              <span>{`Total: ${fm.from(totalItems)}`}</span>
              <span>{`Página ${currentPage} `}</span>
            </div>
            <Row className={`g-0 mb-2 d-none d-lg-flex pt-2 pb-4 py-lg-0 ps-5 pe-4 custom-sort h-100 ${cancellations.length === 0 && 'invisible'} `}>
              <Col lg="1" className="d-flex flex-column pe-1 justify-content-cente bg-infow">
                <div className="text-muted text-small cursor-pointer">CRM ID</div>
              </Col>
              <Col lg="2" className="d-flex flex-column mb-lg-0 pe-3 bg-successw">
                <div className="text-muted text-small cursor-pointer">SERVICIO / ID ENVÍO</div>
              </Col>
              <Col lg="3" className="d-flex flex-column pe-1 justify-content-center align-items-center">
                <div className="text-muted text-small cursor-pointer">PROVEEDOR</div>
              </Col>
              <Col lg="1" className="d-flex flex-column pe-1 justify-content-center align-items-center bg-secondaryw">
                <div className="text-muted text-small cursor-pointer">COSTO</div>
              </Col>
              <Col lg="1" className="d-flex flex-column pe-1 align-items-end justify-content-center">
                <div className="text-muted text-small cursor-pointer">DEVUELTA</div>
              </Col>
              <Col lg="1" className="d-flex flex-column pe-1 align-items-end justify-content-center">
                <div className="text-muted text-small cursor-pointer">CANCELADA</div>
              </Col>
              <Col lg="1" className="d-flex flex-column pe-1 align-items-end justify-content-center">
                <div className="text-muted text-small cursor-pointer">UTILIZADA</div>
              </Col>
              <Col lg="2" className="d-flex flex-column pe-1 align-items-end justify-content-center">
                <div className="text-muted text-small cursor-pointer">FECHA / HORA</div>
              </Col>
            </Row>
            {/* List Header End */}
            {/* List shipment Start */}
            {cancellations.length === 0 ? (
              <div className="d-flex flex-column align-items-center mt-lg-5">
                {/* <h3 className="text-center">No se encontraron registros.</h3> */}
                <img alt="sin datos" src="/img/animation/userNotfound.webp" className="w-20" />
              </div>
            ) : (
              cancellations?.map((cancellation, index) => <CancellationsCard key={index} data={cancellation} />)
            )}
            {/* List shipment End */}
            {/* Pagination Start */}
            <div className={`d-flex flex-column justify-content-center align-items-center mt-5 mx-auto ${totalPages < 2 && 'd-none'}`}>
              <Paginate currentPage={currentPage} totalPages={totalPages} setCurrentPage={setChangePage} />
            </div>
            {/* Pagination End */}
          </div>
          {/* *** Main Page End *** */}
        </>
      )}
    </>
  );
};
export default Cancellations;

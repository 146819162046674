import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import moment from 'moment';
// utils
import formatMoney from 'utils/formatMoney';

const ShippingCard = ({ data }) => {
  return (
    <Card className="mb-2 px-0">
      {/* <Card.Body className="py-3 px-4  py-lg-0 sh-auto sh-lg-8 card bg-light" style={{ boxShadow: '0 4px 6px rgba(var(--separator-rgb), 0.5)' }}> */}
      <Row className="g-0 h-100 sh-lg-9 position-relative overflow-y-hidden">
        <Col xs="auto" className="position-relative">
          <img src={data?.service_img} alt="product" className="card-img card-img-horizontal sw-11 h-100" />
        </Col>
        <Col className="py-4 py-lg-0 px-4 h-100 card bg-light" style={{ boxShadow: '0 4px 6px rgba(var(--separator-rgb), 0.5)' }}>
          <Row className="g-0 h-100 align-content-center">
            <Col xs="12" lg="3" className="d-flex flex-column mb-lg-0 mb-2 pe-3 d-flex order-1 h-lg-100 justify-content-center order-2 order-lg-1">
              <div className="text-muted text-small d-lg-none">Servicio / ID Envio</div>

              <NavLink to={`/shipping-all/detail/${data?.shipping_label_id}/${data?.razon_social}/${data?.crm_user_id}`}>
                {data.shipping_code?.replace(/_+/g, ' ')}
              </NavLink>
              <div className="text-medium text-muted text-truncate">{data?.shipping_label_id}</div>
            </Col>
            <Col lg="3" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center align-items-lg-center order-3">
              <div className="text-muted text-small d-lg-none">Fecha / Hora</div>

              <div className="lh-1 text-alternate">{`${moment(data.creation_date).format('DD-MM-YY')} ${data?.creation_date.slice(11, 19)}`}</div>
            </Col>
            <Col xs="6" lg="3" className="d-flex flex-column pe-1 mb-1 mb-lg-0 justify-content-center align-items-lg-center order-4 text-end">
              <div className="text-muted text-small d-lg-none">Costo</div>
              <div className={`${data.refunded ? 'text-success' : 'text-primary'}`}>
                <span className="text-small text-muted">$</span>
                {formatMoney(data.shipping_rates?.total)}
                <div className={`text-small text-success ${data.refunded ? 'd-block' : 'd-none'}`}>Guía devuelta</div>
              </div>
            </Col>
            <Col
              xs="6"
              lg="2"
              className="d-flex flex-column pe-1 mb-1 mb-lg-0 align-items-start justify-content-center align-items-end align-items-lg-center order-5"
            >
              <div className="text-muted text-small d-lg-none">Peso</div>
              <div className="lh-1 text-alternate">
                {data.parcel?.total_weight}
                <span className="text-small text-muted"> kg</span>
              </div>
            </Col>
            <Col xs="12" lg="1" className="d-flex flex-column mb-1 mb-lg-0 align-items-end order-1 order-lg-last justify-content-lg-center">
              {data?.shipping_label_id !== 'error' && (
                <a href={`data:application/pdf;base64,${data.label}`} download={data?.shipping_label_id}>
                  <CsLineIcons icon="download" />
                </a>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
export default ShippingCard;

// 1. React and core dependencies
import React from 'react';

// 2. UI Components
import { Form, InputGroup } from 'react-bootstrap';

// 3. Libraries
import CsLineIcons from 'cs-line-icons/CsLineIcons';
// - This component
//  1. Handles local states
//  2. Receives a function in cleanError to remove error
//  3. Receives a function in setField to update the value
const InputText = ({
  value = '',
  fieldName = '',
  setField = () => {},
  isRequired = false,
  icon = 'circle',
  label = '',
  placeholder = '',
  helpText = '',
  cleanError = null,
  maxLength = null,
  validation = /^[A-Z0-9 ]+$/i, // accept letter and numbers
}) => {
  return (
    <>
      <Form.Label>{label}</Form.Label>
      {isRequired && <span className="text-primary ms-1">*</span>}
      <InputGroup>
        <InputGroup.Text>
          <CsLineIcons icon={icon} size="16" className="text-alternate" />
        </InputGroup.Text>
        <Form.Control
          type="text"
          maxLength={maxLength}
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setField(e.target.value, validation, fieldName);
            if (cleanError) {
              // remove any pre error
              cleanError('', fieldName);
            }
          }}
        />
      </InputGroup>
      <span className="text-medium">{helpText}</span>
    </>
  );
};

export default InputText;
// - CALL THIS COMPONENT LIKE THIS -

//  <InputText
// value={requestReason} *
// fieldName="requestReason" *
// setField={updateTextField} *
// isRequired *      // MEANS IS TRUE IF YOU WANT TOP BE FALSE CALL IT -> isRequired={false}
// - Optionals
// icon="edit-square"  *
// label =""
// placeholder=""
// helpText=""
// cleanError = '',
// maxLength = 5
// validation={/^[A-Z0-9 ]+$/i} // JUST IN CASE YOU WANT TO PASS A DIFFERENT REGEX EXAMPLE: validation={/^[0-9 ]+$/i}

// />

// React and hooks
import React, { useEffect, useState } from 'react';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// React Router
import { NavLink, useHistory } from 'react-router-dom';

// UI Components
import { Row } from 'react-bootstrap';

// External Libraries
import { motion } from 'framer-motion';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

// Layout Components
import HtmlHead from 'components/html-head/HtmlHead';

// Utilities
import { initialsUpperCase } from 'utils/textFormat';
import { handleAPIError, handleCatchError } from 'utils/handleErrors';

// Redux Actions
import { setCurrentUser, setIsLoginUser } from 'slices/auth/authSlice';

// API Calls
import { getCounters } from '../../api/Api-user';

// Custom Components
import CounterCard from './components/CounterCard';
import UnauthorizedUserPanel from './sections/UnauthorizedUserPanel';
import AuthorizedUserPanel from './sections/AuthorizedUserPanel';

const Dashboard = () => {
  const title = 'Inicio';
  const description = 'Inicio Plataforma Autopaquete';
  const history = useHistory();
  const dispatch = useDispatch();
  const storedCounters = JSON.parse(localStorage.getItem('counters'));
  const storedShippings = JSON.parse(localStorage.getItem('shippings'));
  const storedNotice = JSON.parse(localStorage.getItem('notice'));

  // - Local States
  const [isLoading, setIsLoading] = useState(!storedShippings);

  const [counters, setCounters] = useState({
    balance: storedCounters?.current_balance || 0,
    shippings: storedCounters?.shippings_counter_last_month || 0,
    pickups: storedCounters?.pickups_counter_last_month || 0,
  });
  const [lastShippings, setLastShippings] = useState(storedShippings || null);
  const [notifications, setNotifications] = useState(storedNotice || []);

  // - Global State from Store.js form Values
  const { isLogin, currentUser } = useSelector((state) => state.auth);

  const isUserAllowed = currentUser.isVerified;
  // console.log('current user:', currentUser);
  // console.log('token desde el storage:', JSON.parse(JSON.parse(localStorage.getItem('persist:starter-project')).auth).currentUser.api_key_production);

  if (!isLogin) {
    history.push('/login');
  }
  const handlerGetCounters = async () => {
    try {
      // if isUserAllowed is true get counters
      if (isUserAllowed) {
        const response = await getCounters();
        // console.log('isUserAllowed ok,response counters:', response);
        if (response.current_balance !== undefined) {
          // save in local storage
          localStorage.setItem('counters', JSON.stringify(response));
          localStorage.setItem('notice', JSON.stringify(response.notices));
          localStorage.setItem('shippings', JSON.stringify(response.shippings));
          setCounters({
            balance: response.current_balance,
            shippings: response.shippings_counter_last_month,
            pickups: response.pickups_counter_last_month,
          });
          setLastShippings(response.shippings);
          setNotifications(response.notices);
        } else if (response.error) {
          handleAPIError(response);
        }
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      if (isLoading) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    handlerGetCounters();
  }, []);

  useEffect(() => {
    const storedVerified = JSON.parse(localStorage.getItem('isLoginVerified') || 'false');
    // Comprobamos si el estado de isUserAllowed es undefined
    if (isUserAllowed === undefined && !storedVerified) {
      // Si está undefined, forzamos al usuario a hacer login
      setTimeout(() => {
        dispatch(setCurrentUser({}));
        dispatch(setIsLoginUser(false));
        history.push('/inicio');
        localStorage.setItem('isLoginVerified', 'true');
      }, 4000);
    }
  }, [isUserAllowed, dispatch, history]);

  return (
    <>
      <HtmlHead title={title} description={description} />
      {/* Title Start */}
      <div className="my-5 page-title-container bg-infox d-flex flex-column flex-xl-row justify-content-between">
        <h1 className="mb-0 pb-0 display-4 text-center text-sm-start lh-base" id="title">
          {`¡Bienvenid@ ${initialsUpperCase(currentUser.name)}! ✨ `}
        </h1>
        <div className={`mt-4 mt-xl-0 d-flex justify-content-end ${lastShippings?.length > 0 && isUserAllowed ? 'visible' : 'invisible'}`}>
          <NavLink to="/envios/enviar" className="btn btn-primary d-flex" id="goform" style={{ height: '36px' }}>
            <CsLineIcons icon="arrow-double-left" size="15" className="me-1" />
            <span>Crear envío</span>
          </NavLink>
        </div>
      </div>
      {/* Title End */}
      {/* Counter Cards Start */}
      <motion.div
        initial={{ opacity: 0, y: 50 }} // Empieza transparente y desplazado hacia abajo
        animate={{ opacity: 1, y: 0 }} // Se muestra y vuelve a su posición
        transition={{ duration: 0.5, ease: 'easeOut' }} // Transición suave
      >
        <Row className="mb-5 g-2">
          <CounterCard
            icon="dollar"
            title="saldo actual"
            counter={counters.balance}
            tooltip="Ir a Movimientos"
            path="/finanzas/movimientos"
            isUserAllowed={isUserAllowed}
          />
          <CounterCard
            icon="credit-card"
            title="clabe stp"
            counter={currentUser.clabe || 0}
            tooltip="Ir a Facturación"
            path="/finanzas/facturacion"
            isUserAllowed={isUserAllowed}
          />
          <CounterCard
            icon="shipping"
            title="Envíos del mes"
            counter={counters.shippings}
            tooltip="Ir a Historial de envíos"
            path="/envios/historial"
            isUserAllowed={isUserAllowed}
          />
          <CounterCard
            icon="destination"
            title="recolecciones del mes"
            counter={counters.pickups}
            tooltip="Ir a Historial de recolecciones"
            path="/envios/recolectar"
            isUserAllowed={isUserAllowed}
          />
        </Row>
      </motion.div>

      {/* Counter Cards End */}
      {!isUserAllowed ? (
        <>
          {/* if the user is not allowed */}
          {isUserAllowed === false && <UnauthorizedUserPanel />}
        </>
      ) : (
        <AuthorizedUserPanel isUserAllowed={isUserAllowed} lastShippings={lastShippings} notifications={notifications} isLoading={isLoading} />
      )}
    </>
  );
};

export default Dashboard;

const { FormatMoney } = require('format-money-js');

const fmDecimal = new FormatMoney({
  decimals: 2,
});

const formatMoney = (price) => {
  // Convertimos a número y validamos si es válido
  const numericPrice = Number(price);
  // Si no es un número válido, devolvemos "0.00"
  if (Number.isNaN(numericPrice)) {
    return fmDecimal.from(0);
  }

  return fmDecimal.from(numericPrice);
};
export default formatMoney;

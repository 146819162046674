import React, { useState, useEffect } from 'react';
import { Modal, Button, Dropdown } from 'react-bootstrap';
// utils
import { handleCatchError, handleAPIError } from 'utils/handleErrors';
// components
import ButtonRequest from '../../../../components/buttons/ButtonRequest';
import { ToastSuccess, ToastErrorCath } from '../../../../components/alerts/toast';
// API
import { editUserAccount, getRateList, getSellers } from '../../../api/Api-admin';

const EditUserModal = ({ isModalOpen, setIsModalOpen, data, setUpdatePage }) => {
  // console.log('---->', data);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isAddChargerLoader, setIsAddChargerLoader] = useState(false);
  const [ratesList, setRatesList] = useState([]); // tarifario list
  const [sellerList, setSellerList] = useState([]);

  const [dataToUpdate, setDataToUpdate] = useState({
    crmId: data.crm_user_id,
    isAccountActive: data.is_active,
    isAutoBalance: data.automatic_balance,
    isAutoVouchers: data.automatic_invoice, // com fiscales
    isMultiPieces: data.is_multipiece_available,
    customerType: data.customer_type,
    adviser: data.seller,
    rate: data.rates_list === null ? '-- x --' : data.rates_list, // tarifario
    labelType: data.label_type === null ? '-- x --' : data.label_type, // etiqueta
    taxSystem: data.tax_system, // regimen
    zip: data.zip_code,
  });

  const handleButtonAction = () => {
    setIsButtonDisabled(true); // disable button
    setIsAddChargerLoader(true); // active loader in the button
    // Rehabilitar el botón después de 3 segundos
    setTimeout(() => setIsButtonDisabled(false), 3000);
  };
  const handlerEditUserAccount = async () => {
    try {
      // console.log('datos para editar:', dataToUpdate);
      handleButtonAction();
      const response = await editUserAccount(dataToUpdate);
      if (response.success !== undefined) {
        // console.log('response edit', response);
        setUpdatePage(true);
        ToastSuccess(`El usuario ${response.crm_user_id} ha sido editado correctamente ⭐`);
      } else if (response.error) {
        handleAPIError(response);
      }
    } catch (error) {
      handleCatchError(error);
    } finally {
      setIsModalOpen(false);
    }
  };
  const handlerGetRates = async () => {
    try {
      const response = await getRateList();
      if (response.rates_list) {
        setRatesList(response.rates_list);
      }
    } catch (error) {
      // handleCatchError(error);
      ToastErrorCath('Error: No se pudo cargar el tarifario. Por favor, verifica tu conexión a Internet e inténtalo nuevamente.');
    }
  };
  const handlerGetSellers = async () => {
    try {
      const response = await getSellers();
      // console.log(response);
      if (response.list) {
        setSellerList(response.list);
      }
    } catch (error) {
      handleCatchError(error);
    }
  };
  useEffect(() => {
    handlerGetRates();
    handlerGetSellers();
  }, []);
  return (
    <Modal className="modal-right" show={isModalOpen} onHide={() => setIsModalOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title as="h5">Editar Usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <div className="mb-5">{`Por favor, confirma si deseas editar la información del usuario ${dataToUpdate.crmId} con los datos siguientes:`}</div> */}
        <div className="d-flex align-items-center mb-2">
          <div className="text-alternate mx-2">CUENTA {dataToUpdate.isAccountActive ? 'ACTIVA' : 'INACTIVA'} </div>
          <input
            type="checkbox"
            className="form-check-input cursor-pointer"
            checked={dataToUpdate.isAccountActive}
            onClick={() =>
              setDataToUpdate((prevValues) => {
                return { ...prevValues, isAccountActive: !dataToUpdate.isAccountActive };
              })
            }
          />
        </div>
        <div className="d-flex align-items-center mb-2">
          {/* <CsLineIcons icon="dollar" size="19" className="text-muted" /> */}
          <div className="text-alternate mx-2">SALDO AUTOMÁTICO</div>
          <input
            type="checkbox"
            className="form-check-input cursor-pointer ms-2  mb-1"
            checked={dataToUpdate.isAutoBalance}
            onClick={() =>
              setDataToUpdate((prevValues) => {
                return { ...prevValues, isAutoBalance: !dataToUpdate.isAutoBalance };
              })
            }
          />
        </div>
        <div className="d-flex align-items-center mb-2 ">
          {/* <CsLineIcons icon="invoice" size="19" className="text-muted" /> */}
          <div className="text-alternate mx-2">COMPROBANTES AUTOMÁTICO</div>
          <input
            type="checkbox"
            className="form-check-input cursor-pointer ms-2  mb-1"
            checked={dataToUpdate.isAutoVouchers}
            onClick={() =>
              setDataToUpdate((prevValues) => {
                return { ...prevValues, isAutoVouchers: !dataToUpdate.isAutoVouchers };
              })
            }
          />
        </div>
        <div className="d-flex align-items-center mb-2">
          {/* <CsLineIcons icon="duplicate" size="19" className="text-muted" /> */}
          <div className="text-alternate mx-2">MULTIPIEZAS</div>
          <input
            type="checkbox"
            className="form-check-input cursor-pointer ms-2 mb-1"
            checked={dataToUpdate.isMultiPieces}
            onClick={() =>
              setDataToUpdate((prevValues) => {
                return { ...prevValues, isMultiPieces: !dataToUpdate.isMultiPieces };
              })
            }
          />
        </div>
        <div className="d-flex align-items-center mb-2">
          {/* <CsLineIcons icon="shield" size="19" className="text-muted" /> */}
          <div className="text-alternate mx-2"> CLIENTE TIPO </div>
          <Dropdown>
            <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1 text-primary">
              {dataToUpdate.customerType}
            </Dropdown.Toggle>
            <Dropdown.Menu className="shadow dropdown-menu-start">
              <Dropdown.Item
                className={`${dataToUpdate.customerType === 'T' && 'd-none'}`}
                onClick={(e) =>
                  setDataToUpdate((prevValues) => {
                    return { ...prevValues, customerType: e.target.innerText };
                  })
                }
              >
                T
              </Dropdown.Item>
              <Dropdown.Item
                className={`${dataToUpdate.customerType === 'C' && 'd-none'}`}
                onClick={(e) =>
                  setDataToUpdate((prevValues) => {
                    return { ...prevValues, customerType: e.target.innerText };
                  })
                }
              >
                C
              </Dropdown.Item>
              <Dropdown.Item
                className={`${dataToUpdate.customerType === 'D' && 'd-none'}`}
                onClick={(e) =>
                  setDataToUpdate((prevValues) => {
                    return { ...prevValues, customerType: e.target.innerText };
                  })
                }
              >
                D
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="d-flex align-items-center mb-2">
          {/* <CsLineIcons icon="support" size="19" className="text-muted" /> */}
          <div className="text-alternate mx-2"> VENDEDOR </div>
          <Dropdown>
            <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1 text-primary">
              {dataToUpdate.adviser}
            </Dropdown.Toggle>
            <Dropdown.Menu className="shadow dropdown-menu-start">
              {sellerList?.map((seller, index) => (
                <Dropdown.Item
                  key={index}
                  className={`${dataToUpdate.adviser === seller && 'd-none'}`}
                  onClick={() =>
                    setDataToUpdate((prevValues) => {
                      return { ...prevValues, adviser: seller };
                    })
                  }
                >
                  {seller}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="d-flex align-items-center mb-2">
          <div className="text-alternate mx-2"> TARIFARIO </div>
          <Dropdown>
            <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1 text-primary">
              {dataToUpdate.rate}
            </Dropdown.Toggle>
            <Dropdown.Menu className="shadow dropdown-menu-start" style={{ height: '200px', overflowY: 'scroll' }}>
              {ratesList?.map((rates) => (
                <Dropdown.Item
                  key={rates.id}
                  onClick={() =>
                    setDataToUpdate((prevValues) => {
                      return { ...prevValues, rate: rates.name };
                    })
                  }
                >
                  {rates.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="d-flex align-items-center mb-2">
          {/* {dataToUpdate.labelType !== null ? (
            <CsLineIcons icon="print" size="19" className="text-muted" />
          ) : (
            <CsLineIcons icon="close-circle" size="19" className="text-muted" />
          )} */}
          <div className="text-alternate mx-2"> ETIQUETA </div>
          <Dropdown>
            <Dropdown.Toggle variant="foreground-alternate" className="sw-auto ps-0 py-1 text-primary">
              {dataToUpdate.labelType}
            </Dropdown.Toggle>
            <Dropdown.Menu className="shadow dropdown-menu-start">
              <Dropdown.Item
                className={`${dataToUpdate.labelType === 'COMPACT' && 'd-none'}`}
                onClick={(e) =>
                  setDataToUpdate((prevValues) => {
                    return { ...prevValues, labelType: e.target.innerText };
                  })
                }
              >
                COMPACT
              </Dropdown.Item>
              <Dropdown.Item
                className={`${dataToUpdate.labelType === 'STANDARD' && 'd-none'}`}
                onClick={(e) =>
                  setDataToUpdate((prevValues) => {
                    return { ...prevValues, labelType: e.target.innerText };
                  })
                }
              >
                STANDARD
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="d-flex align-items-center mb-2">
          {/* <CsLineIcons icon="suitcase" size="19" className="text-muted" /> */}
          <div className="text-alternate mx-2">REGIMEN</div>
          <input
            className="text-primary"
            style={{ backgroundColor: 'transparent', border: 'none' }}
            value={dataToUpdate.taxSystem}
            onChange={(e) => {
              setDataToUpdate((prevValues) => {
                return { ...prevValues, taxSystem: e.target.value };
              });
            }}
          />
        </div>
        <div className="d-flex align-items-center mb-2">
          {/* <CsLineIcons icon="suitcase" size="19" className="text-muted" /> */}
          <div className="text-alternate mx-2">CP</div>
          <input
            className="text-primary"
            style={{ backgroundColor: 'transparent', border: 'none' }}
            value={dataToUpdate.zip}
            onChange={(e) => {
              setDataToUpdate((prevValues) => {
                return { ...prevValues, zip: e.target.value };
              });
            }}
          />
        </div>
        <hr />
        <div className="d-flex justify-content-center mt-5">
          <ButtonRequest
            title="Confirmar"
            titleLoading="Editando"
            handlerFn={handlerEditUserAccount}
            isButtonDisabled={isButtonDisabled}
            isLoading={isAddChargerLoader}
          />

          {/* <Button variant="primary" disabled={isButtonDisabled} className="me-2" onClick={handlerEditUserAccount}>
            {isAddChargerLoader && (
              <Spinner
                as="span"
                animation="border"
                role="status"
                aria-hidden="true"
                style={{
                  width: '13px',
                  height: '13px',
                  borderWidth: '1px',
                  color: '#FFFF',
                }}
              />
            )}
            <span>Editar</span>
          </Button> */}
          <Button variant="primary" onClick={() => setIsModalOpen(false)} style={{ height: '36px' }}>
            Cancelar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditUserModal;

// 1. React and core dependencies
import React from 'react';

// 2. State management
import { useSelector } from 'react-redux';

// 3. UI components
import { Row, Col } from 'react-bootstrap';

// 4. Custom form components
import InputText from 'components/form-elements/InputText2';
import SelectFile from 'components/form-elements/SelectFile';

const PackageInfoDamaged = ({ values, setValues, errors, setErrors, invoiceOk }) => {
  // - Global State from Store.js form Values
  const { generalData } = useSelector((state) => state.general_data);
  return (
    <Row className={`g-3 my-5 ${generalData.fields.isVerifiedToReport ? 'd-flex' : 'd-none'}`}>
      <h2 className="small-title">Información Paquete</h2>
      <div>
        Es crucial proporcionar la información correcta sobre el contenido, la cual debe coincidir con el comprobante entregado a la paquetería y la evidencia
        disponible.
      </div>
      <Col sm="12" className="position-relative">
        <InputText
          label="1.Describe el empaque o embalaje exterior y detalla cualquier daño que presente (si lo hay)."
          icon="box"
          maxLength="250"
          placeholder="Caja cerrada con cinta canela y etiqueta con logo Autopaquete al costado (máximo 250 caracteres)"
          value={values.packagingDescription}
          isRequired
          helpText="Descripción del material que se usó en el paquete, si tiene alguna calcomanía, logo o tipo de cinta rotulada."
          fieldName="packagingDescription"
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.packagingDescription && (
          <div className={`invalid-tooltip ${errors.packagingDescription ? 'd-block' : 'd-none'}`}>{errors.packagingDescription}</div>
        )}
      </Col>

      <Col sm="12" className="position-relative">
        <InputText
          label="2.Indica el tipo de embalaje interno (protección) y detalla cualquier daño que presente (si lo hay)."
          icon="box"
          value={values.packagingInternalDescription}
          isRequired
          fieldName="packagingInternalDescription"
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.packagingInternalDescription && (
          <div className={`invalid-tooltip ${errors.packagingInternalDescription ? 'd-block' : 'd-none'}`}>{errors.packagingInternalDescription}</div>
        )}
      </Col>
      <Col sm="12" className="position-relative">
        <InputText
          label="3.Describe el contenido y detalla exactamente el daño que presenta (si lo hay)."
          icon="box"
          maxLength="250"
          placeholder="5 pantalones de mezclilla 900 total (máximo 250 caracteres)"
          helpText="Producto, piezas y valor del producto ($)"
          value={values.packageContent}
          isRequired
          fieldName="packageContent"
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.packageContent && <div className={`invalid-tooltip ${errors.packageContent ? 'd-block' : 'd-none'}`}>{errors.packageContent}</div>}
      </Col>
      <Col sm="12" className="position-relative">
        <InputText
          label="4.Indica cuántas piezas llegaron dañadas y cuántas piezas llegaron en buen estado."
          icon="box"
          maxLength="50"
          placeholder="5 piezas con daño y 3 piezas en buen estado"
          value={values.missingParts}
          isRequired
          fieldName="missingParts"
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.missingParts && <div className={`invalid-tooltip ${errors.missingParts ? 'd-block' : 'd-none'}`}>{errors.missingParts}</div>}
      </Col>
      <Col sm="12" className="position-relative">
        <InputText
          label="5.El daño presentado en el empaque exterior, ¿Coincide con el daño del contenido? "
          icon="box"
          value={values.damagedDescription}
          isRequired
          fieldName="damagedDescription"
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.damagedDescription && <div className={`invalid-tooltip ${errors.damagedDescription ? 'd-block' : 'd-none'}`}>{errors.damagedDescription}</div>}
      </Col>
      <Col sm="12" className="position-relative">
        <InputText
          label="6.¿Es posible reparar el contenido?"
          icon="box"
          value={values.isRepairable}
          isRequired
          fieldName="isRepairable"
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.isRepairable && <div className={`invalid-tooltip ${errors.isRepairable ? 'd-block' : 'd-none'}`}>{errors.isRepairable}</div>}
      </Col>
      <Col sm="12" className="position-relative">
        <InputText
          label="7.¿Cuál es el costo o la cotización para la reparación?"
          icon="box"
          value={values.repairCost}
          isRequired
          fieldName="repairCost"
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.repairCost && <div className={`invalid-tooltip ${errors.repairCost ? 'd-block' : 'd-none'}`}>{errors.repairCost}</div>}
      </Col>
      <Col sm="12" className="position-relative">
        <InputText
          label="8.Por favor, indica si el paquete presenta cinta adhesiva con rotulación diferente a la original."
          icon="box"
          value={values.packageTape}
          isRequired
          fieldName="packageTape"
          formObj={setValues}
          setErrors={setErrors}
        />
        {errors.packageTape && <div className={`invalid-tooltip ${errors.packageTape ? 'd-block' : 'd-none'}`}>{errors.packageTape}</div>}
      </Col>
      <Col sm="9" className="position-relative mt-5">
        <SelectFile
          label="Adjunta tu factura del contenido"
          placeholder="Sube o arrastra tu factura"
          isRequired={invoiceOk}
          fieldName="invoiceFile"
          formObj={setValues}
          setErrors={setErrors}
          helpText={`La descripción del contenido debe coincidir con la del comprobante y la fecha del comprobante debe ser anterior o igual al día en que creaste tu guía.
      *No se aceptan capturas de transferencias ni tickets de depósitos.`}
        />
        {errors.invoiceFile && <div className={`invalid-tooltip ${errors.invoiceFile ? 'd-block' : 'd-none'}`}>{errors.invoiceFile}</div>}
      </Col>
    </Row>
  );
};

export default PackageInfoDamaged;
